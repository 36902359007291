import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router'
import { checkUser } from '../../core/reducers/user'
import Loader from '../../components/Loader'
import './index.scss'

const GuestLayout = memo(() => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.rootReducers.isLoading)
  useEffect(() => {
    dispatch(checkUser())
  }, [dispatch])

  return (
    <div className='guest-layout'>
      {
          isLoading ? (<Loader />) : (<Outlet />)
      }
    </div>
  )
})

GuestLayout.displayName = 'GuestLayout'

export default GuestLayout
