import { create, remove, getAll, update, subscribeAll, subscribeOne } from './db'
import { functions, httpsCallable } from './firebase'

const collectionName = 'contents'

const contentCreate = async (id, data) => {
  await create(collectionName, id, data)
}

const contentDelete = async (id) => {
  await remove(collectionName, id)
}

const contentGenerateDeepLink = (id) => {
  const call = httpsCallable(functions, 'contentGenerateDeepLink')
  return call({ id })
}

const contentGenerateVector = (id) => {
  const call = httpsCallable(functions, 'contentGenerateVector')
  return call(id)
}

const contentSearch = async (query) => {
  const call = httpsCallable(functions, 'contentSearch')
  const { data } = await call({ query })

  return data
}

const contentUpdate = async (id, data) => {
  const updateData = {
    ...data,
    updatedAt: new Date()
  }
  await update(collectionName, id, updateData)
}

const contentGetAll = () => getAll(collectionName)
const contentSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)
const contentSubscribeById = (id, mutationCallback) => subscribeOne(collectionName, id, mutationCallback)

export {
  contentCreate,
  contentDelete,
  contentGenerateDeepLink,
  contentGenerateVector,
  contentGetAll,
  contentSearch,
  contentSubscribeAll,
  contentSubscribeById,
  contentUpdate
}
