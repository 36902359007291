import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { create, getAll, getOne, remove, subscribeAll, subscribeOne, update } from '../db'

const accountsCollectionName = 'accounts'
const collectionName = 'patients'

const memberCreate = async (accountId, data) => {
  await create(`${accountsCollectionName}/${accountId}/${collectionName}`, uuid(), data)
}

const memberDelete = async (accountId, memberId) => {
  await remove(`${accountsCollectionName}/${accountId}/${collectionName}`, memberId)
}

const memberUpdate = async (accountId, memberId, data) => {
  await update(`${accountsCollectionName}/${accountId}/${collectionName}`, memberId, data)
}

const membersGetAll = (accountId) => getAll(`${accountsCollectionName}/${accountId}/${collectionName}`)

const membersGetCombined = async (accountIds) => {
  const members = []
  for (let i = 0; i < accountIds.length; i++) {
    const accountUsers = await getAll(`${accountsCollectionName}/${accountIds[i]}/${collectionName}`)
    members.push(...accountUsers)
  }

  return _.uniq(members)
}

const membersSubscribeAll = (accountId, mutationCallback) => subscribeAll(`${accountsCollectionName}/${accountId}/${collectionName}`, mutationCallback)
const membersSubscribeOne = (accountId, memberId, mutationCallback) => subscribeOne(`${accountsCollectionName}/${accountId}/${collectionName}`, memberId, mutationCallback)

const memberGet = async (accountId, email) => await getOne(`${accountsCollectionName}/${accountId}/${collectionName}`, email)

export {
  memberCreate,
  memberDelete,
  memberGet,
  membersGetAll,
  membersGetCombined,
  memberUpdate,
  membersSubscribeAll,
  membersSubscribeOne
}
