import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { getMixpanel } from '../../../../api/mixpanel'

const mixpanel = getMixpanel()

const BillingBanner = ({ isOpen, toggle }) => {
  const startToggle = () => {
    mixpanel.track('Upgrade process cancelled by user J11')
    toggle()
  }

  const trackEmailClick = () => {
    mixpanel.track('Upgrade finalized: contact by email J22')
  }

  return (
    <Modal isOpen={isOpen} toggle={startToggle} className="billing-upgrade">
      <ModalHeader>Upgrade your subscription</ModalHeader>
      <ModalBody>
        <center onClick={trackEmailClick}>Contact us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> to upgrade.</center>
      </ModalBody>
    </Modal>
  )
}

export default BillingBanner
