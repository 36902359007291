import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { create, getAll, remove, subscribeAll, update } from './db'

const collectionName = 'sessions2'

const sessionCreate = (group, data) => {
  const sessionId = uuid()
  const { sessions } = group.metadata

  sessions.push({
    ...data,
    id: sessionId
  })

  update(collectionName, group.id, {
    sessions
  })
}

const sessionGroupCreate = async (data) => {
  await create(collectionName, uuid(), data)
}

const sessionGroupDelete = async (groupId) => {
  await remove(collectionName, groupId)
}

const sessionGroupUpdate = async (groupId, data) => {
  await update(collectionName, groupId, data)
}

const sessionUpdate = (group, sessionId, data) => {
  const { sessions } = group.metadata

  const index = _.findIndex(sessions, { id: sessionId })
  sessions[index] = data

  update(collectionName, group.id, {
    sessions
  })
}

const sessionsUpdate = (group, sessions) => {
  update(collectionName, group.id, {
    sessions
  })
}

const sessionGroupsGetAll = () => getAll(collectionName)
const sessionGroupsSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)

export {
  sessionCreate,
  sessionGroupCreate,
  sessionGroupDelete,
  sessionGroupUpdate,
  sessionUpdate,
  sessionsUpdate,
  sessionGroupsGetAll,
  sessionGroupsSubscribeAll
}
