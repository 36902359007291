import React from 'react'

const HightlightKeywords = ({ text, keywords }) => {
  return (
    <>
      {text.split(' ').map((word, index) => (
          <span key={index} className={keywords.includes(word) ? 'highlight' : ''}>{word} </span>
      ))}
    </>
  )
}

export default HightlightKeywords
