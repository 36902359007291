import React from 'react'
import './adoption.scss'

const AccountHome = ({ adoption }) => {
  const activeMembers = adoption.activeMembers
  const inactiveMembers = adoption.allMembers - adoption.activeMembers
  const allMembers = activeMembers + inactiveMembers

  return (
    <div className="adoption">
      <h3>Adoption</h3>
        <p>Active members: {activeMembers} / {allMembers}</p>
        <p>Inactive members: {inactiveMembers} / {allMembers}</p>
    </div>
  )
}

export default AccountHome
