import React from 'react'
import { Table } from 'reactstrap'
import mixpanel from '../../../config/mixpanel'

const System = () => {
  return (
    <>
      <h1>System</h1>
      <br />
      <Table bordered hover>
        <tbody>
          <tr>
            <th>Mixpanel</th>
            <td>{mixpanel.token}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default System
