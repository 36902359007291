import { functions, httpsCallable } from './firebase'
import { subscribeFiltered, subscribeOne, update } from './db'

const collectionName = 'patients'

const memberDelete = (memberId) => {
  const call = httpsCallable(functions, 'memberDelete')
  return call({ appId: memberId })
}

const memberSubscribe = (memberId, callback) => {
  return subscribeOne(collectionName, memberId, callback)
}

const memberUpdate = (memberId, data) => {
  return update(collectionName, memberId, data)
}

const appMemberGet = (memberId) => {
  const call = httpsCallable(functions, 'appMemberGet')
  return call({ appId: memberId, audit: true })
}

const subscribeRecentMembers = (mutation) => {
  return subscribeFiltered(
    collectionName,
    [['lastActivity', '>', new Date(Date.now() - 86400000)]],
    [['lastActivity', 'desc']],
    100,
    mutation
  )
}

export {
  appMemberGet,
  subscribeRecentMembers,
  memberDelete,
  memberSubscribe,
  memberUpdate
}
