import React from 'react'
import { Col, Row } from 'reactstrap'
import Input from '../../../Input/index2'

const Step1 = ({ errors, register }) => (
  <>
    <Row xs='4'>
      <Col>
        <Input
          type='number'
          step='0.1'
          name='eIn'
          label='Inhale'
          required={() => true}
          placeholder='0'
          register={register}
          errors={errors}
        />
      </Col>
      <Col>
        <Input
          type='number'
          step='0.1'
          name='eInHold'
          label='Hold 1'
          required={() => true}
          placeholder='0'
          register={register}
          errors={errors}
        />
      </Col>
      <Col>
        <Input
          type='number'
          step='0.1'
          name='eOut'
          label='Exhale'
          required={() => true}
          placeholder='0'
          register={register}
          errors={errors}
        />
      </Col>
      <Col>
        <Input
          type='number'
          step='1'
          name='eOutHold'
          label='Hold 2'
          required={() => true}
          placeholder='0'
          register={register}
          errors={errors}
        />
      </Col>
    </Row>
    <Row xs='4'>
      <Col>
        <Input
          type='number'
          step='0.1'
          name='eRepetitions'
          label='Repetitions'
          required={() => true}
          placeholder='0'
          register={register}
          errors={errors}
        />
      </Col>
    </Row>
  </>
)

export default Step1
