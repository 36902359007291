import _ from 'lodash'
import { deleteFile } from '../api/storage'

const deleteReplacedFiles = async ({ oldItems, newItems }) => {
  _.each(newItems, newItem => {
    const oldItem = _.find(oldItems, { id: newItem.id })
    if (!oldItem) {
      return
    }

    if (oldItem.image && oldItem.image !== newItem.image) {
      deleteFile(oldItem.image)
    }

    if (oldItem.file && oldItem.file !== newItem.file) {
      deleteFile(oldItem.file)
    }
  })
}

export default deleteReplacedFiles
