import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { create, remove, subscribeAll, update } from '../db'

const accountsCollectionName = 'accounts'
const collectionName = 'sessions'

const sessionCreate = (accountId, group, data) => {
  const sessionId = uuid()
  const { sessions } = group.metadata

  sessions.push({
    ...data,
    id: sessionId
  })

  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    sessions
  })
}

const sessionGroupCreate = async (accountId, data) => {
  await create(`${accountsCollectionName}/${accountId}/${collectionName}`, uuid(), data)
}

const sessionGroupDelete = async (accountId, groupId) => {
  await remove(`${accountsCollectionName}/${accountId}/${collectionName}`, groupId)
}

const sessionGroupUpdate = async (accountId, groupId, data) => {
  await update(`${accountsCollectionName}/${accountId}/${collectionName}`, groupId, data)
}

const sessionUpdate = (accountId, group, sessionId, data) => {
  const { sessions } = group.metadata

  const index = _.findIndex(sessions, { id: sessionId })
  sessions[index] = data

  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    sessions
  })
}

const sessionsUpdate = (accountId, group, sessions) => {
  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    sessions
  })
}

const sessionGroupsSubscribeAll = (accountId, mutationCallback) => subscribeAll(`${accountsCollectionName}/${accountId}/${collectionName}`, mutationCallback)

export {
  sessionCreate,
  sessionGroupCreate,
  sessionGroupDelete,
  sessionGroupUpdate,
  sessionUpdate,
  sessionsUpdate,
  sessionGroupsSubscribeAll
}
