import { remove, getAll, subscribeAll, subscribeOne } from './db'

const collectionName = 'healthchecks'

const alertDelete = async (id) => {
  await remove(collectionName, id)
}
const healthchecksGetAll = () => getAll(collectionName)
const alertSubscribeAll = (mutationCallback) =>
  subscribeAll(collectionName, mutationCallback)
const alertSubscribeById = (id, mutationCallback) =>
  subscribeOne(collectionName, id, mutationCallback)

export {
  alertDelete,
  healthchecksGetAll,
  alertSubscribeAll,
  alertSubscribeById
}
