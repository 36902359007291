import React, { memo, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import { Timestamp } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
// import { Col, Input, Row, Table } from 'reactstrap'
import { Col, Row, Table } from 'reactstrap'
import Loader from '../../../../components/Loader'
import Button from '../../../../components/button'
import UserEditModal from './modals/user-edit'
import {
  memberDelete,
  //  memberUpdate,
  membersSubscribeAll
} from '../../../../api/accounts/members'
import { userAddToAccount, userDoesExist } from '../../../../api/functions'
import AddUserButtonText from './add-user-button-text'
// import ActionsButtonText from '../../../../components/actions-button-text'
import UserDeleteModal from './modals/user-delete-confirmation'

const Users = memo(({ account }) => {
  // const [actionsButtonVisible, setActionsButtonVisible] = useState(false)
  const [isUserModalOpen, setIsUserEditModalOpen] = useState(false)
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false)
  const [currentUserId, setCurrentUserId] = useState(null)
  const [users, setUsers] = useState(null)
  const [checkboxes, setCheckboxes] = useState({})
  const [sequence, setSequence] = useState(uuid())
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const goToUser = useCallback((userId) => navigate(`/account/teams/${account.id}/user/${userId}`), [navigate])

  useEffect(() => {
    const unsubscribe = membersSubscribeAll(account.id, (rawUsers) => {
      const usersOrdered = _.sortBy(rawUsers, user => new Timestamp(user.metadata.createdAt).toMillis())

      setUsers(usersOrdered)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  /*
  useEffect(() => {
    let visible = false
    _.each(checkboxes, value => {
      if (value) {
        visible = true
      }

      setActionsButtonVisible(visible)
    })
  }, [checkboxes])
*/

  const editUser = (UserId) => {
    setCurrentUserId(UserId)
    setIsUserEditModalOpen(true)
  }

  const saveUser = async (user) => {
    const appId = user.appId.trim()

    try {
      const { data } = await userAddToAccount({
        appId,
        accountId: account.id
      })

      if (data.success) {
        toast.info('Member added.')
      } else {
        throw new Error(data.errorMessage.replace('Error: ', ''))
      }
    } catch (e) {
      toast.error(e.toString(), { autoClose: 30000 })
    }
    userEditModalClose()
  }

  const userEditModalClose = () => {
    setIsUserEditModalOpen(false)
    setCurrentUserId(false)
  }

  const memberDeleteModalClose = () => {
    setIsUserDeleteModalOpen(false)
    togglCheckboxes(false)
  }

  const togglCheckbox = (userId, checked) => {
    const newCheckboxes = Object.assign(checkboxes, {})
    checkboxes[userId] = checked
    setSequence(uuid())
    setCheckboxes({ ...newCheckboxes })
  }

  const togglCheckboxes = (checked) => {
    const newCheckboxes = {}
    _.each(users, user => {
      newCheckboxes[user.id] = checked
    })
    setCheckboxes(newCheckboxes)
  }

  const executeDeletionProcess = () => {
    const usersToDelete = []
    _.each(checkboxes, (value, key) => {
      if (value) {
        togglCheckbox(key, false)
        usersToDelete.push(key)
      }
    })

    _.each(usersToDelete, userId => {
      memberDelete(account.id, userId)
    })

    setIsUserDeleteModalOpen(false)
  }
  /*
  const startDeletionProcess = () => {
    setIsUserDeleteModalOpen(true)
  }
*/
  return (
    <>
      <br />
      <br />
      <br />
      <Row className='page-header'>
        <Col xs="2">
          <h2>Members</h2>
        </Col>

        <Col xs="2">
          { /* actionsButtonVisible &&
            <ActionsButtonText
              onDelete={() => startDeletionProcess()}
            />
            */
          }
        </Col>
        <Col xs="2"></Col>

        {account.metadata.membersDetails &&
          <Col xs="4">
            <Button onClick={() => editUser()} text={<AddUserButtonText />} />
          </Col>
        }
      </Row>
      {!loading &&
        <>
          {account.metadata.membersDetails &&
            <Row>
            {users?.length > 0 &&
              <Table bordered className="table-list">
                  <thead>
                    <tr>
                      { /*
                      <th className="checkbox">
                        <Input type="checkbox" onClick={(e) => togglCheckboxes(e.target.checked)} />
                      </th>
                    */}
                      <th className="field no-border-right">
                        Name
                      </th>
                      <th className="no-border-right">
                        App ID
                      </th>
                      <th className="">
                        Date added
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((user, index) =>
                      <tr key={`${user.id}-${sequence}`}>
                      { /*
                        <th scope="row" className="checkbox">
                          <Input
                            type="checkbox"
                            id={`user-${user.id}`}
                            onChange={(e) => togglCheckbox(user.id, e.target.checked)}
                            checked={!!checkboxes[user.id]}
                          />
                        </th>
                        <td className="no-border-right" onClick={() => goToUser(user.id)}>
                          {user.metadata.firstname}
                        </td>
                      */}
                        <td className="no-border-right" onClick={() => goToUser(user.id)}>
                          {user.metadata.name}
                        </td>
                        <td className="no-border-right" onClick={() => goToUser(user.id)}>
                          {user.metadata.appIds.join(', ')}
                        </td>
                        <td>
                          {user.metadata.createdAt?.toDate()?.toLocaleString()}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
            }
            {users?.length === 0 &&
              <p>No members yet.</p>
            }
            </Row>
          }
          {!account.metadata.membersDetails &&
            <>
              {users.length} user{users.length !== 1 && 's' }.
            </>
          }
        </>

        }
        {loading &&
          <Row className='center'>
            <Loader></Loader>
          </Row>
        }
      {isUserModalOpen &&
        <UserEditModal
          title="Add a User"
          user={_.find(users, { id: currentUserId })}
          isOpen={isUserModalOpen}
          toggle={userEditModalClose}
          onSubmit={saveUser}
          userDoesExist={userDoesExist}
        />
      }
      {isUserDeleteModalOpen &&
        <UserDeleteModal
          users={_.filter(users, user => !!checkboxes[user.id])}
          isOpen={true}
          toggle={memberDeleteModalClose}
          onSubmit={executeDeletionProcess}
        />
      }
    </>)
})

Users.displayName = 'Users'

export default Users
