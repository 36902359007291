import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Col, Row, Table } from 'reactstrap'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { cacheDeleteItem, cacheGetItemData, cacheSetItem } from '../../../api/cache'
import { accountCreate, accountsGetByMasterAccount } from '../../../api/accounts'
import Button from '../../../components/button'
import NewTeamModal from './modals/new-team'
import TeamReadiness from './team-readiness'
import { getMixpanel } from '../../../api/mixpanel'
import './index.scss'
import Loader from '../../../components/Loader'
import ee from '../../../core/emitter'

const mixpanel = getMixpanel()

const AccountTeams = () => {
  const navigate = useNavigate()
  const userAccountsCacheKey = 'user-accounts'
  const [accounts, setAccounts] = React.useState(cacheGetItemData(userAccountsCacheKey) || [])
  const [loading, setLoading] = React.useState(accounts.length < 1)
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false)
  const user = useSelector(state => state.userReducers.user)
  const masterAccount = useSelector(state => state.accountReducers.account)
  const [allowAddTeam, setAllowAddTeam] = useState(false)
  const goToTeam = useCallback((account) => navigate(`/account/teams/${account.id}`), [navigate])

  useEffect(() => {
    const eventName = 'View Teams E00'
    mixpanel.track(eventName)
  }, [])

  useEffect(() => {
    if (!masterAccount.metadata.limits) {
      setAllowAddTeam(true)
      return
    }

    if (masterAccount.metadata.limits.teams > accounts.length) {
      setAllowAddTeam(true)
      return
    }

    setAllowAddTeam(false)
  }, [masterAccount, accounts])

  const loadTeams = () => {
    accountsGetByMasterAccount(masterAccount).then(async (data) => {
      const sortedData = _.orderBy(data, a => a.metadata.createdAt.toDate(), 'desc')
      cacheSetItem(userAccountsCacheKey, { data: sortedData, expires: Date.now() + 1000 * 60 * 60 })
      await setAccounts(sortedData)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (user.metadata.account) {
      loadTeams()
    }

    return () => {}
  }, [user.metadata.account])

  const newTeam = () => {
    setNewTeamModalOpen(true)
  }

  const saveTeam = async ({ name }) => {
    const response = await accountCreate(user.id, { name })

    if (response.success) {
      setNewTeamModalOpen(false)
      cacheDeleteItem(userAccountsCacheKey)
      loadTeams()
    }

    mixpanel.track('Team Added E10', { teamsAlready: accounts.length })

    return response
  }

  return (
    <div>
      <Row>
        <Col>
          <h1>Teams</h1>
        </Col>
        <Col>
          {allowAddTeam &&
            <Button onClick={() => newTeam()} text="+ New Team" />
          }
          {!masterAccount.metadata.billing && !allowAddTeam &&
            <Button onClick={() => ee.emit('upgradeTrigger', { eventName: 'team:new', eventMessage: 'Teams are a premium feature.' })} text="+ New Team" />
          }
        </Col>
      </Row>

      {loading && <Loader></Loader>}
      {!loading &&
        <Table bordered className="table-list">
          <thead>
            <tr>
              <th className="no-border-right">Team Name</th>
              <th className="no-border-right mental-readiness">Mental readiness</th>
              <th className="date">Date added</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(account => {
              return (
                <tr key={account.id} onClick={() => goToTeam(account)}>
                  <td className="no-border-right">{account.metadata.name || 'Unnamed'}</td>
                  <td className="no-border-right">
                    <TeamReadiness account={account}></TeamReadiness>
                  </td>
                  <td>{account.metadata.createdAt.toDate().toLocaleString()}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }

      {newTeamModalOpen &&
        <NewTeamModal
          isOpen={newTeamModalOpen}
          toggle={() => setNewTeamModalOpen(false)}
          onSubmit={saveTeam}
        />
      }
    </div>
  )
}

export default AccountTeams
