import mixpanel from 'mixpanel-browser'
import config from '../config/mixpanel'
const { token, debug } = config

let client = false

const getMixpanel = () => {
  /*
  if (window.location.hostname === 'localhost') {
    return {
      track: (eventName) => {
        console.log('Mixpanel stopped for localhost: ', eventName)
      }
    }
  } */

  if (client === false) {
    mixpanel.init(token, { debug })
    client = mixpanel
  }
  return client
}

export {
  getMixpanel
}
