import React from 'react'
import {
  Modal as ReactstrapModal, ModalHeader
} from 'reactstrap'
import './index.scss'

const Modal = (props) => {
  const {
    isOpen, toggle, children, title
  } = props
  return (
    <ReactstrapModal
      centered
      className='custom-modal'
      toggle={toggle}
      isOpen={isOpen}
    >
      {title &&
        <ModalHeader>
          {title}
        </ModalHeader>
      }
      {children}
    </ReactstrapModal>
  )
}

export default Modal
