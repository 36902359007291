import journal from '../actions/journal'
import spendActiveTimeOutdoors from '../actions/spendActiveTimeOutdoors'
import drinkWater from '../actions/drinkWater'
import coreStabilityExcercises from '../actions/coreStabilityExcercises'
import dontSmoke from '../actions/dontSmoke'
import eatAHealthlyMeal from '../actions/eatAHealthlyMeal'
import takeMeds from '../actions/takeMeds'
import reduceSweets from '../actions/reduceSweets'
import reduceSoda from '../actions/reduceSoda'
import reduceNightSnacks from '../actions/reduceNightSnacks'
import weighYourself from '../actions/weighYourself'
import eatAFruit from '../actions/eatAFruit'

const category = {
  rank: 4,
  name: 'Live healthier',
  actions: [
    journal,
    spendActiveTimeOutdoors,
    drinkWater,
    coreStabilityExcercises,
    dontSmoke,
    eatAHealthlyMeal,
    takeMeds,
    reduceSweets,
    reduceSoda,
    reduceNightSnacks,
    weighYourself,
    eatAFruit
  ]
}

export default category
