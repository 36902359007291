import React from 'react'
import { ReactComponent as EditIcon } from '../../images/pencil.circle.fill.svg'
import { ReactComponent as TrashIcon } from '../../images/trash.circle.fill.svg'
import './action.scss'

import ActionTile from './action-tile'

const Action = (props) => {
  const {
    action,
    forwardRef,
    draggableProps,
    dragHandleProps,
    editAction,
    deleteAction
  } = props

  return (
    <li className='group-item' ref={forwardRef} {...draggableProps} {...dragHandleProps} data-id={action.id}>
      <div className='group-item__container action-tile'>

        <ActionTile action={action} />

        <button type='button' className='group-item__edit-btn' onClick={() => editAction(action.id)}>
          <EditIcon title='Edit' />
        </button>
        <button type='button' className='group-item__delete-btn' onClick={() => deleteAction(action.id)}>
          <TrashIcon title='Delete' />
        </button>
      </div>

    </li>
  )
}

export default Action
