import React from 'react'
import { Row } from 'reactstrap'
import MemberQueueNew from './new'
import MemberQueueList from './list'

export default function MemberQueueComponent ({ appId }) {
  return (
    <>
      <Row>
        <h3>Queue</h3>
      </Row>
      <MemberQueueList appId={appId}></MemberQueueList>
      <MemberQueueNew appId={appId}></MemberQueueNew>
    </>
  )
}
