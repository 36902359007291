import { httpsCallableFromURL } from 'firebase/functions'
import { addDays } from '../../utils/date'
import { functions } from '../firebase'

const getAccountActivity = async (accountIds, masterAccount) => {
  const req = httpsCallableFromURL(functions, 'https://us-central1-earkick---website.cloudfunctions.net/teamstats')

  let timeStart = addDays(new Date(), -14)

  if (timeStart < masterAccount.metadata.createdAt.toDate()) {
    timeStart = masterAccount.metadata.createdAt.toDate()
  }

  const { data } = await req({
    teamId: accountIds,
    timeStart: timeStart.toISOString().substring(0, 19),
    timeEnd: (new Date()).toISOString().substring(0, 19)
  })

  return data
}

const getAccountActivityMock = () => {
  return {
    mentalReadiness: [
      {
        time: '2023-01-01',
        value: 10
      },
      {
        time: '2023-01-02',
        value: 10
      },
      {
        time: '2023-01-03',
        value: 2
      },
      {
        time: '2023-01-04',
        value: 10
      },
      {
        time: '2023-01-05',
        value: 10
      },
      {
        time: '2023-01-06',
        value: 10
      },
      {
        time: '2023-01-07',
        value: 20
      },
      {
        time: '2023-01-08',
        value: 25
      },
      {
        time: '2023-01-09',
        value: 40
      },
      {
        time: '2023-01-10',
        value: 65
      },
      {
        time: '2023-01-11',
        value: 75
      },
      {
        time: '2023-01-12',
        value: 98
      },
      {
        time: '2023-01-13',
        value: 82
      },
      {
        time: '2023-01-14',
        value: 78
      },
      {
        time: '2023-01-15',
        value: 81
      },
      {
        time: '2023-01-16',
        value: 83
      }
    ],
    latestAdoptionRate: {
      activeMembers: 246,
      allMembers: 259
    }
  }
}

export {
  getAccountActivity,
  getAccountActivityMock
}
