import journal from '../actions/journal'
import dontSmoke from '../actions/dontSmoke'
import dontPickNails from '../actions/dontPickNails'
import reduceNegativeSelfTalk from '../actions/reduceNegativeSelfTalk'
import reduceSocialMedia from '../actions/reduceSocialMedia'
import reduceCoffee from '../actions/reduceCoffee'
import dontBiteNails from '../actions/dontBiteNails'
import noPhoneInBed from '../actions/noPhoneInBed'
import reduceTv from '../actions/reduceTv'

const category = {
  rank: 5,
  name: 'Break bat habits',
  actions: [
    journal,
    dontSmoke,
    dontPickNails,
    reduceNegativeSelfTalk,
    reduceSocialMedia,
    reduceCoffee,
    dontBiteNails,
    noPhoneInBed,
    reduceTv
  ]
}

export default category
