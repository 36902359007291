import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { getItemKey } from '../../../../../api/db'
import ActionTile from '../../../../../components/actions/action-tile'
import '../../../../../components/actions/action.scss'

const sortCards = (items) => {
  return _.sortBy(_.values(items), (item) => parseInt(item.rank, 10))
}

const Group = memo(({
  items = [],
  group,
  actionSelected
}) => {
  const [cards, setCards] = useState([])

  useEffect(() => {
    if (items.length > 0) {
      setCards(sortCards(items))
    }
  }, [items])

  return (
    <div className="group">
      <h3 className="group__title handle">{group.metadata.name}</h3>
      <ul className="group__list">
        {cards.map((action, index) => (
          <li className='group-item' key={getItemKey(action)}>
            <div className='group-item__container action-tile' onClick={() => actionSelected({ ...action })}>
              <ActionTile
                action={action}
              />
            </div>
          </li>
        ))}
      </ul>

    </div>
  )
})

Group.displayName = 'ActionGroupNoEdit'

export default Group
