import React from 'react'
import './index.scss'

const PageHeader = ({ title, children }) => (
  <div className='page-header'>
    <h3 className='page-header__title'>{title}</h3>
    {
      children && (
        <div className='page-header__actions'>
          {children}
        </div>
      )
    }
  </div>
)

export default PageHeader
