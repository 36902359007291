import React from 'react'
import { Badge, Col, Row } from 'reactstrap'
import './index.scss'
import { EditText } from 'react-edit-text'
import { memberUpdate } from '../../../../../api/accounts/members'
import MoodChart from './charts/mood-chart'
import MoodDistributionChart from './charts/mood-distribution'
import AverageDailyAnxiety from './charts/daily-anxiety'
import AnxietyDistribution from './charts/anxiety-distribution'
import Anxiety from './charts/anxiety'
import MoodAndAnxiety from './charts/mood-and-anxiety'

const moodMock = {
  labels: [1, 4, 8, 12, 16, 20, 24, 28],
  values: [0, 4, 2, 3, 1, 2, 0, 3]
}

const moodDistributionMock = {
  labels: [4, 3, 2, 1, 0],
  values: [3, 6, 9, 3, 6]
}

const anxietyDistribution = {
  labels: ['High', 'Mid', 'Low', 'None'],
  values: [7, 4, 9, 1]
}

const anxiety = {
  labels: [0, 4, 8, 12, 16, 20, 24, 28],
  values: [1, 4, 3, 3, 2, 3, 1, 0]
}

const averageDailyAnxiety = {
  labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  values: [1, 4, 3, 3, 2, 2, 1, 3]
}

const moodAndAnxiety = {
  labels: [2, 3, 3, 4, 5, 6, 7, 8],
  values: [2, 0, 1, 3, 2, 3, 1, 0]
}

const C = ({ member, accountId }) => {
  return (
    <div className="therapist-showcase">
      <Row>
        <Col xs="10">
          <h1>
            <EditText
              name="name"
              placeholder="McKinley Hunt"
              defaultValue={member.metadata.name}
              onSave={({ value }) => {
                console.log('save name', value)
                memberUpdate(accountId, member.id, { name: value })
              }}
            ></EditText>
          </h1>
        </Col>
        <Col xs="2">
          <Badge color="dark" className="send-message">Send message</Badge>
        </Col>
      </Row>

      <Row>
        <Col>
        Recently reporting increasing anxiety due to issues in relationships. Symptoms include: restlessness,
trouble relaxing, feeling tired. Often these symptoms are related to relationship issues too.
        </Col>
      </Row>

      <Row>

      </Row>

      <Row>
        <Col xs="12" lg="6" xxl="4">
          <MoodChart data={moodMock} title="Mood"></MoodChart>
        </Col>
        <Col xs="12" lg="6" xxl="4">
          <MoodDistributionChart data={moodDistributionMock} title="Mood distribution"></MoodDistributionChart>
        </Col>
        <Col xs="12" lg="6" xxl="4">
          <Anxiety data={anxiety} title="Anxiety Chart"></Anxiety>
        </Col>

        <Col xs="12" lg="6" xxl="4">
          <AverageDailyAnxiety data={averageDailyAnxiety} title="Average Daily Anxiety"></AverageDailyAnxiety>
        </Col>
        <Col xs="12" lg="6" xxl="4">
          <AnxietyDistribution data={anxietyDistribution} title="Anxiety Distribution"></AnxietyDistribution>
        </Col>
        <Col xs="12" lg="6" xxl="4">
          <MoodAndAnxiety data={moodAndAnxiety} title="Mood and Anxiety"></MoodAndAnxiety>
        </Col>
      </Row>
    </div>
  )
}

export default C
