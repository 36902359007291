import _ from 'lodash'
import Button from '../../../components/button'
import React, { useEffect, useState } from 'react'
import { Alert, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from 'reactstrap'
import { postGenerate, postExport, postsSearch } from '../../../api/posts'
import { getImageUrl } from '../../../utils/get-image-url'
import PexelsImages from './pexels'
import parseContent from './parse-content'
import SelectableImages from './selectable-images'
import InternalPostsIndex from './indernal-posts-index'
import InternalPostsSearch from './indernal-posts-search'

const postTypes = [
  'Listical',
  'Non Listical'
]

const Posts = () => {
  const [query, setQuery] = useState('')
  const [keywords, setKeywords] = useState('')
  const [post, setPost] = useState()
  const [postType, setPostType] = useState('Listical')
  const [images, setImages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [pexelsImages, setPexelsImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [postAdded, setPostAdded] = useState(false)
  const [error, setError] = useState(null)
  const [, setInternalLinks] = useState([])
  const [postTypeDropdownOpen, setPostTypeDropdownOpen] = useState(false)
  const [internalLinksSelected, setInternalLinksSelected] = useState([])

  useEffect(() => {
    setError(null)
    setPostAdded(false)
    setExporting(false)
    setSelectedImages([])
    setInternalLinks([])
  }, [keywords, query, postType])

  const generate = async () => {
    const cacheKey = JSON.stringify({ keywords, query, postType })

    setLoading(true)
    let data = null

    try {
      if (window.localStorage.getItem(cacheKey)) {
        data = JSON.parse(window.localStorage.getItem(cacheKey))
      } else {
        const internalPosts = await postsSearch(keywords)
        const internalLinks = JSON.parse(internalPosts.data.posts).map(
          post => {
            return `https://blog.earkick.com/${post.post_name}/`
          }
        )

        const options = {
          keywords,
          query,
          post_type: postType,
          internal_links: internalLinks
        }

        data = await postGenerate(options)
        window.localStorage.setItem(cacheKey, JSON.stringify(data))
      }
    } catch (e) {
      console.log(e)
      setError('Error generating post')
    }
    setLoading(false)

    if (!data.data.title || data.data.title.length === 0) {
      data.data.title = query

      const lines = data.data.content.split('\n')
      if (lines[0].length < 100) {
        data.data.title = lines[0]
        data.data.content = lines.slice(1).join('\n')
      }
    }

    setImages(await Promise.all(data.data.images.map(image => {
      if (!image) {
        return null
      }

      return getImageUrl(`gs://${image}`)
    })))

    setPost(data)
    setPostAdded(false)
  }

  const exportPost = async () => {
    const options = {
      key: 'EarkickArray()IsJustAmazing',
      title: post.data.title.replaceAll('"', ''),
      excerpt: post.data.excerpt.replaceAll('"', ''),
      content: parseContent(
        post.data.content,
        post.data.videos,
        [..._.map(internalLinksSelected, l => l.url), ..._.uniq(post.data.citations)],
        [...selectedImages, ...pexelsImages]
      )
    }

    setExporting(true)
    try {
      await postExport(options)
      setPostAdded(true)
    } catch (e) {
      console.log(e)
    }

    setExporting(false)
  }

  return (
    <Container>
      <InternalPostsIndex />
      <Row>
        <h1>Generate Blog Post</h1>
      </Row>

      <Row>
      <FormGroup>
        <Label for="query">Query</Label>
          <Input
            className="form-control"
            type="text"
            placeholder=""
            name="query"
            onChange={e => setQuery(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="keywords">Keywords</Label>
          <Input
            className="form-control"
            type="text"
            placeholder=""
            name="keywords"
            onChange={e => setKeywords(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="keywords">Post type</Label>

          <Dropdown isOpen={postTypeDropdownOpen} toggle={() => setPostTypeDropdownOpen(!postTypeDropdownOpen)} className="post-type-dropdown">
            <DropdownToggle caret>{postType}</DropdownToggle>
            <DropdownMenu>
              {postTypes.map(postTypeItem => (

                <DropdownItem key={postTypeItem} onClick={() => {
                  setPostType(postTypeItem)
                  setPostTypeDropdownOpen(!postTypeDropdownOpen)
                }}>{postTypeItem}</DropdownItem>
              ))}

            </DropdownMenu>
          </Dropdown>
        </FormGroup>
        <FormGroup className="mt-4">
          <Button loading={loading} color="primary" text="Generate" onClick={() => generate()}></Button>
        </FormGroup>

        {error &&
          <Alert color="danger">
            {error}
          </Alert>
        }

        {loading &&
          <Alert color="info">
            The reason this function is slow is that we have an intentional delay of 3 seconds between each query to avoid being banned by the search engine. Therefore, if we use 4 sources (e.g., healthline.com;theguardian.com;mayoclinic.org;wikipedia.org), the function will take at least 12 seconds to generate the blog post. I don’t think we want to mess too much with this delay cause we could risk our IP address being banned from the search engine.
          </Alert>
        }
        {post && (
          <Row className="mt-5">
            <h1>{post.data.title || 'No title yet :)'}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.data.content.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />

            <SelectableImages
              images={images} onChange={setSelectedImages}
            />
            <PexelsImages
              key={`${post.data.revised_image_prompt} ${query} ${keywords}`}
              queries={[`${post.data.revised_image_prompt}`, query, keywords]}
              keywords={keywords}
              onChange={setPexelsImages}
            />
            {post.data.videos && post.data.videos.length > 0 && (
              <div className="my-5">
                <h2>Videos</h2>
                {_.orderBy(post.data.videos, ['view_count'], ['desc']).map((video, index) => (
                  <div key={index} className="my-5">
                    <h3>{video.title}</h3>
                    <p>{video.description}</p>
                    <p>View count: <b>{video.view_count}</b></p>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${video.video_id}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen={true}
                    >
                    </iframe>
                  </div>
                ))}
              </div>
            )}
            {post.data.citations &&
              <div className="my-5">
                <h2>Citations</h2>
                {_.uniq(post.data.citations).map((citation, index) => (
                  <ul key={index} className="my-4">
                    <li><a href={citation} target="_blank" rel="noreferrer">{citation}</a></li>
                  </ul>
                ))}
              </div>
            }

            <InternalPostsSearch
              defaultQuery={`${keywords} ${post.data.title} ${post.data.excerpt}`}
              onChange={links => setInternalLinks(links)}
              selectLink={link => setInternalLinksSelected(_.uniq([...internalLinksSelected, link]))}
            />

            <div className="my-5">
              <h3>Selected Internal Links</h3>
              {internalLinksSelected.length < 1 &&
                <p>No internal links selected</p>
              }
              {internalLinksSelected.length > 0 &&
                <ul>
                  {internalLinksSelected.map((result) => {
                    return <li
                      className="selectable-link remove-link"
                      key={`selectable-link remove-link ${result.id}`}
                      title={`ID: ${result.id} Similarity score ${Math.floor(100 * result.similarity)}`}
                      onClick={() => setInternalLinksSelected(internalLinksSelected.filter(link => link.id !== result.id))}
                    >
                        <a href={result.url} target="_blank" rel="noreferrer">{result.title || result.url}</a>
                      </li>
                  })}
                </ul>
              }
            </div>

            <Button
              loading={exporting}
              color="primary"
              text="Export to Wordpress"
              onClick={() => exportPost()}
              disabled={postAdded}
            ></Button>
          </Row>
        )}
      </Row>

    </Container>
  )
}

export default Posts
