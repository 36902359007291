import React, { memo, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useForm } from 'react-hook-form'
import { Container, Row, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ModalClose from '../../../../../components/Modal/close'
import Session from '../components/session'
import { btnStyles } from '../../../../../utils/constants'
import Button from '../../../../../components/button'
import Input from '../../../../../components/Input/index2'
import { fileCopy } from '../../../../../api/functions'

const copyStorageFile = async (accountId, userId, sessionId, file, newId) => {
  const newPath = `accounts/${accountId}/users/${userId}/sessions/${newId}`
  const filePath = file.split('/')
  const newFilename = `${newPath}/${filePath[filePath.length - 1]}`

  const { data } = await fileCopy({
    file,
    newFilename
  })

  return data.ref
}

const ConfigureSessionTemplate = memo(({
  back,
  toggle,
  session,
  accountId,
  userId,
  createSession
}) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const {
    getValues, handleSubmit, register, reset, formState: { errors }
  } = useForm({
  })

  useEffect(() => {
    if (session?.type === 'breathing') {
      reset({
        eRepetitions: session.eRepetitions
      })
    }
  }, [session, reset])

  const onFormSubmit = async () => {
    const newId = uuid()
    setSubmitLoading(true)
    const values = getValues()
    const newSession = Object.assign({}, session)
    newSession.id = newId

    if (session?.type === 'breathing') {
      newSession.eRepetitions = parseInt(values.eRepetitions, 10)
    }

    if (session.image) {
      newSession.image = await copyStorageFile(accountId, userId, session.id, session.image, newId)
    }

    if (session.audio) {
      newSession.audio = await copyStorageFile(accountId, userId, session.id, session.audio, newId)
    }

    await createSession(newSession)

    setSubmitLoading(false)
    toggle()
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <ModalHeader close={<ModalClose toggle={toggle} />}>
        {session.title}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <Session
                sessionSelected={() => false}
                session={session}
              />
            </Col>
            <Col>
              {session?.type === 'breathing' &&
                <Input
                  type='number'
                  step='0.1'
                  name='eRepetitions'
                  label='Repetitions'
                  required={() => true}
                  placeholder='0'
                  register={register}
                  errors={errors}
                />
              }
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button text="Back" btnStyle={btnStyles.simple} onClick={() => back()} />
        <Button type="submit" loading={submitLoading} text="Create" />
      </ModalFooter>
    </form>
  )
})

ConfigureSessionTemplate.displayName = 'ConfigureSessionTemplate'

export default ConfigureSessionTemplate
