import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Col, Input as RInput, Label, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap'
import Modal from '../../../Modal/index'
import Button from '../../../button'
import Input from '../../../Input/index2'
import Select from '../../../Select'
import '../../../Input/index.scss'
import './index.scss'

const getTitle = (action) => {
  if (!action?.metadata || action.metadata.title === '') {
    return 'Add routine'
  }

  return action.metadata.title
}

const DayCheckbox = ({ name, onChange, checked = false }) => (
  <>
    <Label for={`date-${name}`}>
      {name}
    </Label>
    <br />
    <RInput
      checked={checked}
      id={`date-${name}`}
      onChange={e => onChange(e.target.checked)}
      type="checkbox"
    />
  </>
)

const ActionModal = ({
  groups,
  groupId,
  isOpen,
  onSubmit,
  actionId,
  toggle,
  uploadPath
}) => {
  const group = _.find(groups, { id: groupId })
  const action = _.find(group.metadata.actions, { id: actionId })
  const [eType, setEType] = useState(action?.eType || 'daily')
  const [dailyPreset, setDailyPreset] = useState('every-day')
  const [lockSave, setLockSave] = useState(false)
  const [days, setDays] = useState(action?.days || [1, 2, 3, 4, 5, 6, 7])

  useEffect(() => {
    updateDailyPreset()
  })

  const {
    handleSubmit,
    getValues,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      eType: 'daily',
      dailyRepetition: 'every-day',
      eRepetitions: 1,
      ...action
    }
  })

  const dailyPresetChanged = value => {
    setDailyPreset(value)

    if (value === 'every-day') {
      setDays([1, 2, 3, 4, 5, 6, 7])
    } else if (value === 'week-days') {
      setDays([1, 2, 3, 4, 5])
    } else if (value === 'weekend') {
      setDays([6, 7])
    }
  }

  const getSingular = (type) => {
    if (type === 'daily') {
      return 'Day'
    } else if (type === 'weekly') {
      return 'Week'
    } else if (type === 'monthly') {
      return 'Month'
    }
  }

  const updateDailyPreset = () => {
    if (days.length === 7) {
      setDailyPreset('every-day')
    } else if (_.difference([6, 7], days.sort((a, b) => a - b)).length === 0) {
      setDailyPreset('weekend')
    } else if (_.difference([1, 2, 3, 4, 5], days.sort((a, b) => a - b)).length === 0) {
      setDailyPreset('week-days')
    } else {
      setDailyPreset('custom')
    }
  }

  const processDayChange = (day, checked) => {
    let syncDays
    if (checked) {
      syncDays = [...days, day]
      setDays(syncDays)
    } else {
      syncDays = _.without(days, day)
      setDays(syncDays)
    }

    updateDailyPreset()
  }

  const onFormSubmit = () => {
    setLockSave(true)
    const values = getValues()

    _.each(values, (value, key) => {
      if (_.includes(['eRepetitions'], key)) {
        values[key] = parseInt(value, 10)
      }
    })

    values.eType = eType

    if (eType === 'daily') {
      values.days = days
    } else {
      delete (values.days)
    }

    onSubmit(group.id, {
      ...values,
      updatedAt: new Date()
    })
  }

  return (
    <Modal
      title={false}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form className="action-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <ModalHeader>{getTitle(action)}</ModalHeader>
          </Col>
          <Col xs="3">
            <Select
              name='eType'
              required={() => true}
              options={[{
                value: 'daily',
                name: 'Daily'
              }, {
                value: 'weekly',
                name: 'Weekly'
              }, {
                value: 'monthly',
                name: 'Monthly'
              }]}
              register={register}
              errors={errors}
              onChange={e => setEType(e.target.value)}
              value={eType}
            />
          </Col>
        </Row>

        <ModalBody>
          <Row className='action-form'>
            <Col>
              <Input
                name='title'
                label='Title'
                required={() => true}
                placeholder='Enter title'
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            {eType === 'daily' &&
              <>
                <Col xs="4">
                  <Select
                    name='dailyPreset'
                    value={dailyPreset}
                    required={() => true}
                    options={[{
                      value: 'every-day',
                      name: 'Every Day'
                    }, {
                      value: 'custom',
                      name: 'Custom'
                    }, {
                      value: 'weekend',
                      name: 'Weekend'
                    }, {
                      value: 'week-days',
                      name: 'Week Days'
                    }]}
                    register={register}
                    errors={errors}
                    onChange={e => dailyPresetChanged(e.target.value)}
                  />
                </Col>
                <Col>
                  <Row className="day-selection">
                    <Col className="offset-md-1">
                      <DayCheckbox
                        name="Su"
                        onChange={value => processDayChange(7, value)}
                        checked={days.indexOf(7) !== -1}
                      />
                    </Col>
                    <Col>
                      <DayCheckbox
                        name="Mo"
                        onChange={value => processDayChange(1, value)}
                        checked={days.indexOf(1) !== -1}
                      />
                    </Col>
                    <Col>
                      <DayCheckbox
                        name="Tu"
                        onChange={value => processDayChange(2, value)}
                        checked={days.indexOf(2) !== -1}
                      />
                    </Col>
                    <Col>
                      <DayCheckbox
                        name="We"
                        onChange={value => processDayChange(3, value)}
                        checked={days.indexOf(3) !== -1}
                      />
                    </Col>
                    <Col>
                      <DayCheckbox
                        name="Th"
                        onChange={value => processDayChange(4, value)}
                        checked={days.indexOf(4) !== -1}
                      />
                    </Col>
                    <Col>
                      <DayCheckbox
                        name="Fr"
                        onChange={value => processDayChange(5, value)}
                        checked={days.indexOf(5) !== -1}
                      />
                    </Col>
                    <Col>
                      <DayCheckbox
                        name="Sa"
                        onChange={value => processDayChange(6, value)}
                        checked={days.indexOf(6) !== -1}
                      />
                    </Col>

                  </Row>
                </Col>
              </>
            }
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Input
                    name='eRepetitions'
                    label='Repetitions'
                    required={() => true}
                    placeholder=''
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col className="repetitions-label"><span>Times per {getSingular(eType)}</span></Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button type='submit' disabled={lockSave} text="Save" />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default ActionModal
