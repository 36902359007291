import Joi from 'joi'

export const passwordSchema = () => Joi.string().regex(/.{6,}/).required().messages({
  'string.pattern.base': 'Password must be at least 6 characters',
  'string.empty': 'Password cannot be empty'
})

export const emailSchema = () => Joi.string().email({ tlds: { allow: false } })
  .required().messages({
    'string.email': 'Email must be correct'
  })

export const optionsSchema = () => Joi.array().items(
  Joi.object({
    id: Joi.string(),
    value: Joi.string(),
    label: Joi.string(),
    price: Joi.number()
  })
).min(1).required()
  .messages({
    'array.min': 'Please select at least one option'
  })
