import React, { Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import './index.scss'

const ConfirmModal = ({
  onClose,
  message,
  title,
  confirmText,
  cancelText,
  confirmColor,
  cancelColor,
  className,
  buttonsComponent,
  size,
  bodyComponent,
  modalProps
}) => {
  let buttonsContent = (
    <Fragment>
      {cancelText && (
        <Button color={cancelColor} onClick={() => onClose(false)}>
          {cancelText}
        </Button>
      )}{' '}
      <Button className="confirmation-button" color={confirmColor} onClick={() => onClose(true)}>
        {confirmText}
      </Button>

    </Fragment>
  )

  if (buttonsComponent) {
    const CustomComponent = buttonsComponent
    buttonsContent = <CustomComponent onClose={onClose} />
  }

  const BodyComponent = bodyComponent

  return (
    <Modal
      size={size}
      isOpen
      toggle={() => onClose(false)}
      className={`reactstrap-confirm ${className}`}
      {...modalProps}
    >
      {title && (
        <ModalHeader toggle={() => onClose(false)}>{title || null}</ModalHeader>
      )}
      <ModalBody>{bodyComponent ? <BodyComponent /> : message}</ModalBody>
      <ModalFooter>{buttonsContent}</ModalFooter>
    </Modal>
  )
}

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  title: 'Warning!',
  confirmText: 'Ok',
  cancelText: 'Cancel',
  confirmColor: 'primary',
  cancelColor: '',
  className: '',
  buttonsComponent: null,
  size: null,
  bodyComponent: null,
  modalProps: {}
}

export default ConfirmModal
