import React from 'react'
import { getMixpanel } from '../../../../api/mixpanel'
import './trial-expired.scss'

const mixpanel = getMixpanel()

const TrialExpired = () => {
  const trackEmailClick = () => {
    mixpanel.track('Upgrade finalized: contact by email J22')
  }

  return (
    <div className="trial-expired">
      <h1>Free trial expired</h1>
      <div>
        <center onClick={trackEmailClick}>Contact us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> to upgrade.</center>
      </div>
    </div>
  )
}

export default TrialExpired
