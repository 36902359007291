import React, { memo } from 'react'

const Settings = memo(() => (
  <div>
    Settings page
  </div>
))

Settings.displayName = 'Settings'

export default Settings
