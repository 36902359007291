import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router'
import { Badge, Button, Col, Container, Row, Table } from 'reactstrap'
import { contentSubscribeAll, contentUpdate, contentGenerateVector } from '../../../api/content'
import Loader from '../../../components/Loader'
import './index.scss'
import { cacheGetItem, cacheSetItem } from '../../../api/cache'

const sortContents = (contents) => {
  return _.sortBy(contents, ['metadata.createdAt']).reverse()
}

const ContentComponent = () => {
  const navigate = useNavigate()
  const [contents, setContents] = useState(sortContents(cacheGetItem('contents')) || [])
  const [loading, setLoading] = useState(cacheGetItem('contents') === null)

  useEffect(() => {
    return contentSubscribeAll(contents => {
      cacheSetItem('contents', contents)
      setContents(sortContents(contents))
      setLoading(false)
    })
  }, [])

  const createNew = async () => {
    const id = uuid()

    navigate(`/admin/content/${id}`)
  }

  const refreshVector = async (id) => {
    await contentUpdate(id, { vectorRequiresRefresh: true, vector: false })
    await contentGenerateVector({ id })
  }

  return (
    <Container className="content-list">
      <Row>
        <Col xs="6">
          <h3 className='page-header__title'>Content</h3>
        </Col>
        <Col xs="3">
          <Button onClick={() => createNew()}>New content</Button>
        </Col>
        <Col xs="3">
          <Button onClick={() => navigate('/admin/content/search')}>Test search</Button>
        </Col>
      </Row>

      {!loading &&
        <Row>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th className="created-at">Created At</th>
                <th>Category</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contents.map(content => (
                <tr key={content.id}>
                  <td className="content-title" onClick={() => navigate(`/admin/content/${content.id}`)}>{content.metadata.name || 'Untitled'}</td>
                  <td>{content.metadata.createdAt?.toDate()?.toISOString().replace('T', ' ').replace('Z', '').substring(0, 10)}</td>
                  <td>{content.metadata.category}</td>
                  <td>
                    {!content.metadata.vector &&
                      <Badge
                        color="danger"
                        pill
                      >
                        No vector
                      </Badge>
                    }
                    {(!content.metadata.url || content.metadata.url.length < 6) &&
                      <Badge
                        color="danger"
                        pill
                      >
                        No url (length)
                      </Badge>
                    }
                    {content.metadata.url && content.metadata.url.split('https').length > 2 &&
                      <Badge
                        color="danger"
                        pill
                      >
                        Multiple urls (https {content.metadata.url.split('https').length})
                      </Badge>

                    }
                    {(content.metadata.url && content.metadata.url.split(' ').length > 2) &&
                      <Badge
                        color="danger"
                        pill
                      >
                        Multiple urls (space)
                      </Badge>
                    }
                    {content.metadata.published &&
                      <Badge
                        color="success"
                        pill
                      >
                        Published
                      </Badge>
                    }
                  </td>
                  <td className="model-name">
                    {content.metadata.embeddingModel}
                    {!content.metadata.embeddingModel && <Button onClick={() => refreshVector(content.id)}>Refresh</Button> }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      }
      {loading &&
        <Row className="center">
          <Loader></Loader>
        </Row>
      }
    </Container>
  )
}

export default ContentComponent
