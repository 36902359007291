import React, { useState } from 'react'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap'
import './dropdown/index.scss'

const ActionsMenu = ({ onDelete, onUnDelete }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className="dropdown">
      <DropdownToggle
        caret
        aria-expanded={false}
        className="button button_type_default"
        cssModule={{
          btn: true,
          'btn-secondary': true
        }}
      >
        Actions
      </DropdownToggle>
      <DropdownMenu
        end
        flip={false}
      >
        <DropdownItem onClick={() => onDelete()} className="delete">
          Delete
        </DropdownItem>
        <DropdownItem onClick={() => onUnDelete()} className="delete">
          Undelete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ActionsMenu
