import React, { memo, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import PageHeader from '../../../components/PageHeader'
import Button from '../../../components/button'
import Loader from '../../../components/Loader'
import { ReactComponent as PlusIcon } from '../../../images/plus.svg'
import { Container, Row } from 'reactstrap'
import './index.scss'
import BadgeGroup from '../../../components/badges/group'
import BadgeModal from '../../../components/badges/modals/badge'
import NewGroupModal from '../../../components/badges/modals/group-new'
import EditGroupModal from '../../../components/badges/modals/group-edit'
import { getItemKey } from '../../../api/db'
import { deleteFile } from '../../../api/storage'
import {
  badgeUpdate,
  badgeGroupCreate,
  badgeGroupDelete,
  badgeGroupUpdate,
  badgeCreate,
  badgesUpdate,
  badgeGroupsSubscribeAll
} from '../../../api/badges'
import { appDomain } from '../../../config'

const AddGroupButtonText = () => (
  <div className="d-flex align-items-center gap-3">
    <PlusIcon style={{
      width: '11px',
      height: '12px'
    }}
    />
    Add Group
  </div>
)

const Badges = memo(() => {
  const [groups, setGroups] = useState()
  const [loading, setLoading] = useState(true)
  const [isAddBadgeModalOpen, setIsAddBadgeModalOpen] = useState(false)
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditGroupModalOpen] = useState(false)
  const [currentGroupId, setcurrentGroupId] = useState(null)
  const [currentBadgeId, setcurrentBadgeId] = useState(null)

  useEffect(() => {
    const unsubscribe = badgeGroupsSubscribeAll((badges) => {
      const badgesOrdered = _.sortBy(badges, badge => badge.metadata.rank)

      setGroups(badgesOrdered)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const openNewGroupModal = () => {
    setIsNewGroupModalOpen(true)
  }

  const deleteGroup = (groupId) => {
    const files = []
    const group = _.find(groups, { id: groupId })

    _.each(group.metadata.badges, badge => {
      if (badge.image) {
        files.push(badge.image)
      }

      if (badge.file) {
        files.push(badge.file)
      }
    })

    files.forEach(file => {
      deleteFile(file)
    })

    setcurrentGroupId(null)
    badgeGroupDelete(groupId)
  }

  const editGroup = (groupId) => {
    setcurrentGroupId(groupId)
    setIsEditGroupModalOpen(true)
  }

  const openBadgeModal = (groupId, badgeId = null) => {
    if (!badgeId) {
      badgeId = uuid()
    }

    setcurrentGroupId(groupId)
    setcurrentBadgeId(badgeId)
    setIsAddBadgeModalOpen(true)
  }

  const resetBadgeModal = () => {
    setIsAddBadgeModalOpen(false)
    setcurrentBadgeId(null)
    setcurrentGroupId(null)
  }

  const resetAddNewGroupModal = () => {
    setIsNewGroupModalOpen(false)
  }

  const resetEditGroupModal = () => {
    setIsEditGroupModalOpen(false)
    setcurrentGroupId(null)
  }

  const badgesUpdated = (groupId, badges) => {
    const group = _.find(groups, { id: groupId })
    badgesUpdate(group, badges)
  }

  const onBadgeSubmit = (groupId, badgeData) => {
    const group = _.find(groups, { id: groupId })
    const badges = group.metadata.badges
    if (_.findIndex(badges, { id: currentBadgeId }) !== -1) {
      badgeUpdate(group, currentBadgeId, badgeData)
    } else {
      badgeData.rank = _.size(badges) + 1
      badgeCreate(group, badgeData)
    }
    resetBadgeModal()
  }

  const onAddNewGroupSubmit = async (values) => {
    const group = {
      name: values.title,
      rank: groups.length > 0 ? _.last(groups).metadata.rank + 1 : 1,
      badges: []
    }
    badgeGroupCreate(group)
    setIsNewGroupModalOpen(false)
  }

  const onEditGroupSubmit = async (values) => {
    badgeGroupUpdate(currentGroupId, { name: values.title })
    resetEditGroupModal()
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newGroups = Array.from(groups)
    const [reorderedItem] = newGroups.splice(result.source.index, 1)
    newGroups.splice(result.destination.index, 0, reorderedItem)

    const groupsChanged = []
    newGroups.map((group, index) => {
      if (group.metadata.rank !== index + 1) {
        groupsChanged.push({
          badgeId: group.id,
          rank: index + 1
        })
      }
      return true
    })

    setGroups(newGroups)

    groupsChanged.forEach(({ badgeId, rank }) => {
      badgeGroupUpdate(badgeId, { rank })
    })
  }

  return (
    <Container className="badges">
      <PageHeader title="Badges">
        <Button onClick={() => openNewGroupModal()} text={<AddGroupButtonText />} />
      </PageHeader>
      <div className="badges__content">
        {!loading &&
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="group__header">
              {(provided) => (
                <ul className="badges__list" {...provided.droppableProps} ref={provided.innerRef}>
                  {groups?.map((group, index) => (
                    <Draggable key={getItemKey(group)} draggableId={`badge-group-${group.id}`} index={index}>
                      {(prov) => (
                        <BadgeGroup
                          group={group}
                          forwardRef={prov.innerRef}
                          draggableProps={prov.draggableProps}
                          dragHandleProps={prov.dragHandleProps}
                          title={group.metadata.name}
                          items={group.metadata.badges}
                          groupId={group.id}
                          addBadgeHandle={openBadgeModal}
                          editGroupHandle={editGroup}
                          deleteGroupHandle={deleteGroup}
                          editBadgeHandle={openBadgeModal}
                          badgesUpdated={badgesUpdated}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        }
        {loading &&
          <Row className="center">
            <Loader></Loader>
          </Row>
        }
      </div>
      {isAddBadgeModalOpen &&
        <BadgeModal
          isOpen={isAddBadgeModalOpen}
          onSubmit={onBadgeSubmit}
          toggle={resetBadgeModal}
          badgeId={currentBadgeId}
          groupId={currentGroupId}
          groups={groups}
          uploadPath={'badges'}
          linkPath={`https://${appDomain}/badge?badgeId=${currentBadgeId}&groupId=${currentGroupId}&accountId=public`}
        />
      }
      {isNewGroupModalOpen &&
        <NewGroupModal
          title="Add a group"
          isOpen={isNewGroupModalOpen}
          toggle={resetAddNewGroupModal}
          onSubmit={onAddNewGroupSubmit}
        />
      }
      {isEditModalOpen &&
        <EditGroupModal
          title="Edit group"
          group={_.find(groups, { id: currentGroupId })}
          isOpen={isEditModalOpen}
          toggle={resetEditGroupModal}
          onSubmit={onEditGroupSubmit}
        />
      }
    </Container>
  )
})

Badges.displayName = 'Badges'

export default Badges
