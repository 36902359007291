import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { getItemKey } from '../../../../../api/db'
import Session from './session'

const sortCards = (items) => {
  return _.sortBy(_.values(items), (item) => parseInt(item.rank, 10))
}

const Group = memo(({
  items = [],
  group,
  sessionSelected
}) => {
  const [cards, setCards] = useState([])

  useEffect(() => {
    if (items.length > 0) {
      setCards(sortCards(items))
    }
  }, [items])

  return (
    <div className="group">
      <h3 className="group__title handle">{group.metadata.name}</h3>
      <ul className="group__list">
        {cards.map((session, index) => (
          <div key={getItemKey(session)}>
            <Session
              sessionSelected={sessionSelected}
              session={session}
            />
          </div>
        ))}
      </ul>

    </div>
  )
})

Group.displayName = 'SessionGroupNoEdit'

export default Group
