import React from 'react'
import './hr.scss'

const Hr = ({ children }) => {
  return (
    <div className='hr'>
      <div className='hr__line' />
      <div className='hr__content'>{children}</div>
      <div className='hr__line' />
    </div>
  )
}

export default Hr
