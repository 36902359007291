const action = {
  dailyPreset: 'every-day',
  eRepetitions: 2,
  eType: 'daily',
  days: [1, 2, 3, 4, 5, 6, 7],
  title: 'Brush teeth',
  code: 'brushteeth'
}

export default action
