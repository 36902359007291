import { subscribeFiltered, subscribe, update, subscribeOne, remove } from './db'

const collectionName = 'queue'

const queueDelete = (queueId) => {
  return remove(collectionName, queueId)
}

const queueSubscribe = (callback) => {
  return subscribe(collectionName, null, callback)
}

const queueSubscribeOne = (id, callback) => {
  return subscribeOne(collectionName, id, callback)
}

const queueUpdate = (queueId, data) => {
  return update(collectionName, queueId, data)
}

const subscribeMemberQueue = (appId, mutation) => {
  return subscribeFiltered(
    collectionName,
    [['eventData.appId', '==', appId]],
    [['executeAt', 'desc']],
    100,
    mutation
  )
}

export { queueDelete, subscribeMemberQueue, queueSubscribe, queueSubscribeOne, queueUpdate }
