import React, { memo } from 'react'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../../../components/Modal/index'
import Button from '../../../../../components/button'
import { btnStyles } from '../../../../../utils/constants'

export default memo(function ModalUserDelete ({
  isOpen,
  toggle,
  onSubmit,
  users
}) {
  return (
    <Modal
      title={'Are you sure you want to delete following users?'}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalBody>
        <ul>
          {users.map((user, index) =>
            <li key={user.id}>{user.metadata.firstname} {user.metadata.lastname}</li>
          )}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button text='Cancel' btnStyle={btnStyles.simple} onClick={toggle} />
        <Button type='submit' text='Delete' onClick={() => onSubmit()} />
      </ModalFooter>
    </Modal>
  )
})
