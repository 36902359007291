import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../api/firebase'
import { ReactComponent as PencilWhiteIcon } from '../../images/pencil-white-fill.svg'
import { ReactComponent as TrashIcon } from '../../images/trash.circle.white.fill.svg'
import { ReactComponent as CopySolid } from '../../images/copy-solid.svg'
import { ReactComponent as StarIcon } from '../../images/star.svg'
import { cssColor } from '../../utils/css-helpers'

import './session.scss'

const Session = (props) => {
  const {
    session,
    forwardRef,
    draggableProps,
    dragHandleProps,
    editSession,
    deleteSession,
    togglePremium
  } = props

  const [imageUrl, setImageUrl] = useState(null)
  const [isMounted, setIsMounted] = useState(true)
  const account = useSelector((state) => state.accountReducers.account)
  const admin = account?.metadata?.admin || false

  async function getImageUrl (image) {
    if (!image) {
      return null
    }

    try {
      const url = await getDownloadURL(ref(storage, image))
      return `url(${url})`
    } catch (e) {
      return false
    }
  }

  useEffect(() => {
    getImageUrl(session.image).then(image => {
      if (isMounted) {
        setImageUrl(image)
      }
    })

    return () => setIsMounted(false)
  }, [])

  const copyId = (id) => {
    navigator.clipboard.writeText(id)
    toast.info('Session id copied to clipboard')
  }

  return (
    <li className='group-item' ref={forwardRef} {...draggableProps} {...dragHandleProps} data-id={session.id}>
      <div className='group-item__container'>
      <button type='button' className='group-item__edit-btn' onClick={() => editSession(session.id)}>
          <PencilWhiteIcon title='Edit' />
        </button>
        {admin && (
          <button
            type='button'
            className='group-item__copy-id-btn'
            onClick={() => copyId(session.id)}>
              <CopySolid title='Copy Id' />
          </button>
        )}
        <button type='button' className='group-item__delete-btn' onClick={() => deleteSession(session.id)}>
          <TrashIcon title='Delete' />
        </button>
        <button type='button' className={`group-item__premium-btn ${session.premium ? 'premium' : ''}`} onClick={() => togglePremium(session.id)}>
          <StarIcon title='Toggle premium' />
        </button>
        {
          session.img
            ? <img src={session.img} alt='overlay' className='group-item__img' />
            : (
              <div
                className='group-item__img'
                style={{
                  backgroundColor: cssColor(session.color),
                  backgroundImage: imageUrl
                }}
              />
              )
        }

      </div>
      <h3 className='group-item__title'>
        {session.title}
      </h3>
    </li>
  )
}

export default Session
