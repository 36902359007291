import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
const storage = getStorage()

const uploadFile = (file, name) => {
  try {
    return uploadBytesResumable(ref(storage, name), file)
  } catch (e) {
    console.log(e)
  }
}

const getFileUrl = async (name) => {
  if (!name) {
    return false
  }

  if (name.indexOf('https://') === 0) {
    return name
  }

  try {
    return await getDownloadURL(ref(storage, name))
  } catch (e) {
    console.log(e)
  }
}

const deleteFile = async (name) => {
  try {
    await deleteObject(ref(storage, name))
  } catch (e) {
    console.log(e)
  }
}

export {
  deleteFile,
  getFileUrl,
  uploadFile
}
