import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from './saga'

const store = () => {
  const sagaMiddleware = createSagaMiddleware()

  const sagasStore = configureStore({
    reducer: reducers,
    middleware: [sagaMiddleware]
  })

  sagaMiddleware.run(rootSaga)

  return sagasStore
}

export default store()
