/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  ModalBody, ModalFooter,
} from 'reactstrap';
import Modal from '../../../components/Modal/index';
import Button from '../../../components/button';
import Input from '../../../components/Input/index2';
import { btnStyles } from '../../../utils/constants';
import '../../../components/Input/index.scss';

export default ({
  title,
  isOpen,
  toggle,
  onSubmit,
  group,
}) => {
  const {
    getValues, handleSubmit, register, reset, formState: { errors },
  } = useForm({
  });

  useEffect(() => {
      reset({ title: group.metadata.name });
  }, [group, reset]);

  const onFormSubmit = () => {
    const values = getValues();
    onSubmit(values);
  };

  return (
    <Modal
      title={title}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className="session-form">
            <Input
              name="title"
              label="Title"
              register={register}
              errors={errors}
              required={() => true}
            />
          </div>

        </ModalBody>
        <ModalFooter>
          <Button text="Cancel" btnStyle={btnStyles.simple} onClick={toggle} />
          <Button type="submit" text="Save" />
        </ModalFooter>
      </form>
    </Modal>
  );
};
