import React, { useState } from 'react'
import { Button, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { addDays } from '../../../../utils/date'
import { Timestamp } from 'firebase/firestore'
import { memberUpdate } from '../../../../api/members'
import { useSelector } from 'react-redux'

const MakePremiumDialog = ({ member, toggle, isOpen }) => {
  const user = useSelector((state) => state.userReducers.user)
  const [content, setContent] = useState('')
  const [expiryDate, setExpiryDate] = useState(addDays(new Date(), 365).toISOString().substring(0, 10))

  const makePremium = () => {
    const existingChange = member.metadata.subscriptionChange || []
    const subscriptionChange = [
      ...existingChange,
      {
        createdAt: new Timestamp(new Date().getTime() / 1000, 0),
        operation: 'manual_purchase',
        productId: 'manual',
        platform: 'admin',
        content: `${content} Added by: ${user.id}`,
        expiryDate: (new Date(expiryDate)).toISOString().substring(0, 10)
      }
    ]

    memberUpdate(member.id, { subscriptionChange })

    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader >Give a premium</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <p>You can leave a note here:</p>
            <Input type="textarea" onChange={(e) => setContent(e.target.value)} />
          </Row>

          <Row>
            <p>Expiry date (format: YYYY-mm-dd)</p>
            <Input type="text" onChange={(e) => setExpiryDate(e.target.value)} defaultValue={expiryDate} />
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => makePremium()}>Create</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MakePremiumDialog
