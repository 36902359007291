/* eslint-disable */
import { put, takeLatest } from 'redux-saga/effects'
import actions from '../actions'
import constants from '../constants'
import { checkUserAuth, signInUser, signOutUser } from '../../api/auth'
import { userGet } from '../../api/users'
import { accountGetById } from '../../api/accounts'
import { cacheClear } from '../../api/cache'
import { getMixpanel } from '../../api/mixpanel'

const mixpanel = getMixpanel()

const {
  showError, setAuthUser, setUser, setAccount, unsetAccount, setIsLoading, setComponentIsLoading
} = actions

function* checkUser() {
  try {
    yield put(setIsLoading(true))
    const userAuth = yield checkUserAuth()
    yield put(setAuthUser(userAuth))
    const user = yield userGet(userAuth.email)
    yield put(setUser(user))
    const account = yield accountGetById(user.metadata.account)
    yield put(setAccount(account))
    mixpanel.identify(user.id)
    mixpanel.track('$identify', { '$identified_id': user.id })
    mixpanel.people.set({
      'X-debug': 'mixpanel.people.set',
      'App': 'Dashboard',
      'Email': user.id,
      'Account': user.metadata.account,
      'Name': [user.metadata.firstname, user.metadata.lastname].join(' '),
    })
    mixpanel.register({
      'X-debug': 'mixpanel.register',
      'App': 'Dashboard',
      'Email': user.id,
      'Account': user.metadata.account,
      'Name': [user.metadata.firstname, user.metadata.lastname].join(' '),
    })
  } catch (error) {
    console.log(error)
    yield put(showError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* signOut() {
  try {
    yield signOutUser()
    yield put(setIsLoading(true))
    yield put(setAuthUser(false))
    yield put(setUser(false))
    yield put(unsetAccount(false))
    yield put(cacheClear())
  } catch (error) {
    yield put(showError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* signIn(action) {
  const {
    payload: {
      email, password
    }
  } = action
  try {
    yield put(setComponentIsLoading(true))
    const userAuth = yield signInUser(email, password)
    yield put(setAuthUser(userAuth))
    const user = yield userGet(userAuth.email)
    yield put(setUser(user))
    const account = yield accountGetById(user.metadata.account)
    yield put(setAccount(account))
  } catch (error) {
    switch (error.code) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        yield put(showError('Wrong email or password'))
        break

      default:
        yield put(showError('Too many requests. Please try again later.'))
        break
    }
  } finally {
    yield put(setComponentIsLoading(false))
  }
}

export default function* mainForm() {
  yield takeLatest(constants.CHECK_USER_REQUEST, checkUser)
  yield takeLatest(constants.SIGN_OUT_REQUEST, signOut)
  yield takeLatest(constants.SIGN_IN_REQUEST, signIn)
}
