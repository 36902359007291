import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import {
  DropdownItem
} from 'reactstrap'
import { useNavigate } from 'react-router'
import { cacheGetItemData, cacheSetItem } from '../../../api/cache'
import { accountsGetByUser } from '../../../api/accounts'

const HeaderMenuAccounts = memo(() => {
  const navigate = useNavigate()
  const userMasterAccountsCacheKey = 'user-master-accounts'
  const user = useSelector((state) => state.userReducers.user)
  const userAccounts = user?.metadata?.accounts || []
  const [accounts, setAccounts] = useState(cacheGetItemData(userMasterAccountsCacheKey) || [])

  useEffect(() => {
    if (user?.metadata?.accounts?.length > 0) {
      accountsGetByUser(user.metadata.accounts).then(async data => {
        const sortedData = _.orderBy(_.orderBy(data, a => a.metadata.createdAt.toDate(), 'desc'), a => a.id === 'admin', 'desc')
        cacheSetItem(userMasterAccountsCacheKey, { data: sortedData, expires: Date.now() + 1000 * 60 * 60 })
        await setAccounts(sortedData)
      })
    }

    return () => {}
  }, [userAccounts])

  return (
    <>
      {accounts.length > 1 && accounts.map((account) => (
        <div key={account.id}>
          {account.id !== user?.metadata?.account &&
            <DropdownItem onClick={() => {
              navigate(`/account/switch/${account.id}`)
            }}>
              {account.metadata.customerName}
            </DropdownItem>
          }
          {account.id === user?.metadata?.account &&
            <DropdownItem>
              <strong>{account.metadata.customerName}</strong>
            </DropdownItem>
          }
        </div>
      ))}
    </>
  )
})

HeaderMenuAccounts.displayName = 'HeaderMenuAccounts'

export default HeaderMenuAccounts
