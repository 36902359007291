import journal from '../actions/journal'
import spendActiveTimeOutdoors from '../actions/spendActiveTimeOutdoors'
import dontSmoke from '../actions/dontSmoke'
import noPhoneInBed from '../actions/noPhoneInBed'
import reduceTv from '../actions/reduceTv'
import reduceAlcohol from '../actions/reduceAlcohol'
import sleep8Hours from '../actions/sleep8Hours'
import noScreensBeforeBed from '../actions/noScreensBeforeBed'
import noLateCoffee from '../actions/noLateCoffee'
import noAlcohol from '../actions/noAlcohol'
import noFoodBeforeBedtime from '../actions/noFoodBeforeBedtime'
import noNaps from '../actions/noNaps'
import fixedSleepSchedule from '../actions/fixedSleepSchedule'
import goForASlowWalk from '../actions/goForASlowWalk'
import lowerTheLights from '../actions/lowerTheLights'
import reduceNoise from '../actions/reduceNoise'

const category = {
  rank: 3,
  name: 'Improve Sleep',
  actions: [
    journal,
    spendActiveTimeOutdoors,
    dontSmoke,
    noPhoneInBed,
    reduceTv,
    reduceAlcohol,
    sleep8Hours,
    noScreensBeforeBed,
    noLateCoffee,
    noAlcohol,
    noFoodBeforeBedtime,
    noNaps,
    fixedSleepSchedule,
    goForASlowWalk,
    lowerTheLights,
    reduceNoise
  ]
}

export default category
