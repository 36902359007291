import React from 'react'
import { getMixpanel } from '../../../api/mixpanel'
import './account-disabled.scss'

const mixpanel = getMixpanel()

const TrialExpired = () => {
  const trackEmailClick = () => {
    mixpanel.track('Account disabled support email clicked')
  }

  return (
    <div className="trial-expired">
      <h1>This account is not active</h1>
      <div>
        <center onClick={trackEmailClick}>Contact us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> to re-activate.</center>
      </div>
    </div>
  )
}

export default TrialExpired
