import React from 'react'
import InputContainer from '../InputContainer'
import './index.scss'

const Input = ({
  type = 'text',
  name,
  error,
  placeholder = 'Enter text',
  label,
  onChange = () => {},
  onBlur = () => {},
  disabled = false,
  required = false,
  style = {}
}) => (
  <InputContainer type={type} error={error} label={label} name={name} required={required} style={style}>
    <input
      name={name}
      type={type}
      disabled={disabled}
      autoComplete='off'
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      className='input-container__input'
    />
  </InputContainer>
)

export default Input
