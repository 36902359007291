import React from 'react'
import { Table } from 'reactstrap'
import { EditText } from 'react-edit-text'

const TeamTable = ({
  accounts,
  accountValueChange
}) => {
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Account ID</th>
          <th>Account name (team name)</th>
          <th>Join Link</th>
        </tr>
      </thead>
      <tbody>
        {accounts?.map((account) => (
          <tr key={account.id}>
            <td>{account.id}</td>
            <td>
              <EditText
                name="name"
                placeholder="Account name (team name)"
                defaultValue={account.metadata.name}
                onSave={({ value }) => {
                  accountValueChange(account.id, 'name', value)
                }}
              ></EditText>
            </td>
            <td>
                {account.metadata.joinLink || 'n/a'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

  )
}

export default TeamTable
