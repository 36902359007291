import React from 'react'
import { useSelector } from 'react-redux'
import { Alert, Button } from 'reactstrap'
import { getMixpanel } from '../../../../api/mixpanel'
import { addDays, daysLeft } from '../../../../utils/date'
import './banner.scss'

const mixpanel = getMixpanel()

const BillingBanner = ({ triggerUpgrade }) => {
  const masterAccount = useSelector(state => state.accountReducers.account)

  const expiresOn = addDays(masterAccount.metadata.createdAt.toDate(), 14)
  const isExpired = expiresOn < new Date()
  const props = {
    color: isExpired ? 'danger' : 'warning'
  }
  const leftDays = daysLeft(expiresOn) + 1

  return (
    <Alert {...props} className="upgrade-banner">
      {!masterAccount.metadata.billing && (
        <>
          <span className="trial-message">14-day free trial &nbsp;<strong>{leftDays} day{leftDays !== 1 ? 's' : ''} left</strong></span>
          <Button className="upgrade-button" onClick={() => {
            mixpanel.track('Upgrade process started by user J00')
            triggerUpgrade()
          } }>Contact us!</Button>
        </>
      )}
    </Alert>
  )
}

export default BillingBanner
