import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth, functions, httpsCallable } from './firebase'
import { getOne, subscribeAll, subscribe, subscribeOne } from './db'

const collectionName = 'users'

const createAuthRecord = async (email, password) => new Promise((resolve) => {
  createUserWithEmailAndPassword(
    auth,
    email,
    password
  ).then(resolve)
})

const userGet = async (email) => await getOne(collectionName, email)

const userSubscribe = (email, mutationCallback) => subscribeOne(collectionName, email, mutationCallback)
const usersSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)
const usersSubscribeCustomers = (mutationCallback) => subscribe(collectionName, [['customer', '==', true]], mutationCallback)

const userTeammemberAdd = async (data) => {
  const call = httpsCallable(functions, 'userTeammemberAdd')
  return await call(data)
}

const userTeammemberRemove = async (data) => {
  const call = httpsCallable(functions, 'userTeammemberRemove')
  return await call(data)
}

const userUpdateField = async (data) => {
  const call = httpsCallable(functions, 'userUpdateField')
  return await call(data)
}

export {
  createAuthRecord,
  userGet,
  userSubscribe,
  userTeammemberAdd,
  userTeammemberRemove,
  userUpdateField,
  usersSubscribeAll,
  usersSubscribeCustomers
}
