import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { Badge, Container, Row, Table } from 'reactstrap'
import { queueSubscribe } from '../../../api/queue'
import './index.scss'

const MembersComponent = () => {
  const navigate = useNavigate()
  const [queue, setQueue] = useState([])

  useEffect(() => {
    let unsubscribe = () => {}
    const run = async () => {
      unsubscribe = await queueSubscribe((result) => {
        setQueue(_.orderBy(result, ['metadata.executeAt'], ['asc']))
      })
    }

    run()

    return unsubscribe
  }, [])

  return (<Container className="items">
    <Row><h1>Queue</h1></Row>
    <Row>
      <Table>
        <tbody>
          {queue.map((item) => (
            <tr key={item.id}>
              <th onClick={() => navigate(`/admin/queue/${item.id}`)} className="item-link">
                {item.metadata.eventData.appId}
              </th>
              <td>
                {item.metadata.controller}
              </td>
              <td>
                {item.metadata?.executeAt ? item.metadata.executeAt.toDate().toISOString() : '-'} | {item.metadata?.executeAt ? moment(item.metadata?.executeAt.toDate()).fromNow() : '-'}
              </td>
              <td>
                {item.metadata?.platform && item.metadata?.platform !== 'unknown' &&
                  <Badge color={item.metadata?.platform === 'iOS' ? 'primary' : 'success' }>{item.metadata?.platform}</Badge>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  </Container>)
}

export default MembersComponent
