import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const initialState = {
  isLoading: false,
  isComponentLoading: false
}

export const root = createSlice({
  name: 'ROOT',
  initialState,
  reducers: {
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload
    }),
    setComponentIsLoading: (state, action) => ({
      ...state,
      isComponentLoading: action.payload
    }),
    showError: (state, action) => {
      toast.error(action?.payload)
    }
  }
})

// for redux (sync)
export const { actions } = root

export default root.reducer
