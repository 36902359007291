import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../api/firebase'

const getImageUrl = async (image) => {
  if (!image) {
    return null
  }

  try {
    const url = await getDownloadURL(ref(storage, image))
    return url
  } catch (e) {
    return false
  }
}

export {
  getImageUrl
}
