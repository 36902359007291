import reduceStressAnxiety from './categories/reduceStressAnxiety'
import developHealthyHabits from './categories/developHealthyHabits'
import improveSleep from './categories/improveSleep'
import liveHealthier from './categories/liveHealthier'
import breakBadHabits from './categories/breakBadHabits'
import improveFitness from './categories/improveFitness'

const groups = {
  reduceStressAnxiety,
  developHealthyHabits,
  improveSleep,
  liveHealthier,
  breakBadHabits,
  improveFitness
}

export default groups
