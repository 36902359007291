import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import AdminLayout from '../layouts/AdminLayout'
import Badges from '../modules/admin/badges'
import ContentList from '../modules/admin/content'
import ContentItem from '../modules/admin/content/item'
import ContentSearch from '../modules/admin/content/search'
import Customers from '../modules/admin/customers'
import Members from '../modules/admin/members'
import MemberItem from '../modules/admin/members/member'
import Profile from '../modules/admin/profile'
import Soundscapes from '../modules/admin/soundscapes'
import Sessions from '../modules/admin/sessions'
import Settings from '../modules/admin/settings'
import System from '../modules/admin/system'
import MasterAccount from '../modules/admin/customers/master-account'

import { UnregisteredUsersGuard } from './guards'
import Posts from '../modules/admin/posts'
import Queue from '../modules/admin/queue'
import QueueItem from '../modules/admin/queue/item'
import SystemStatus from '../modules/admin/system-status'

const AdminRoutes = memo(() => (
  <Routes>
    <Route element={<AdminLayout />}>
      <Route
        path="members"
        element={
          <UnregisteredUsersGuard>
            <Members />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="members/:memberId"
        element={
          <UnregisteredUsersGuard>
            <MemberItem />
          </UnregisteredUsersGuard>
        }
      />

      <Route
        path="content"
        element={
          <UnregisteredUsersGuard>
            <ContentList />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="content/search"
        element={
          <UnregisteredUsersGuard>
            <ContentSearch />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="content/:contentId"
        element={
          <UnregisteredUsersGuard>
            <ContentItem />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="customers"
        element={
          <UnregisteredUsersGuard>
            <Customers />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="customer/:accountId"
        element={
          <UnregisteredUsersGuard>
            <MasterAccount />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="settings"
        element={
          <UnregisteredUsersGuard>
            <Settings />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="badges"
        element={
          <UnregisteredUsersGuard>
            <Badges />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="profile"
        element={
          <UnregisteredUsersGuard>
            <Profile />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="soundscapes"
        element={
          <UnregisteredUsersGuard>
            <Soundscapes />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="sessions"
        element={
          <UnregisteredUsersGuard>
            <Sessions />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="system"
        element={
          <UnregisteredUsersGuard>
            <System />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="posts"
        element={
          <UnregisteredUsersGuard>
            <Posts />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="queue"
        element={
          <UnregisteredUsersGuard>
            <Queue />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="queue/:queueId"
        element={
          <UnregisteredUsersGuard>
            <QueueItem />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="system-status"
        element={
          <UnregisteredUsersGuard>
            <SystemStatus />
          </UnregisteredUsersGuard>
        }
      />
    </Route>
  </Routes>
))

AdminRoutes.displayName = 'AdminRoutes'

export default AdminRoutes
