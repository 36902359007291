import { functions, httpsCallable } from './firebase'

const systemDeepLinkCreate = async (data) => {
  const call = httpsCallable(functions, 'systemDeepLinkCreate')
  return await call(data)
}

export {
  systemDeepLinkCreate
}
