import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { getDownloadURL, ref } from 'firebase/storage'
import DropZone from '../../../drop-zone'
import Input from '../../../Input/index2'
import { storage } from '../../../../api/firebase'
import { getImageUrl } from '../../../../utils/get-image-url'

const Audio = ({
  errors,
  getValues,
  register,
  setLockSave,
  setValue,
  uploadPath
}) => {
  const [audioFile, setAudioFileUrl] = useState()
  const [imageUrl, setImageUrl] = useState(null)
  const [isMounted, setIsMounted] = useState(true)

  async function getUrl (file) {
    if (!file) {
      return null
    }

    return await getDownloadURL(ref(storage, file))
  }

  useEffect(() => {
    const file = getValues().file

    if (file) {
      getUrl(file).then(
        setAudioFileUrl
      )
    }
  }, [])

  useEffect(() => {
    if (getValues().imageHighRes) {
      getImageUrl(getValues().imageHighRes).then(image => {
        if (isMounted) {
          setImageUrl(image)
        }
      })

      return () => setIsMounted(false)
    }
  }, [getValues().imageHighRes])

  return <>
    <Row xs='4'>
      <input type='hidden' {...register('file')} />
      <DropZone
        name='file'
        label='Audio File'
        required={() => true}
        style={{ width: 'auto' }}
        errors={errors}
        path={uploadPath}
        uploadSuccess={file => setValue('file', file)}
        uploadStart={() => setLockSave(true)}
        uploadEnd={() => setLockSave(false)}
      />
      {audioFile &&
        <a href={audioFile} target="_blank" rel="noreferrer">Play</a>
      }
    </Row>

    <Row xs='4'>
      <Col>
        <Input
          name='eDuration'
          label='Duration'
          required={() => true}
          placeholder='0'
          register={register}
          errors={errors}
        />
      </Col>
    </Row>

    <Row className='d-flex gap-4 justify-content-between'>
      <Col>
        <input type='hidden' {...register('imageHighRes')} />
        <DropZone
          name='imageHighRes'
          label='High Res Image'
          required={() => true}
          style={{ width: 'auto' }}
          errors={errors}
          path={uploadPath}
          uploadSuccess={(image) => setValue('imageHighRes', image)}
          uploadStart={() => setLockSave(true)}
          uploadEnd={() => setLockSave(false)}
          setDominantColor={() => {}}
        />
      </Col>
      <Col>
        {imageUrl &&
          <img src={imageUrl} alt='overlay' className='group-item__img' />
        }
      </Col>
    </Row>

  </>
}

export default Audio
