import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import similarity from 'compute-cosine-similarity'
import { generateVector, systemBlogPostsGet, systemPostDelete } from '../../../api/posts'
import { Input, Row } from 'reactstrap'

const InternalPostsIndex = ({ defaultQuery, onChange, selectLink }) => {
  const [posts, setPosts] = useState([])
  const [vector, setVector] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [query, setQuery] = useState(defaultQuery || '')

  const searchPosts = () => {
    const similarityMatch = _.orderBy(posts.map((post) => {
      if (!post.metadata.title) {
        systemPostDelete(post.id)
      }

      return {
        id: post.id,
        similarity: similarity(post.metadata.vector, vector),
        url: post.metadata.url,
        title: post.metadata.title
      }
    }), ['similarity'], ['desc'])

    setSearchResults(_.take(similarityMatch, 10))
    onChange(_.take(similarityMatch.map(link => link.url), 10))
  }

  useEffect(() => {
    systemBlogPostsGet().then((response) => {
      setPosts(response)
    })
  }, [])

  useEffect(() => {
    generateVector(query).then((response) => {
      setVector(response.data.vector)
    })
  }, [query])

  useEffect(() => {
    if (vector && posts.length > 0) {
      searchPosts()
    }
  }, [vector, posts])

  return (<div className="my-5">
    <h3>Internal Links</h3>
    <Row>
      <Input type="text" value={query} onChange={(e) => setQuery(e.target.value)} />
    </Row>
    <ul>
      {searchResults.map((result) => {
        return <li
          className="selectable-link add-link"
          key={`selectable-link add-link ${result.id}`}
          title={`ID: ${result.id} Similarity score ${Math.floor(100 * result.similarity)}`}
          onClick={() => selectLink(result)}
        >
            <a href={result.url} target="_blank" rel="noreferrer">{result.title || result.url}</a>
          </li>
      })}
    </ul>
  </div>)
}

export default InternalPostsIndex
