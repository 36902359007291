import React, { useEffect, useState } from 'react'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../../../../api/firebase'

const Session = (props) => {
  const {
    session,
    sessionSelected
  } = props

  const [imageUrl, setImageUrl] = useState(null)

  async function getImageUrl (image) {
    if (!image) {
      return null
    }

    const url = await getDownloadURL(ref(storage, image))
    return `url(${url})`
  }

  useEffect(() => {
    getImageUrl(session.image).then(
      setImageUrl
    )
  }, [])

  return (
    <li className='group-item' data-id={session.id}>
      <div className='group-item__container'>

        {
          session.img
            ? <img src={session.img} alt='overlay' className='group-item__img' />
            : (
              <div
                onClick={() => sessionSelected(session)}
                className='group-item__img'
                style={{
                  backgroundColor: session.color,
                  backgroundImage: imageUrl
                }}
              />
              )
        }

      </div>
      <h3 className='group-item__title'>
        {session.title}
      </h3>
    </li>
  )
}

export default Session
