import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { backgroundColorPlugin } from './chart-utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const yMap = {
  0: '😢',
  1: '😔',
  2: '😒',
  3: '😊',
  4: '😃'
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    backgroundColorPlugin: {
      color: '#212324'
    }
  },
  scales: {
    x: {
      beginAtZero: true
    },
    y: {
      grid: {
        color: '#313537'
      },
      ticks: {
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return yMap[data]
        },
        font: {
          size: 22
        }
      }
    }
  }
}

const C = ({ data, title }) => {
  const { labels } = data
  const datasets = [{
    data: data.values,
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    borderColor: 'rgb(255, 99, 132)',
    cubicInterpolationMode: 'monotone',
    tension: 0.4
  }]

  return (
    <div className="showcase-chart">
      <h3>{ title }</h3>
      <div className="chart-area">
        <Line plugins={[backgroundColorPlugin]} data={{ labels, datasets }} options={options} />
      </div>
    </div>
  )
}

export default C
