import React from 'react'
import RadialProgressBar from './radial-progress-bar'
import './hero-measures.scss'
import { Col, Row } from 'reactstrap'
import ReadinessDistribution from './readiness-distribution'
import Loader from '../../../components/Loader'
import Adoption from './adoption'

const AccountHome = ({ accounts, latestAdoptionRate, readiness, workMentalReadinessChange }) => {
  let averageReadiness = 0
  for (let i = 0; i < readiness.length; i++) {
    averageReadiness += readiness[i].value
  }

  averageReadiness = Math.round(averageReadiness / readiness.length)

  return (
    <>
      <Row className="hero-measures">
        <Col className="item">
          <RadialProgressBar
            progress={averageReadiness}
            dataSymbol="%"
            title="Mental Readiness"
            color="#CEDCFF"
            styles={{
              scale: `${averageReadiness}%`

            }}
            ></RadialProgressBar>
        </Col>
        <Col className="readiness-distribution">
            <ReadinessDistribution accounts={accounts}></ReadinessDistribution>
        </Col>
        </Row>
        <Row className="work-mental-readiness-change">
          <Col className="item">
          {workMentalReadinessChange === false &&
            <Loader></Loader>
          }
          {workMentalReadinessChange !== false &&
            <>
              <h3>Work Influence</h3>
              <p>Influence of work on mental readiness.</p>
              <p>Normal range until -7%</p>
              <p className="value">{Math.round(workMentalReadinessChange)}%</p>
              <p><strong>&gt;0</strong> Work has positive influence on mental readiness</p>
              <p><strong>&lt;0</strong> Work has negative influence on mental readiness</p>
            </>
          }
        </Col>
        <Col>
          {latestAdoptionRate.allMembers > 0 &&
            <Adoption adoption={latestAdoptionRate}></Adoption>
          }
        </Col>
      </Row>
      <Row>
        <Col className="item">
          <br />
        </Col>
      </Row>
    </>

  )
}

export default AccountHome
