import React, { memo, useEffect, useState } from 'react'

import { accountSubscribe } from '../../../api/accounts'
import './index.scss'
import { useParams } from 'react-router-dom'
import TeamInvite from './team-invite'
import TeamUsers from './users'

const index = memo(() => {
  const { accountId } = useParams()
  const [account, setAccount] = useState(false)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    return accountSubscribe(accountId, data => {
      setLoading(false)
      setAccount(data)
    })
  }, [accountId])

  return (
    <div className='account-team'>
      {loading && <div>Loading...</div>}
      {!loading && account &&
        <>
          <h1>{account.metadata.name || 'Team unnamed'}</h1>
          <TeamInvite account={account}></TeamInvite>
          <br />
          <hr />
          <TeamUsers account={account}></TeamUsers>
        </>
      }
    </div>
  )
})

index.displayName = 'AccountTeam'

export default index
