import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Button, Input, Row } from 'reactstrap'
import { queueUpdate } from '../../../../../api/queue'
import { toast } from 'react-toastify'

export default function MemberQueueNew ({ appId }) {
  const [controller] = useState('memberMessageSend')
  const [newQueueItem, setNewQueueItem] = useState('')
  const [executeAt] = useState(false)

  const create = () => {
    const queueId = uuid()

    try {
      queueUpdate(
        queueId,
        {
          controller,
          createdAt: new Date(),
          executeAt: executeAt || new Date(),
          eventData: {
            appId,
            ...JSON.parse(newQueueItem)
          }
        }
      )
      toast.success('Queue item created')
    } catch (e) {
      toast.error('Error creating queue item')
    }
  }

  return (
    <>
      <Row>
        <Input type='textarea' placeholder='Enter a new queue item' onChange={(e) => setNewQueueItem(e.target.value)}/>
        { /*
        <Input type='text' placeholder='Controller, default:memberMessageSend' onChange={(e) => setController(e.target.value)}/>
        <Input type='datetime' placeholder="Execute at, default is now" onChange={(e) => setExecuteAt(new Date(e.target.value))} />
        */ }
        <Button onClick={() => create()}>Add</Button>
      </Row>
    </>
  )
}
