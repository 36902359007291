const images = [
  'Breathing expert 2.png',
  'Breathing expert.png',
  'February challenge 2.png',
  'February challenge.png',
  'New years badge 2.png',
  'New years badge.png',
  'Routine streak badge 2.png'
]

export default images
