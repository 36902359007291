import journal from '../actions/journal'
import morePositiveSelfTalk from '../actions/morePositiveSelfTalk'
import encourageSomeone from '../actions/encourageSomeone'
import learnANewLanguage from '../actions/learnANewLanguage'
import goToTherapy from '../actions/goToTherapy'
import trySomethingNew from '../actions/trySomethingNew'
import brushTeeth from '../actions/brushTeeth'
import write500Words from '../actions/write500Words'
import readABook from '../actions/readABook'
import makeAToDoList from '../actions/makeAToDoList'
import sayNo from '../actions/sayNo'

const category = {
  rank: 2,
  name: 'Develop healthy Habits',
  actions: [
    journal,
    morePositiveSelfTalk,
    encourageSomeone,
    learnANewLanguage,
    goToTherapy,
    trySomethingNew,
    brushTeeth,
    write500Words,
    readABook,
    makeAToDoList,
    sayNo
  ]
}

export default category
