import { httpsCallable, httpsCallableFromURL } from 'firebase/functions'
import { functions } from './firebase'
import { production } from '../config/index'
import { getFiltered, remove, update } from './db'

// const wordpressAddress = 'http://localhost:8080/wp-json/earkick/v1/'
const wordpressAddress = 'https://blog.earkick.com/wp-json/earkick/v1/'
const collectionName = 'system/blog/posts'

const postGenerate = async (inputs) => {
  // const call = httpsCallable(functions, 'blog_creator')
  const req = httpsCallableFromURL(
    functions,
    production ? 'https://us-central1-earkick---website.cloudfunctions.net/blog_creator' : 'https://us-central1-earkick-staging.cloudfunctions.net/blog_creator',
    {
      timeout: 600000
    }
  )

  return await req(inputs)
}

const postExport = async (inputs) => {
  const req = httpsCallableFromURL(
    functions,
    `${wordpressAddress}add`
  )

  return await req(inputs)
}

const postsImport = async (excludeIds) => {
  const req = httpsCallableFromURL(
    functions,
    `${wordpressAddress}get-many`
  )

  return await req({ excludeIds })
}

const postsSearch = async (keywords) => {
  const req = httpsCallableFromURL(
    functions,
    `${wordpressAddress}search`
  )

  return await req({ keywords })
}

const generateVector = (content) => {
  const call = httpsCallable(functions, 'mlGenerateVector')
  return call({ content })
}

const systemBlogPostUpdate = async (id, data) => {
  return update(collectionName, id, data)
}

const systemBlogPostsGet = async () => {
  return await getFiltered(
    collectionName,
    [['deleted', '==', false]],
    [['updatedAt', 'desc']],
    1000
  )
}

const systemPostDelete = async (id) => {
  return remove(collectionName, id)
}

export {
  generateVector,
  postGenerate,
  postExport,
  postsImport,
  postsSearch,
  systemPostDelete,
  systemBlogPostsGet,
  systemBlogPostUpdate
}
