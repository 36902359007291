import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mentalReadiness: false,
  latestAdoptionRate: {}
}

export const mentalReadiness = createSlice({
  name: 'mentalReadiness',
  initialState,
  reducers: {
    setMentalReadiness: (state, { payload }) => ({
      ...state,
      mentalReadiness: payload.mentalReadiness,
      latestAdoptionRate: payload.latestAdoptionRate
    })
  }
})

export const { actions } = mentalReadiness

export default mentalReadiness.reducer
