import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { ModalBody, ModalHeader, Progress } from 'reactstrap'
import Modal from '../../../../../components/Modal/index'
import ModalClose from '../../../../../components/Modal/close'
import SessionTemplateGroup from '../components/session-group'
import { sessionGroupsSubscribeAll } from '../../../../../api/accounts/sessions'
import { sessionGroupsGetAll as globalSessionsGetAll } from '../../../../../api/sessions'
import { getItemKey } from '../../../../../api/db'
import ConfigureTemplate from './configure-template'

const Title = memo(({ openCreateNewSessionModal }) => (
    <>
      Pick a session or <a href="#" style={{ color: '#C07FC5' }} onClick={() => openCreateNewSessionModal()}>create new</a>
    </>
))

Title.displayName = 'SessionTemplateTiele'

const UserEdit = memo(({
  accountId,
  userId,
  isOpen,
  toggle,
  onSubmit,
  createSession,
  openCreateNewSessionModal
}) => {
  const masterAccount = useSelector(state => state.accountReducers.account)
  const [groups, setGroups] = useState()
  const [loading, setLoading] = useState(true)
  const [selectedSession, setSelectedSession] = useState(false)

  useEffect(() => {
    const unsubscribe = sessionGroupsSubscribeAll(masterAccount.id, async (sessions) => {
      const sessionsOrdered = _.sortBy(sessions, session => session.metadata.rank)
      const globalSessions = await globalSessionsGetAll()
      setGroups([...sessionsOrdered, ...globalSessions])
      setLoading(false)
    })

    return unsubscribe
  }, [])

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
    >
      {!selectedSession &&
        <>
          <ModalHeader close={<ModalClose toggle={toggle} />}>
            <Title openCreateNewSessionModal={openCreateNewSessionModal} />
          </ModalHeader>
          <ModalBody>
            {!loading &&
              <>
                <div>
                  {groups?.map((group, index) => (
                    <div key={getItemKey(group)} style={{ marginBottom: '30px' }}>
                      <SessionTemplateGroup
                        group={group}
                        items={group.metadata.sessions}
                        sessionSelected={setSelectedSession}
                      />
                    </div>
                  ))}
                </div>
              </>
            }
            {loading &&
              <Progress
                animated
                bar
                value="100"
              >
                Loading
              </Progress>
            }
          </ModalBody>
        </>
      }
      {selectedSession &&
        <ConfigureTemplate
          accountId={accountId}
          userId={userId}
          session={selectedSession}
          back={() => setSelectedSession(false)}
          toggle={toggle}
          createSession={createSession}
        ></ConfigureTemplate>
      }

    </Modal>
  )
})

UserEdit.displayName = 'UserEditModal'

export default UserEdit
