import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Badge, Button, Col, Container, Input, Row, Table } from 'reactstrap'
import { contentSearch } from '../../../api/content'
import Loader from '../../../components/Loader'

const C = () => {
  const navigate = useNavigate()
  const [contents, setContents] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')

  const search = async () => {
    setLoading(true)
    const data = await contentSearch(query)
    setLoading(false)
    setContents(data.contents)
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3 className='page-header__title'>Content Search</h3>
        </Col>
      </Row>
      <Row className="search-form">
        <Col>
          <Input placeholder="your search" type="textarea" onChange={e => setQuery(e.target.value)}></Input>
          <Button onClick={() => search()}>Search</Button>
        </Col>
      </Row>

      {!loading && contents.length > 0 &&
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Similarity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contents.map(({ content, similarity }) => (
              <tr key={content.id} onClick={() => navigate(`/admin/content/${content.id}`)}>
                <td onClick={() => navigate(`/admin/content/${content.id}`)}>{content.metadata.name}</td>
                <td>{similarity}</td>
                <td>
                {!content.metadata.vector &&
                    <Badge
                      color="danger"
                      pill
                    >
                      No vector
                    </Badge>
                  }
                  {content.metadata.published &&
                    <Badge
                      color="success"
                      pill
                    >
                      Published
                    </Badge>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      }
      {loading &&
        <Loader></Loader>
      }
    </Container>
  )
}

export default C
