/**
 * @param {Date object} date Date to add days to
 * @param {integer} days Number of days to be added
 * @return Date object
 */
const addDays = (time, days) => {
  const date = new Date(time)
  return new Date(date.setDate(date.getDate() + days))
}

/**
 * @param {any} Date that we need to get end of the day
 * @return Date object
 */
const endOfDay = (day) => {
  return new Date((addDays(day, 1)).getTime() - 1000)
}

/**
 *
 * @param {any} date It would be wrapped in new Date()
 * @param {number} hours Number of hours (integer of loat) to be added to date
 * @returns Date Object
 */
const addHours = (date, hours) => {
  return new Date((new Date(date)).getTime() + (hours * 60 * 60 * 1000))
}

const daysLeft = (date) => {
  return Math.floor((date - new Date()) / (1000 * 60 * 60 * 24))
}

/**
 * @returns Date object
 */
const yesterday = () => {
  return addDays(new Date(), -1)
}

const toIsoDate = date => {
  return (new Date(date)).toISOString().substring(0, 10)
}

/**
 * @param {any} time It would be wrapped in new Date()
 * @return Unix Timestamp (Not Javascript Timestamp)
 */
const toTimestamp = (time) => {
  return Math.round((new Date(time)).getTime() / 1000)
}

module.exports = {
  addDays,
  addHours,
  daysLeft,
  endOfDay,
  toIsoDate,
  toTimestamp,
  yesterday
}
