import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { create, remove, subscribeAll, update } from '../db'

const accountsCollectionName = 'accounts'
const collectionName = 'actions'

const actionCreate = (accountId, group, data) => {
  const actionId = uuid()
  const { actions } = group.metadata

  actions.push({
    ...data,
    id: actionId
  })

  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    actions
  })
}

const actionGroupCreate = async (accountId, data) => {
  await create(`${accountsCollectionName}/${accountId}/${collectionName}`, uuid(), data)
}

const actionGroupDelete = async (accountId, groupId) => {
  await remove(`${accountsCollectionName}/${accountId}/${collectionName}`, groupId)
}

const actionGroupUpdate = async (accountId, groupId, data) => {
  await update(`${accountsCollectionName}/${accountId}/${collectionName}`, groupId, data)
}

const actionUpdate = (accountId, group, actionId, data) => {
  const { actions } = group.metadata

  const index = _.findIndex(actions, { id: actionId })
  actions[index] = data

  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    actions
  })
}

const actionsUpdate = (accountId, group, actions) => {
  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    actions
  })
}

const actionGroupsSubscribeAll = (accountId, mutationCallback) => subscribeAll(`${accountsCollectionName}/${accountId}/${collectionName}`, mutationCallback)

export {
  actionCreate,
  actionGroupCreate,
  actionGroupDelete,
  actionGroupUpdate,
  actionUpdate,
  actionsUpdate,
  actionGroupsSubscribeAll
}
