import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Col, Container, Row } from 'reactstrap'
import './index.scss'
import HightlightKeywords from './highlight-keywords'

const apiKey = '9d7VRT518WYXErkOy5Wsxt7GUvhSAnjPSbCIq5aka0uNyOCWK2K9wy4J'

const fetchImages = async (query) => {
  const cacheKey = `pexels-fetch-${query}`
  if (window.localStorage.getItem(cacheKey)) {
    return JSON.parse(window.localStorage.getItem(cacheKey))
  } else {
    return new Promise((resolve, reject) => {
      fetch(
        `https://api.pexels.com/v1/search?orientation=landscape&size=small&per_page=15&page=1&query=${query}`,
        {
          headers: {
            Authorization: apiKey
          }
        }
      ).then((response) => response.json())
        .then((data) => {
          window.localStorage.setItem(cacheKey, JSON.stringify(data.photos))
          resolve(data.photos)
        })
    })
  }
}

const PexelsImages = ({ queries, onChange, keywords }) => {
  const [images, setImages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])

  const toggleImage = (image) => {
    const index = _.findIndex(selectedImages, object => { return object.src === image.src })
    if (index === -1) {
      setSelectedImages([...selectedImages, image])
    } else {
      setSelectedImages(_.filter(selectedImages, (selectedImage, i) => i !== index))
    }
  }

  const isSelected = (image) => {
    return _.findIndex(selectedImages, object => { return object.src === image.src }) !== -1
  }

  useEffect(() => {
    onChange(selectedImages)
  }, [selectedImages])

  useEffect(() => {
    (async () => {
      const tasks = []
      _.each(queries, query => {
        if (query.length > 0) {
          tasks.push(fetchImages(query))
        }
      })

      const results = await Promise.all(tasks)
      const images = _.flatten(results)
      setImages(images)
    })()
  }, [queries])

  return (<div className="my-5">
    <h2>Pexels Images</h2>
    <p>Click to select images to be imported to Wordpress</p>
    <Container>
      <Row className="pexels-images">
        {images.map((image, index) => (image &&
          <Col key={index} className={`my-4 image ${isSelected({ src: image.src.large, alt: image.alt }) ? 'selected' : ''}`} onClick={e => toggleImage({ src: image.src.large, alt: image.alt })}>
            <img src={image.src.large} alt={image.alt} width="400" />
            <p><HightlightKeywords text={image.alt} keywords={keywords.split(' ')} /></p>
          </Col>
        ))}
      </Row>
    </Container>
  </div>)
}

export default PexelsImages
