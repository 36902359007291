import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import { UserGuard } from './routes/guards'
import Routing from './routes'
import store from './core/store'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/base/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ToastContainer autoClose={2000} />
    <UserGuard>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </UserGuard>
  </Provider>
)
