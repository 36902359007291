import React, { useEffect, useState } from 'react'
import { getAccountActivity } from '../../../api/accounts/team-activity'
import { membersGetCombined } from '../../../api/accounts/members'
import { cacheGetItemData, cacheSetItem } from '../../../api/cache'
import { useSelector } from 'react-redux'

const TeamReadiness = ({ account }) => {
  const accountId = account.id
  const [mentalReadiness, setMentalReadiness] = useState(cacheGetItemData(`mentalReadiness-${accountId}`) || [])
  const [latestAdoptionRate, setLatestAdoptionRate] = useState(cacheGetItemData(`latestAdoptionRate-${accountId}`) || {})
  const [averageReadiness, setAverageReadiness] = useState(0)
  const masterAccount = useSelector(state => state.accountReducers.account)

  const [loading, setLoading] = useState(mentalReadiness.length < 1)

  useEffect(() => {
    let readiness = 0
    for (let i = 0; i < mentalReadiness.length; i++) {
      readiness += mentalReadiness[i].value
    }

    readiness = Math.round(readiness / mentalReadiness.length)
    setAverageReadiness(readiness)
  }, [mentalReadiness])

  useEffect(() => {
    const run = async () => {
      try {
        const accountUsers = await membersGetCombined([accountId])
        console.log({ accountUsers, length: accountUsers.length })
        if (accountUsers.length < 5) {
          throw new Error('Not enough data')
        }

        const { mentalReadiness: mr, latestAdoptionRate: lar } = await getAccountActivity([accountId], masterAccount)
        console.log({ lar, mr })
        cacheSetItem(`mentalReadiness-${accountId}`, { data: mr })
        cacheSetItem(`latestAdoptionRate-${accountId}`, { data: lar })
        setMentalReadiness(mr)
        setLatestAdoptionRate(lar)
        setLoading(false)
      } catch (e) {
        console.log(e)
        cacheSetItem(`mentalReadiness-${accountId}`, { data: [] })
        cacheSetItem(`latestAdoptionRate-${accountId}`, { data: {} })
        setMentalReadiness([])
        setLatestAdoptionRate({})
        setLoading(false)
      }
    }

    if (accountId && loading) {
      run()
    }
  }, [accountId, loading])

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && (!latestAdoptionRate.activeMembers || latestAdoptionRate.activeMembers < 5) && (
        <>Not enough data</>
      )}
       {!loading && latestAdoptionRate.activeMembers >= 5 && (
          <>
            { averageReadiness }% <div className="readiness-bar"></div>
          </>
       )}
    </>
  )
}

export default TeamReadiness
