import journal from '../actions/journal'
import breathe from '../actions/breathe'
import laugh from '../actions/laugh'
import onlyOneCoffee from '../actions/onlyOneCoffee'
import gratefulThought from '../actions/gratefulThought'
import mediate from '../actions/mediate'
import listenToMusic from '../actions/listenToMusic'
import petADog from '../actions/petADog'
import spendActiveTimeOutdoors from '../actions/spendActiveTimeOutdoors'
import takeAWarmBath from '../actions/takeAWarmBath'
import drinkWater from '../actions/drinkWater'
import goForABikeRide from '../actions/goForABikeRide'
import excercise from '../actions/excercise'
import goForAWalk from '../actions/goForAWalk'
import pray from '../actions/pray'
import timeAlone from '../actions/timeAlone'
import shower from '../actions/shower'

const category = {
  rank: 1,
  name: 'Reduce stress & anxiety',
  actions: [
    journal,
    breathe,
    laugh,
    onlyOneCoffee,
    gratefulThought,
    mediate,
    listenToMusic,
    petADog,
    spendActiveTimeOutdoors,
    takeAWarmBath,
    drinkWater,
    goForABikeRide,
    excercise,
    goForAWalk,
    pray,
    timeAlone,
    shower
  ]
}

export default category
