import React, { useState } from 'react'
import _ from 'lodash'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import './accounts-dropdown.scss'

const AccountsDropdown = ({ accounts, selectedAccount, onSelectAccount }) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(selectedAccount || 'all')

  const handleSelect = (account) => {
    setSelected(_.find(accounts, { id: account }) || 'all')
    onSelectAccount(account)
    setOpen(false)
  }

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <Dropdown isOpen={open} toggle={toggle} className="accounts-dropdown">
      <DropdownToggle caret>{selected?.metadata?.name || 'All Teams'}</DropdownToggle>
      <DropdownMenu>

      <DropdownItem onClick={() => handleSelect('all')}>-- All Teams --</DropdownItem>
        {accounts.map(account => (
          <DropdownItem key={account.id} onClick={() => handleSelect(account.id)}>{account.metadata.name}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default AccountsDropdown
