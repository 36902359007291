import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../../../components/button'
import { Button as RButton, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { addDays } from '../../../../utils/date'
import { useNavigate } from 'react-router'
import { memberDelete } from '../../../../api/members'

const MemberDeleteCompoent = ({ member }) => {
  const [loading, setLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [deleteInitialButtonDisabled, setDeleteInitialButtonDisabled] = useState(false)
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true)
  const [confirmationText, setConfirmationText] = useState('')
  const [tooltipNote, setTooltipNote] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (confirmationText === 'DELETE') {
      setDeleteButtonDisabled(false)
    } else {
      setDeleteButtonDisabled(true)
    }
  }, [confirmationText])

  const performDeletion = async () => {
    console.log('Deleting member')
    setLoading(true)
    const { data } = await memberDelete(member.id)
    setLoading(false)
    console.log(data)
    if (data.success) {
      toast.info('Member deleted successfully')
      navigate('/admin/members')
    } else if (data.error) {
      toast.error(`Member not deleted: ${data.errorMessage}`)
    } else {
      toast.error('Member not deleted, cause unknown')
    }
  }

  useEffect(() => {
    console.log(!!member.metadata.lastActivity)
    console.log(addDays(member.metadata?.lastActivity?.toDate(), 1) < new Date())

    if (!!member.metadata.lastActivity && addDays(member.metadata?.lastActivity?.toDate(), 1) > new Date()) {
      setTooltipNote('Deletion note: Member should be inactive for at least one day.')
      setDeleteInitialButtonDisabled(true)
    }
  }, [member])

  return (
    <Row>
      {deleteInitialButtonDisabled &&
        <p>{tooltipNote}</p>
      }
      <RButton disabled={deleteInitialButtonDisabled} onClick={() => setShowDialog(true)} title={tooltipNote}>Delete Member</RButton>
      {showDialog &&
        <Modal isOpen={showDialog} toggle={() => setShowDialog(false)}>
          <ModalHeader >Confirm member deletion - it can not be undone</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <p>Type <strong>DELETE</strong> to delete:</p>
                <Input type="textarea" onChange={(e) => setConfirmationText(e.target.value)} />
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button btnStyle="secondary" onClick={() => setShowDialog(false)} text="Cancel">Cancel</Button>{' '}
            <Button loading={loading} disabled={deleteButtonDisabled} onClick={() => performDeletion()} text="Delete"></Button>
          </ModalFooter>
        </Modal>
      }
    </Row>
  )
}

export default MemberDeleteCompoent
