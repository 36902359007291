import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import InputContainer from '../InputContainer'
import './select.scss'

const Select = ({
  register,
  options,
  name,
  errors,
  label,
  disabled = false,
  required = false,
  style = {},
  value = false,
  onChange = () => {}
}) => (
  <InputContainer label={label} name={name} required={required} style={style}>

    <select
      {...register(name, {
        required: required ? `${label} is required.` : false
      })}
      disabled={disabled}
      className='input-container__input'
      onChange={onChange}
    >
      {options.map((option) => (
        <option
          value={option.value}
          key={option.name}
          defaultValue={option.value === value}>{option.name}
        </option>
      ))}
    </select>
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className='input-container__error'>{message}</p>}
    />
  </InputContainer>
)

export default Select
