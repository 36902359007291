import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { create, remove, subscribeAll, update } from '../db'

const accountsCollectionName = 'accounts'
const collectionName = 'audios'

const audioCreate = (accountId, group, data) => {
  const audioId = uuid()
  const { audios } = group.metadata

  audios.push({
    ...data,
    id: audioId
  })

  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    audios
  })
}

const audioGroupCreate = async (accountId, data) => {
  await create(`${accountsCollectionName}/${accountId}/${collectionName}`, uuid(), data)
}

const audioGroupDelete = async (accountId, groupId) => {
  await remove(`${accountsCollectionName}/${accountId}/${collectionName}`, groupId)
}

const audioGroupUpdate = async (accountId, groupId, data) => {
  await update(`${accountsCollectionName}/${accountId}/${collectionName}`, groupId, data)
}

const audioUpdate = (accountId, group, audioId, data) => {
  const { audios } = group.metadata

  const index = _.findIndex(audios, { id: audioId })
  audios[index] = data

  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    audios
  })
}

const audiosUpdate = (accountId, group, audios) => {
  update(`${accountsCollectionName}/${accountId}/${collectionName}`, group.id, {
    audios
  })
}

const audioGroupsSubscribeAll = (accountId, mutationCallback) => subscribeAll(`${accountsCollectionName}/${accountId}/${collectionName}`, mutationCallback)

export {
  audioCreate,
  audioGroupCreate,
  audioGroupDelete,
  audioGroupUpdate,
  audioUpdate,
  audiosUpdate,
  audioGroupsSubscribeAll
}
