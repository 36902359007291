import React, { memo, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import logo from '../../../images/logo-panda-text.svg'
// import { ReactComponent as SettingsIcon } from '../../../images/settings.svg'
import { ReactComponent as HeadphonesIcon } from '../../../images/headphones.svg'
import { ReactComponent as HandThumbsup } from '../../../images/hand.thumbsup.svg'
import { ReactComponent as crown } from '../../../images/crown.svg'
import { ReactComponent as members, ReactComponent as PeoplesIcon } from '../../../images/peoples.svg'
import { ReactComponent as rosette } from '../../../images/rosette.svg'
import { ReactComponent as ActionsIcon } from '../../../images/actions.svg'
import { ReactComponent as HouseIcon } from '../../../images/house.svg'
import { ReactComponent as foldersTree } from '../../../images/folder-tree-solid.svg'

const PostsIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/></svg>)
const AlertsIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>)
const QueueIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path d="M448 64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32zm0 256c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 192c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM448 448c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32z"/></svg>)
const SoundsIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/></svg>)

const Sidebar = memo(() => {
  const [menu, setMenu] = useState([])
  const { pathname } = useLocation()
  const account = useSelector((state) => state.accountReducers.account)
  const user = useSelector((state) => state.userReducers.user)

  const isTherapist = !!account?.metadata?.therapistShowcase

  useEffect(() => {
    const audios = account?.metadata?.audios || false
    const sessions = account?.metadata?.sessions || false
    const routines = account?.metadata?.routines || false
    // const badges = account?.metadata?.badges || false
    const badges = account.metadata?.badges || false
    const team = account?.metadata?.team || false
    const customer = !account?.metadata?.admin || false
    const admin = account?.metadata?.admin || false

    const updateMenu = []
    if (customer) {
      if (!isTherapist) {
        updateMenu.push({
          path: '/account',
          Icon: HouseIcon,
          title: 'Overview'
        })

        updateMenu.push({
          path: '/account/mental-readiness',
          Icon: HandThumbsup,
          title: 'Mental Readiness'
        })
      } else {
        updateMenu.push({
          path: '/account/teams',
          Icon: PeoplesIcon,
          title: 'Groups'
        })
      }

      if (sessions) {
        updateMenu.push({
          path: '/account/sessions',
          Icon: HeadphonesIcon,
          title: 'Sessions'
        })
      }

      if (audios) {
        updateMenu.push({
          path: '/account/audios',
          Icon: SoundsIcon,
          title: 'Sessions'
        })
      }

      if (routines) {
        updateMenu.push({
          path: '/account/routines',
          Icon: ActionsIcon,
          title: 'Routines'
        })
      }

      if (!isTherapist && team) {
        updateMenu.push({
          path: '/account/teams',
          Icon: PeoplesIcon,
          title: 'Teams'
        })
      }

      if (badges) {
        updateMenu.push({
          path: '/account/badges',
          Icon: rosette,
          title: 'Badges'
        })
      }
    }

    if (admin) {
      updateMenu.push({
        path: '/admin/customers',
        Icon: crown,
        title: 'Customers'
      })

      updateMenu.push({
        path: '/admin/members',
        Icon: members,
        title: 'Members'
      })

      updateMenu.push({
        path: '/admin/soundscapes',
        Icon: SoundsIcon,
        title: 'Soundscapes'
      })

      updateMenu.push({
        path: '/admin/sessions',
        Icon: HeadphonesIcon,
        title: 'Sessions'
      })

      updateMenu.push({
        path: '/admin/badges',
        Icon: rosette,
        title: 'Badges'
      })

      updateMenu.push({
        path: '/admin/content',
        Icon: foldersTree,
        title: 'Content'
      })

      updateMenu.push({
        path: '/admin/posts',
        Icon: PostsIcon,
        title: 'Posts'
      })

      updateMenu.push({
        path: '/admin/queue',
        Icon: QueueIcon,
        title: 'Queue'
      })

      updateMenu.push({
        path: '/admin/system-status',
        Icon: AlertsIcon,
        title: 'System Status'
      })

      /*
      updateMenu.push({
        path: '/admin/system',
        Icon: rosette,
        title: 'System'
      })
      */
    }

    setMenu(updateMenu)
  }, [user, account])

  return (
    <div className='sidebar'>
      <div className='sidebar__container'>
        <div className='logo'>
          <img src={logo} alt='logo' className='logo__img' />
        </div>
        <ul className='sidebar__menu'>
          {
            menu?.map(({ Icon, path, title }) => (
              <li className={`sidebar__menu-item ${path === pathname ? 'sidebar__menu-item_active' : ''}`} key={uuid()}>
                <Link to={path} className='sidebar__menu-item-link'>
                  <Icon />
                  {title}
                </Link>
              </li>
            ))
          }
        </ul>
      </div>

      { /*
      <div className='sidebar__bottom'>
        <div className={`sidebar__bottom-item ${pathname === '/admin/settings' ? 'sidebar__bottom-item_active' : ''}`}>
          <Link to='/admin/settings' className='sidebar__bottom-item-link'>
            <SettingsIcon />
            Settings
          </Link>
        </div>
      </div>
      */ }
    </div>
  )
})

Sidebar.displayName = 'Sidebar'

export default Sidebar
