import React, { memo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import Title from '../../components/Title'
import Button from '../../components/button'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import './index.scss'
import { emailSchema } from '../../utils/schemas'
import { Link } from 'react-router-dom'
import { auth } from '../../api/firebase'
import { sendPasswordResetEmail } from 'firebase/auth'

const schema = Joi.object({
  email: emailSchema()
})

const LoginPage = memo(() => {
  const [isLoading, setIsLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const {
    handleSubmit, formState: { errors }, setValue
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: joiResolver(schema)
  })

  const onSubmit = async (values) => {
    const { email } = values
    setIsLoading(true)
    await sendPasswordResetEmail(auth, email)
    setIsLoading(false)
    setCompleted(true)
  }

  return (
    <div className='login'>
      <div className='login__container'>
        <Title value='Forgot password' />
        {!completed && (
          <form className='login__form' onSubmit={handleSubmit(onSubmit)}>
            <Input
              type='text'
              name='email'
              label='Email'
              placeholder='Enter your email'
              onChange={(e) => setValue('email', e.target.value)}
              error={errors?.email?.message}
              disabled={isLoading}
            />
            {
              isLoading
                ? <Loader />
                : (
                  <>
                    <Button
                      loading={isLoading}
                      text='Get password change link'
                      type='submit'
                    />
                  </>
                  )
            }
          </form>
        )}
        {completed && (
          <>Please check your email inbox & spam for reset link.</>
        )}

        <div className="login-signup-cross-link">
          <Link to="/login">Login</Link> / <Link to="/signup">Signup</Link>
        </div>
      </div>
    </div>
  )
})

LoginPage.displayName = LoginPage

export default LoginPage
