import React, { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../../components/Modal/index'
import Button from '../../../../components/button'
import Input from '../../../../components/Input/index2'
import Checkbox from '../../../../components/Input/checkbox'
import { btnStyles } from '../../../../utils/constants'
import '../../../../components/Input/index.scss'
import { accountSubscribe } from '../../../../api/accounts'
import { uniqueId } from 'lodash'
import Select from '../../../../components/Select'
import { userCreate } from '../../../../api/functions'
import { toast } from 'react-toastify'

const UserEdit = memo(({
  title,
  isOpen,
  toggle,
  onSubmit,
  user
}) => {
  const [lockSave, setLockSave] = useState(false)
  const [account, setAccount] = useState({})
  const accountId = user?.metadata?.account || uniqueId()

  const {
    getValues, handleSubmit, register, reset, formState: { errors }
  } = useForm({
  })

  useEffect(() => {
    if (user && account) {
      reset({
        firstname: user.metadata.firstname,
        lastname: user.metadata.lastname,
        sessions: account?.metadata?.sessions,
        routines: account?.metadata?.routines,
        badges: account?.metadata?.badges,
        members: account?.metadata?.members,
        membersDetails: account?.metadata?.membersDetails,
        team: account?.metadata?.team,
        email: user.id,
        name: account.metadata.name,
        customerName: account.metadata.customerName,
        billing: account.metadata.billing
      })
    }
  }, [user, account, reset])

  useEffect(() => {
    if (accountId) {
      const unsubscribe = () => {}

      const subscribe = async () => {
        return await accountSubscribe(accountId, data => {
          setAccount(data)
        })
      }

      subscribe().then(f => unsubscribe)

      return unsubscribe
    }
  }, [accountId])

  const onFormSubmit = async () => {
    setLockSave(true)
    const data = getValues()
    // onSubmit(values)

    const accountData = {
      customerName: data.customerName || false,
      name: data.name || false,
      sessions: data.sessions || false,
      members: data.members || false,
      memberDetails: data.memberDetails || false,
      routines: data.routines || false,
      team: data.team || false,
      subaccounts: [],
      billing: data.billing === 'false' ? false : data.billing || false
    }

    const { email, firstname, lastname, password } = data

    const { data: response } = await userCreate({
      accountData,
      email: email.toLowerCase(),
      firstname,
      lastname,
      password
    })
    console.log(response)
    if (!response.error) {
      toast.info(`Customer ${data.customerName} created`)
    } else if (response.e.code === 6) {
      toast.error(`Error creating customer ${data.customerName}, email ${data.email} already in use`)
    } else {
      toast.error(`Error creating customer ${data.customerName}`)
    }

    onSubmit()
  }

  return (
    <Modal
      title={user ? 'Edit customer' : 'Add a customer'}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className="session-form">
            <Input
              name="customerName"
              label="Customer Name"
              register={register}
              errors={errors}
              required={() => true}
            />

            <Input
              name="name"
              label="Team Name"
              register={register}
              errors={errors}
              required={() => true}
            />

            <strong>Billing</strong>

            <Select
              name='billing'
              required={() => true}
              options={[{
                value: false,
                name: 'Free'
              }, {
                value: 'evaluation',
                name: 'Evaluation'
              }, {
                value: 'premium',
                name: 'Premium'
              }]}
              register={register}
              errors={errors}
            />

            <strong>Extra features</strong>

            <Checkbox
              type="checkbox"
              name="sessions"
              label="Sessions"
              register={register}
              errors={errors}
              required={false}
            />

            <Checkbox
              type="checkbox"
              name="badges"
              label="Badges"
              register={register}
              errors={errors}
              required={false}
            />

            <Checkbox
              type="checkbox"
              name="team"
              label="Team (invite with link)"
              register={register}
              errors={errors}
              required={false}
            />

            <small>More extra features will be available after creating customer.</small>

            <hr />

            <strong>Initial owner user</strong>

            <Input
              name="firstname"
              label="First Name"
              register={register}
              errors={errors}
              required={() => true}
            />

            <Input
              name="lastname"
              label="Last Name"
              register={register}
              errors={errors}
              required={() => true}
            />

            <Input
              name="email"
              label="Email"
              register={register}
              errors={errors}
              required={() => true}
              disabled={!!user}
            />

            {!user &&
              <Input
                name="password"
                label="Password"
                register={register}
                errors={errors}
                required={() => true}
              />
            }
          </div>

        </ModalBody>
        <ModalFooter>
          <Button text="Cancel" btnStyle={btnStyles.simple} onClick={toggle} />
          <Button type="submit" loading={lockSave} text={user ? 'Save' : 'Add'} />
        </ModalFooter>
      </form>
    </Modal>
  )
})

UserEdit.displayName = 'CustomerEditModal'

export default UserEdit
