import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { queueSubscribeOne, queueDelete, queueUpdate } from '../../../../api/queue'
import Loader from '../../../../components/Loader'
import { Button, Col, Row, Table } from 'reactstrap'
import '../index.scss'
import moment from 'moment'

const QueueComponent = () => {
  const queueId = useParams().queueId
  const [queue, setQueue] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = queueSubscribeOne(queueId, (queueData) => {
      setQueue(queueData)

      setLoading(false)
    })

    return unsubscribe
  }, [queueId])

  return (<div>
      <Row><h1>Queue {queueId}</h1></Row>
      {loading && <Loader></Loader>}
      {!loading && queue.exists && (
        <>
          <Row>
            <Table>
              <tbody>
                <tr>
                  <th>Execute at</th>
                  <td>
                    {queue.metadata?.executeAt ? queue.metadata.executeAt.toDate().toISOString() : '-'} | {queue.metadata?.executeAt ? moment(queue.metadata?.executeAt.toDate()).fromNow() : '-'}
                  </td>
                </tr>
                <tr>
                  <th>Created</th>
                  <td>
                    {queue.metadata?.createdAt ? queue.metadata.createdAt.toDate().toISOString() : '-'} | {queue.metadata?.createdAt ? moment(queue.metadata?.createdAt.toDate()).fromNow() : '-'}
                  </td>
                </tr>
                <tr>
                  <th>Controller</th>
                  <td>{queue.metadata?.controller}</td>
                </tr>
                <tr>
                  <th>Event Data</th>
                  <td><pre>{JSON.stringify(queue.metadata.eventData, null, 2)}</pre></td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col><Button onClick={() => queueDelete(queueId)}>Delete</Button></Col>
            <Col><Button onClick={() => queueUpdate(queueId, { executeAt: new Date() })} color="primary">Run now</Button></Col>
          </Row>
        </>
      )}
      {!loading && !queue.exists && (
        <Row>Queue item deleted</Row>
      )}

    </div>)
}

export default QueueComponent
