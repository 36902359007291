import React, { memo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../../../components/Modal/index'
import Button from '../../../../../components/button'
import Input from '../../../../../components/Input/index2'
import { btnStyles } from '../../../../../utils/constants'
import '../../../../../components/Input/index.scss'

const UserEdit = memo(({
  title,
  isOpen,
  toggle,
  onSubmit,
  user,
  userDoesExist
}) => {
  const [lockSave, setLockSave] = useState(false)
  const {
    clearErrors,
    getValues,
    handleSubmit,
    register,
    setError,
    formState: { errors }
  } = useForm({
  })

  const onFormSubmit = async () => {
    setLockSave(true)
    const values = getValues()
    const { data } = await userDoesExist({ memberId: values.appId })

    if (!data.memberExists) {
      setError('appId', {
        type: 'focus',
        message: 'App ID does not exist'
      })
      setLockSave(false)
    } else {
      clearErrors('appId')
      onSubmit(values)
    }
  }

  return (
    <Modal
      title={user ? 'Edit member' : 'Add a member'}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className="session-form">
            { /*
            <Input
            name="firstname"
            label="First Name"
              register={register}
              errors={errors}
              required={false}
            />

            <Input
            name="lastname"
            label="Last Name"
            register={register}
            errors={errors}
            required={false}
            />
            */ }

            <Input
              name="appId"
              label="App Id"
              register={register}
              errors={errors}
              required={true}
            />
          </div>

        </ModalBody>
        <ModalFooter>
          <Button text="Cancel" btnStyle={btnStyles.simple} onClick={toggle} />
          <Button type="submit" loading={lockSave} text={user ? 'Save' : 'Add'} />
        </ModalFooter>
      </form>
    </Modal>
  )
})

UserEdit.displayName = 'UserEditModal'

export default UserEdit
