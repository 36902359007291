import React, { memo, useState } from 'react'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../../components/Modal/index'
import Button from '../../../../components/button'
import Input from '../../../../components/Input/index2'
import { btnStyles } from '../../../../utils/constants'
import '../../../../components/Input/index.scss'
import { userGet } from '../../../../api/users'

const UserEdit = memo(({
  accountId,
  isOpen,
  toggle,
  onSubmit
}) => {
  const [lockSave, setLockSave] = useState(false)
  const [user, setUser] = useState(null)

  const {
    getValues, handleSubmit, register, unregister, formState: { errors }
  } = useForm({
  })

  const onFormSubmit = async () => {
    setLockSave(true)
    const values = getValues()
    await onSubmit(values)
    setLockSave(false)
  }

  const checkUser = async (email) => {
    const resolvedUser = await userGet(email)
    if (resolvedUser.exist) {
      unregister('firstname')
      unregister('lastname')
      unregister('password')
      setUser(resolvedUser)
    }
  }

  return (
    <Modal
      title={'Add team member'}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className="session-form">
            {user?.exist && (
              <>
                {_.indexOf(user.metadata.accounts, accountId) > -1 && (
                  <p>Member Already</p>
                )}
                <p>{user.metadata.firstname} {user.metadata.lastname}
                  {_.indexOf(user.metadata.accounts, 'admin') > -1 && (
                    <span>&nbsp;(Admin)</span>
                  )}
                </p>
              </>
            )}
            <Input
              name="email"
              label="Email"
              register={register}
              errors={errors}
              required={() => true}
              disabled={!!user}
              onChange={(e) => checkUser(e.target.value)}
            />

            {!user?.exist && (
              <>
                <Input
                  name="firstname"
                  label="First Name"
                  register={register}
                  errors={errors}
                  required={() => !user.exist}
                />

                <Input
                  name="lastname"
                  label="Last Name"
                  register={register}
                  errors={errors}
                  required={() => !user.exist}
                />

                <Input
                  name="password"
                  label="Password"
                  register={register}
                  errors={errors}
                  required={() => !user.exist}
                />
              </>
            )}

          </div>

        </ModalBody>
        <ModalFooter>
          <Button text="Cancel" btnStyle={btnStyles.simple} onClick={toggle} />
          <Button type="submit" loading={lockSave} text={'Save'} />
        </ModalFooter>
      </form>
    </Modal>
  )
})

UserEdit.displayName = 'CustomerEditModal'

export default UserEdit
