import React from 'react'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { auth } from '../../api/firebase'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import actions from '../../core/actions'
import { getMixpanel } from '../../api/mixpanel'
const { setAuthUser } = actions

const mixpanel = getMixpanel()
const provider = new GoogleAuthProvider()
provider.setCustomParameters({
  login_hint: 'user@example.com'
})

const ContinueWithGoogle = ({ text }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const signIn = async () => {
    const response = await signInWithPopup(auth, provider)

    if (response.user) {
      console.log('user', response.user)
      mixpanel.track('User account sign in or signup A15', { method: 'google' })

      dispatch(setAuthUser(response.user))

      navigate('/account')
    }
  }

  return (
    <div className='login__google'>
      <button className="button" onClick={() => signIn()}><i className="fab fa-google"></i>{ text }</button>
    </div>
  )
}

export default ContinueWithGoogle
