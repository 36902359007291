import React, { memo } from 'react'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../../components/Modal/index'
import Button from '../../../../components/button'
import { btnStyles } from '../../../../utils/constants'

export default memo(function ModalUserDelete ({
  isOpen,
  toggle,
  onSubmit,
  customers
}) {
  const validCustomers = customers.filter(customer => customer.metadata.deleted)

  return (
    <Modal
      title={validCustomers.length > 0 ? 'Are you sure you want to un-delete following customers?' : 'No customers to undelete'}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalBody>
        <ul>
          {validCustomers.map((customer, index) =>
            <li key={customer.id}>{customer.metadata.customerName || 'Unnamed'}</li>
          )}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button text='Cancel' btnStyle={btnStyles.simple} onClick={toggle} />
        {validCustomers.length > 0 &&
          <Button type='submit' text='Delete' onClick={() => onSubmit()} />
        }
      </ModalFooter>
    </Modal>
  )
})
