import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Col, Container, Row } from 'reactstrap'
import './index.scss'

const PexelsImages = ({ images, onChange }) => {
  const [selectedImages, setSelectedImages] = useState([])

  const toggleImage = (image) => {
    const index = _.findIndex(selectedImages, object => { return object.src === image.src })
    if (index === -1) {
      setSelectedImages([...selectedImages, image])
    } else {
      setSelectedImages(_.filter(selectedImages, (selectedImage, i) => i !== index))
    }
  }

  const isSelected = (image) => {
    return _.findIndex(selectedImages, object => { return object.src === image.src }) !== -1
  }

  useEffect(() => {
    onChange(selectedImages)
  }, [selectedImages])

  return (<div className="my-5">
    <h2>Images</h2>
    <p>Click to select images to be imported to Wordpress</p>
    <Container>
      <Row className="pexels-images">
        {images.map((image, index) => (
          <Col key={index} className={`my-4 image ${isSelected({ src: image, alt: '' }) ? 'selected' : ''}`} onClick={e => toggleImage({ src: image, alt: '' })}>
            <img src={image} alt="" width="400" />
          </Col>
        ))}
      </Row>
    </Container>
  </div>)
}

export default PexelsImages
