import React from 'react'
import { Badge, Table } from 'reactstrap'

const PremiumBreakdownTable = ({ lastChange, teamPremium, premium }) => {
  const inAppPurchase = (lastChange?.operation === 'purchase' || lastChange?.operation === 'existing_purchase') && new Date(lastChange.expiryDate) > new Date()
  const manualPremium = lastChange?.operation === 'manual_purchase' && new Date(lastChange.expiryDate) > new Date()
  const referralPremium = premium && premium.active && premium.plan === 'referral'

  return (
    <>
      <p>Possible sources of premium</p>
      <Table>
        <tbody>
        <tr>
            <th>In app purchase</th>
            <td>{inAppPurchase ? <Badge color="success">yes</Badge> : 'no' }</td>
          </tr>
          <tr>
            <th>Manual premium</th>
            <td>{manualPremium ? <Badge color="success">yes</Badge> : 'no' }</td>
          </tr>
          <tr>
            <th>Team premium</th>
            <td>{teamPremium ? <Badge color="success">yes</Badge> : 'no' }</td>
          </tr>
          <tr>
            <th>Referral premium</th>
            <td>{referralPremium ? <Badge color="success">yes</Badge> : 'no' }</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default PremiumBreakdownTable
