import React, { useState } from 'react'
import { Table } from 'reactstrap'
import Button from '../../../../components/button'

const UsersTable = ({ users, userRemove }) => {
  const [deleteButtonLoading, setDeleteButtonLoading] = useState({})

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Created At</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users?.map((user) => (
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user?.metadata?.firstname} {user.metadata?.lastname}</td>
            <td>
                {user?.metadata?.createdAt?.toDate()?.toLocaleString()}
            </td>
            <td>
              {!user.exist &&
                <span className="text-danger">! User does not exist</span>
              }
              <Button onClick={() => {
                const newDeleteButtonLoading = { ...deleteButtonLoading }
                newDeleteButtonLoading[user.id] = true
                setDeleteButtonLoading(newDeleteButtonLoading)
                userRemove(user.id)
              }}
                loading={deleteButtonLoading[user.id]}
                color="danger" size="sm" className="ml-2"
                text={'Remove'}
              ></Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

  )
}

export default UsersTable
