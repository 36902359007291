import React from 'react'
import { btnStyles } from '../../utils/constants'
import Loader from '../Loader'
import './index.scss'

const Button = ({
  type = 'button',
  text = 'Button',
  onClick = () => {},
  disabled = false,
  btnStyle = btnStyles.default,
  style = {},
  loading = false
}) => (
  <button
    type={type}
    className={`button button_type_${btnStyle} ${disabled ? 'button_type_disabled' : ''}`}
    onClick={onClick}
    disabled={disabled || loading}
    style={style}
  >
    {!loading ? text : <Loader />}
  </button>
)

export default Button
