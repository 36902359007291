import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import HeroMeasures from './hero-measures'
import { getAccountActivity } from '../../../api/accounts/team-activity'
import './index.scss'
import { cacheGetItemData, cacheSetItem } from '../../../api/cache'
import { accountsGetByMasterAccount } from '../../../api/accounts'
import { membersGetCombined } from '../../../api/accounts/members'
import AccountsDropdown from '../components/accounts-dropdown'
import { signOutUser } from '../../../core/reducers/user'
import { getMixpanel } from '../../../api/mixpanel'
import AccountOnboarding from './onboarding'
import { userUpdateField } from '../../../api/users'
import Loader from '../../../components/Loader'

const mixpanel = getMixpanel()
const activeMembersLimit = 2

const AccountHome = () => {
  const dispatch = useDispatch()
  const userAccountsCacheKey = 'user-accounts'
  const [accounts, setAccounts] = useState(cacheGetItemData(userAccountsCacheKey) || [])
  const user = useSelector(state => state.userReducers.user)
  const [showOnboarding, setShowOnboarding] = useState(user.metadata.showOnboarding)
  const masterAccount = useSelector(state => state.accountReducers.account)
  const [accountId, setAccount] = useState('all' || false)
  const [mentalReadiness, setMentalReadiness] = useState(cacheGetItemData(`mentalReadiness-${accountId}`) || false)
  const [latestAdoptionRate, setLatestAdoptionRate] = useState(cacheGetItemData(`latestAdoptionRate-${accountId}`) || false)
  const [workMentalReadinessChange, setWorkMentalReadinessChange] = useState(cacheGetItemData(`workMentalReadinessChange-${accountId}`) || false)

  const [loading, setLoading] = useState(!mentalReadiness)

  useEffect(() => {
    setShowOnboarding(user.metadata.showOnboarding)
  }, [user.metadata.showOnboarding])

  useEffect(() => {
    const eventName = 'View Overview C00'
    mixpanel.track(eventName)
  }, [])

  useEffect(() => {
    const run = async () => {
      try {
        const inputAccounts = accountId !== 'all' ? [accountId] : [masterAccount.id, ...masterAccount.metadata.subaccounts]
        const accountUsers = await membersGetCombined(inputAccounts)

        if (accountUsers.length < activeMembersLimit) {
          throw new Error('Not enough data')
        }

        const { mentalReadiness: mr, latestAdoptionRate: lar, workMentalReadinessChange: wmrc } = await getAccountActivity(inputAccounts, masterAccount)
        cacheSetItem(`mentalReadiness-${accountId}`, { data: mr })
        cacheSetItem(`latestAdoptionRate-${accountId}`, { data: lar })
        cacheSetItem(`workMentalReadinessChange-${accountId}`, { data: wmrc })
        setMentalReadiness(mr)
        setLatestAdoptionRate(lar)
        setWorkMentalReadinessChange(wmrc)
        setLoading(false)
      } catch (e) {
        console.log(e)
        cacheSetItem(`mentalReadiness-${accountId}`, { data: [] })
        cacheSetItem(`latestAdoptionRate-${accountId}`, { data: {} })
        cacheSetItem(`workMentalReadinessChange-${accountId}`, { data: false })
        setMentalReadiness([])
        setLatestAdoptionRate({})
        setWorkMentalReadinessChange(false)
        setLoading(false)
      }
    }

    if (accountId && loading) {
      run()
    }
  }, [accountId, loading])

  useEffect(() => {
    if (!user?.metadata?.account) {
      console.log('User has no accounts', user)
      console.log('Running emergency logout')
      dispatch(signOutUser())
    }

    if (user.metadata.accounts.length > 0) {
      accountsGetByMasterAccount(masterAccount).then(async (data) => {
        const sortedData = _.orderBy(data, a => a.metadata.createdAt.toDate(), 'desc')
        cacheSetItem(userAccountsCacheKey, { data: sortedData })
        await setAccounts(sortedData)
      })
    }

    return () => {}
  }, [user.metadata.account])

  const accountSelected = (account) => {
    setAccount(account)
    setLoading(true)
  }

  return (
    <div className="overview">
      <h1 style={{ display: 'inline-block' }}>Overview
        {accounts.length > 0 && (
          <AccountsDropdown
          accounts={accounts}
          accountSelected={_.find(accounts, { id: accountId })}
          onSelectAccount={(account) => accountSelected(account)}
          />
        )}
      </h1>
      {loading &&
        <Loader></Loader>
      }
      {!loading && (
        <>
          {(mentalReadiness.length === 0 || latestAdoptionRate.activeMembers < activeMembersLimit) && <div className='not-enough-data'>Not enough data, please invite more active members.</div>}
          {(mentalReadiness.length > 0 && latestAdoptionRate.activeMembers >= activeMembersLimit) &&
            <HeroMeasures
              readiness={mentalReadiness}
              accounts={accounts}
              workMentalReadinessChange={workMentalReadinessChange}
              latestAdoptionRate={latestAdoptionRate}
            ></HeroMeasures>
          }

        </>
      )}
      <AccountOnboarding
        isOpen={showOnboarding}
        toggle={() => {
          setShowOnboarding(false)
          userUpdateField({ field: 'showOnboarding', value: false })
        }}
      />
    </div>
  )
}

export default AccountHome
