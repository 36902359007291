import React from 'react'
import { ReactComponent as PlusIcon } from '../../../../images/plus.svg'

const AddUserButtonText = () => (
  <div className="d-flex align-items-center gap-3">
    <PlusIcon style={{
      width: '11px',
      height: '12px'
    }}
    />
    Add a member
  </div>
)

export default AddUserButtonText
