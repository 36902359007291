import React from 'react'
import classNames from 'classnames'
import { ReactComponent as PencilWhiteIcon } from '../../images/pencil-white-fill.svg'
import { ReactComponent as TrashIcon } from '../../images/trash.circle.white.fill.svg'
import { ReactComponent as StarIcon } from '../../images/star.svg'
import { cssColor } from '../../utils/css-helpers'
import './soundscape.scss'

const Soundscape = (props) => {
  const {
    soundscape,
    forwardRef,
    draggableProps,
    dragHandleProps,
    editSoundscape,
    deleteSoundscape
  } = props

  return (
    <li className='group-item soundscape' ref={forwardRef} {...draggableProps} {...dragHandleProps} data-id={soundscape.id}>
      <div className='group-item__container'>
        <div className={classNames({ 'publicity-soundscape': true, published: soundscape.published })}></div>
        <button type='button' className='group-item__edit-btn' onClick={() => editSoundscape(soundscape.id)}>
          <PencilWhiteIcon title='Edit' />
        </button>
        {soundscape.premium &&
            <StarIcon title='Premium' className="premium-badge" />
        }
        {!soundscape.published && (
          <button type='button' className='group-item__delete-btn' onClick={() => { console.log('x'); deleteSoundscape(soundscape.id) }}>
            <TrashIcon title='Delete' />
          </button>
        )}
          <div
            className='emoi'
            style={{
              backgroundColor: cssColor(soundscape.color)
            }}
          >{soundscape.emoi}</div>

      </div>
      <h3 className='group-item__title'>
        {soundscape.title}
      </h3>
    </li>
  )
}

export default Soundscape
