import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { backgroundColorPlugin } from './chart-utils'

ChartJS.register(
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const xMap = {
  0: '😢',
  1: '😔',
  2: '😒',
  3: '😊',
  4: '😃'
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    backgroundColorPlugin: {
      color: '#212324'
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return xMap[data]
        },
        font: {
          size: 22
        }
      }
    },
    y: {
      grid: {
        color: '#313537'
      }
    }
  }
}

const C = ({ data, title }) => {
  const { labels } = data
  const datasets = [{
    data: data.values,
    backgroundColor: '#6BE784',
    borderColor: '#6BE784',
    barThickness: 10,
    borderWidth: 2,
    borderRadius: Number.MAX_VALUE,
    borderSkipped: false
  }]

  return (
    <div className="showcase-chart">
      <h3>{ title }</h3>
      <div className="chart-area">
        <Bar plugins={[backgroundColorPlugin]} data={{ labels, datasets }} options={options} />
      </div>
    </div>
  )
}

export default C
