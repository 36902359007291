import _ from 'lodash'

const parseContent = (content, videos, citations, images) => {
  const lines = content.split('\n')
  const result = []

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    if (line === '') {
      result.push('')
    } else {
      if (line.includes('###')) {
        result.push('<!-- wp:heading {"level":3} -->')
        result.push('<h3 class="wp-block-heading">' + line.replaceAll('**', '').replaceAll('###', '').trim() + '</h3>')
        result.push('<!-- /wp:heading -->')
      } else {
        result.push('<!-- wp:paragraph -->')
        result.push(line)
        result.push('<!-- /wp:paragraph -->')
      }
    }
  }

  for (let i = 0; i < images.length; i++) {
    const image = images[i]
    if (image) {
      result.push('<!-- wp:image {"className":"size-full"} -->')
      result.push(`<figure class="wp-block-image size-full"><img src="${image.src}" alt="${image.alt}" /></figure>`)
      result.push('<!-- /wp:image -->')
    }
  }

  const videosOrdered = _.orderBy(videos, ['view_count'], ['desc'])
  for (let i = 0; i < videosOrdered.length; i++) {
    const video = videos[i]
    result.push('<!-- wp:embed {"url":"' + `https://www.youtube.com/watch?v=${video.video_id}` + '","type":"video","providerNameSlug":"youtube","responsive":true,"className":"wp-embed-aspect-16-9 wp-has-aspect-ratio"} -->')
    result.push('<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">')
    result.push('<div class="wp-block-embed__wrapper">')
    result.push(`https://www.youtube.com/watch?v=${video.video_id}`)
    result.push('</div>')
    result.push('</figure>')
    result.push('<!-- /wp:embed -->')
  }

  if (citations && citations.length > 0) {
    result.push('<!-- wp:list -->')
    result.push('<ul>')
    for (let i = 0; i < citations.length; i++) {
      result.push('<!-- wp:list-item -->')
      result.push(`<li><a href="${citations[i]}">${citations[i]}</a></li>`)
      result.push('<!-- /wp:list-item -->')
    }
    result.push('</ul>')
    result.push('<!-- /wp:list -->')
  }

  return result.join('\n')
}

export default parseContent
