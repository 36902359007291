import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import InputContainer from '../InputContainer'
import './index.scss'

const Checkbox = ({
  register,
  step = '0',
  name,
  errors,
  placeholder = 'Enter text',
  label,
  disabled = false,
  required = false,
  style = {}
}) => {
  return (<InputContainer type="checkbox" label={label} name={name} required={required} style={style}>
    <input
      id={name}
      step={step}
      type="checkbox"
      {...register(name, {
        required: required ? `${label} is required.` : false
      })}
      placeholder={placeholder}
      disabled={disabled}
    />
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className='input-container__error'>{message}</p>}
    />
  </InputContainer>)
}

export default Checkbox
