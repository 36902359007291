import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../api/firebase'
import { ReactComponent as PencilWhiteIcon } from '../../images/pencil-white-fill.svg'
import { ReactComponent as TrashIcon } from '../../images/trash.circle.white.fill.svg'
import { cssColor } from '../../utils/css-helpers'

const Badge = (props) => {
  const {
    badge,
    forwardRef,
    draggableProps,
    dragHandleProps,
    editBadge,
    deleteBadge
  } = props

  const [imageUrl, setImageUrl] = useState(null)
  const [isMounted, setIsMounted] = useState(true)

  async function getImageUrl (image) {
    if (!image) {
      return null
    }

    try {
      const url = await getDownloadURL(ref(storage, image))
      return `url(${url})`
    } catch (e) {
      return false
    }
  }

  useEffect(() => {
    getImageUrl(badge.image).then(image => {
      if (isMounted) {
        setImageUrl(image)
      }
    })

    return () => setIsMounted(false)
  }, [])

  return (
    <li className='group-item' ref={forwardRef} {...draggableProps} {...dragHandleProps} data-id={badge.id}>
      <div className='group-item__container'>
        <div className={classNames({ 'publicity-badge': true, published: badge.published })}></div>
        <button type='button' className='group-item__edit-btn' onClick={() => editBadge(badge.id)}>
          <PencilWhiteIcon title='Edit' />
        </button>
        {!badge.published && (
          <button type='button' className='group-item__delete-btn' onClick={() => deleteBadge(badge.id)}>
            <TrashIcon title='Delete' />
          </button>
        )}
        {
          badge.img
            ? <img src={badge.img} alt='overlay' className='group-item__img' />
            : (
              <div
                className='group-item__img'
                style={{
                  backgroundColor: cssColor(badge.color),
                  backgroundImage: imageUrl
                }}
              />
              )
        }

      </div>
      <h3 className='group-item__title'>
        {badge.title}
      </h3>
    </li>
  )
}

export default Badge
