import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { create, getAll, remove, subscribeAll, update } from './db'

const collectionName = 'soundscapes'

const soundscapesCreate = (group, data) => {
  const soundscapesId = uuid()
  const { soundscapes } = group.metadata

  soundscapes.push({
    ...data,
    id: soundscapesId
  })

  update(collectionName, group.id, {
    soundscapes
  })
}

const soundscapesGroupCreate = async (data) => {
  await create(collectionName, uuid(), data)
}

const soundscapesGroupDelete = async (groupId) => {
  await remove(collectionName, groupId)
}

const soundscapesGroupUpdate = async (groupId, data) => {
  await update(collectionName, groupId, data)
}

const soundscapeUpdate = (group, soundscapesId, data) => {
  const { soundscapes } = group.metadata

  const index = _.findIndex(soundscapes, { id: soundscapesId })
  soundscapes[index] = data

  update(collectionName, group.id, {
    soundscapes
  })
}

const soundscapesUpdate = (group, soundscapes) => {
  update(collectionName, group.id, {
    soundscapes
  })
}

const soundscapesGroupsGetAll = () => getAll(collectionName)
const soundscapesGroupsSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)

export {
  soundscapesCreate,
  soundscapesGroupCreate,
  soundscapesGroupDelete,
  soundscapesGroupUpdate,
  soundscapeUpdate,
  soundscapesUpdate,
  soundscapesGroupsGetAll,
  soundscapesGroupsSubscribeAll
}
