import React, { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router'
import { checkUser, signOutUser } from '../../core/reducers/user'
import Loader from '../../components/Loader'
import Sidebar from './components/sidebar'
import './index.scss'
import Header from './components/header'
import BillingBanner from '../../modules/account/components/billing/banner'
import BillingUpgrade from '../../modules/account/components/billing/upgrade'
import ee from '../../core/emitter'
import { getMixpanel } from '../../api/mixpanel'
import { addDays } from '../../utils/date'
import TrialExpiredComponent from '../../modules/account/components/billing/trial-expired'
import AccountDisabledComponent from '../../modules/account/components/account-disabled'

const AdminLayout = memo(() => {
  const mixpanel = getMixpanel()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector((state) => state.rootReducers.isLoading)
  const masterAccount = useSelector(state => state.accountReducers.account)
  const [isUgradeOpen, setIsUpgradeOpen] = useState(false)
  const [trialExpired, setTrialExpired] = useState(false)
  const disabled = masterAccount?.metadata?.deleted || false

  useEffect(() => {
    if (masterAccount && masterAccount.exist && !masterAccount.metadata.billing) {
      const expiresOn = addDays(masterAccount.metadata.createdAt.toDate(), 14)
      setTrialExpired(expiresOn < new Date())
    }

    if (!masterAccount && !isLoading) {
      navigate('/login')
    }
  }, [masterAccount, isLoading])

  useEffect(() => {
    dispatch(checkUser())
  }, [])

  const signOutHandle = () => {
    dispatch(signOutUser())
    navigate('/login')
  }

  useEffect(() => {
    ee.on('upgradeTrigger', data => {
      setIsUpgradeOpen(true)
      mixpanel.track('Upgrade process started by upsell J01', data)
    })

    return () => ee.off('upgradeTrigger')
  }, [])

  return (
    <div className='admin-layout'>
      <Sidebar />
      <div className='admin-layout__container'>
        <Header signOutHandle={signOutHandle} />
        {masterAccount && !disabled && !masterAccount.metadata.billing && !trialExpired && (
          <>
            <BillingBanner triggerUpgrade={() => setIsUpgradeOpen(true)} />
            <BillingUpgrade isOpen={isUgradeOpen} toggle={() => setIsUpgradeOpen(false)} />
          </>
        )}
        <div className='admin-layout__content'>
          {
            isLoading
              ? (<Loader />)
              : (
              <>
                {!disabled && trialExpired && <TrialExpiredComponent />}
                {disabled && <AccountDisabledComponent />}
                {!trialExpired && !disabled &&
                  <Outlet />
                }
              </>
                )
          }
        </div>
      </div>
    </div>
  )
})

AdminLayout.displayName = 'AdminLayout'

export default AdminLayout
