import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bubble } from 'react-chartjs-2'
import { backgroundColorPlugin } from './chart-utils'

ChartJS.register(
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  Legend
)

const yMap = {
  0: 'Low',
  1: null,
  2: ' ',
  3: null,
  4: 'High'
}

const options = {
  startAtZero: true,
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    backgroundColorPlugin: {
      color: '#212324'
    }
  },
  scales: {
    x: {
      beginAtZero: true
    },
    y: {
      grid: {
        color: '#313537'
      },
      ticks: {
        beginAtZero: true,
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return yMap[data]
        },
        font: {
          size: 13,
          weight: 'bold'
        },
        color: '#fff'
      }

    }
  }
}

const C = ({ data, title }) => {
  const getDataset = (data, point, color) => {
    const newData = []
    data.forEach((d, i) => {
      if (d === point) {
        newData[i] = d
      }
    })
    return {
      data: newData,
      backgroundColor: color,
      borderColor: color,
      barThickness: 10,
      borderWidth: 2,
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false
    }
  }

  const { labels } = data
  const datasets = [
    getDataset(data.values, 4, '#FD6860'),
    getDataset(data.values, 3, '#F18725'),
    getDataset(data.values, 2, '#D9EC37'),
    getDataset(data.values, 1, '#D9EC37'),
    getDataset(data.values, 0, '#5ED600')
  ]

  return (
    <div className="showcase-chart">
      <h3>{ title }</h3>
      <div className="chart-area">
        <Bubble plugins={[backgroundColorPlugin]} data={{ labels, datasets }} options={options} />
      </div>
    </div>
  )
}

export default C
