import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PageHeader from '../../../components/PageHeader'
import Button from '../../../components/button'
import { ReactComponent as PlusIcon } from '../../../images/plus.svg'
import Loader from '../../../components/Loader'
import { Container, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
// import './index.scss';
import ActionGroup from '../../../components/actions/group'
import ActionModal from '../../../components/actions/modals/action'
import NewGroupModal from '../../../components/actions/modals/group-new'
import EditGroupModal from '../../../components/actions/modals/group-edit'
import { getItemKey } from '../../../api/db'
import {
  actionUpdate,
  actionGroupCreate,
  actionGroupDelete,
  actionGroupUpdate,
  actionCreate,
  actionsUpdate,
  actionGroupsSubscribeAll
} from '../../../api/accounts/actions'

const AddGroupButtonText = () => (
  <div className="d-flex align-items-center gap-3">
    <PlusIcon style={{
      width: '11px',
      height: '12px'
    }}
    />
    Add Group
  </div>
)

const AccountActions = memo(() => {
  const [groups, setGroups] = useState()
  const [loading, setLoading] = useState(true)
  const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false)
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditGroupModalOpen] = useState(false)
  const [currentGroupId, setcurrentGroupId] = useState(null)
  const [currentActionId, setcurrentActionId] = useState(null)
  const user = useSelector((state) => state.userReducers.user)

  useEffect(() => {
    const unsubscribe = actionGroupsSubscribeAll(user.metadata.account, (actions) => {
      const actionsOrdered = _.sortBy(actions, action => action.metadata.rank)

      setGroups(actionsOrdered)
      setLoading(false)
    })

    return unsubscribe
  })

  const openNewGroupModal = () => {
    setIsNewGroupModalOpen(true)
  }

  const deleteGroup = (groupId) => {
    setcurrentGroupId(null)
    actionGroupDelete(user.metadata.account, groupId)
  }

  const editGroup = (groupId) => {
    setcurrentGroupId(groupId)
    setIsEditGroupModalOpen(true)
  }

  const openActionModal = (groupId, actionId = null) => {
    if (!actionId) {
      actionId = uuid()
    }

    setcurrentGroupId(groupId)
    setcurrentActionId(actionId)
    setIsAddActionModalOpen(true)
  }

  const resetActionModal = () => {
    setIsAddActionModalOpen(false)
    setcurrentActionId(null)
    setcurrentGroupId(null)
  }

  const resetAddNewGroupModal = () => {
    setIsNewGroupModalOpen(false)
  }

  const resetEditGroupModal = () => {
    setIsEditGroupModalOpen(false)
    setcurrentGroupId(null)
  }

  const actionsUpdated = (groupId, actions) => {
    const group = _.find(groups, { id: groupId })
    actionsUpdate(user.metadata.account, group, actions)
  }

  const onActionSubmit = (groupId, actionData) => {
    const group = _.find(groups, { id: groupId })
    const actions = group.metadata.actions

    if (_.findIndex(actions, { id: currentActionId }) !== -1) {
      actionUpdate(user.metadata.account, group, currentActionId, actionData)
    } else {
      actionData.rank = _.size(actions) + 1
      actionCreate(user.metadata.account, group, actionData)
    }
    resetActionModal()
  }

  const onAddNewGroupSubmit = async (values) => {
    const group = {
      name: values.title,
      rank: groups.length > 0 ? _.last(groups).metadata.rank + 1 : 1,
      actions: []
    }
    actionGroupCreate(user.metadata.account, group)
    setIsNewGroupModalOpen(false)
  }

  const onEditGroupSubmit = async (values) => {
    actionGroupUpdate(user.metadata.account, currentGroupId, { name: values.title })
    resetEditGroupModal()
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newGroups = Array.from(groups)
    const [reorderedItem] = newGroups.splice(result.source.index, 1)
    newGroups.splice(result.destination.index, 0, reorderedItem)

    const groupsChanged = []
    newGroups.map((group, index) => {
      if (group.metadata.rank !== index + 1) {
        groupsChanged.push({
          actionId: group.id,
          rank: index + 1
        })
      }
      return true
    })

    setGroups(newGroups)

    groupsChanged.forEach(({ actionId, rank }) => {
      actionGroupUpdate(user.metadata.account, actionId, { rank })
    })
  }

  return (
    <Container className="actions">
      <PageHeader title="Routines">
        <Button onClick={() => openNewGroupModal()} text={<AddGroupButtonText />} />
      </PageHeader>
      <div className="actions__content">
        {!loading &&
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="group__header">
              {(provided) => (
                <ul className="actions__list" {...provided.droppableProps} ref={provided.innerRef}>
                  {groups?.map((group, index) => (
                    <Draggable key={getItemKey(group)} draggableId={`action-group-${group.id}`} index={index}>
                      {(prov) => (
                        <ActionGroup
                          group={group}
                          forwardRef={prov.innerRef}
                          draggableProps={prov.draggableProps}
                          dragHandleProps={prov.dragHandleProps}
                          title={group.metadata.name}
                          items={group.metadata.actions}
                          groupId={group.id}
                          addActionHandle={openActionModal}
                          editGroupHandle={editGroup}
                          deleteGroupHandle={deleteGroup}
                          editActionHandle={openActionModal}
                          actionsUpdated={actionsUpdated}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        }
        {loading &&
          <Row className="center">
            <Loader></Loader>
          </Row>
        }
      </div>
      {isAddActionModalOpen &&
        <ActionModal
          isOpen={isAddActionModalOpen}
          onSubmit={onActionSubmit}
          toggle={resetActionModal}
          actionId={currentActionId}
          groupId={currentGroupId}
          groups={groups}
          uploadPath={`accounts/${user.metadata.account}/actions`}
        />
      }
      {isNewGroupModalOpen &&
        <NewGroupModal
          title="Add a group"
          isOpen={isNewGroupModalOpen}
          toggle={resetAddNewGroupModal}
          onSubmit={onAddNewGroupSubmit}
        />
      }
      {isEditModalOpen &&
        <EditGroupModal
          title="Edit group"
          group={_.find(groups, { id: currentGroupId })}
          isOpen={isEditModalOpen}
          toggle={resetEditGroupModal}
          onSubmit={onEditGroupSubmit}
        />
      }
    </Container>
  )
})

AccountActions.displayName = 'AccountActions'

export default AccountActions
