import { remove, getAll, subscribeAll, subscribeOne } from './db'

const collectionName = 'alerts'

const alertDelete = async (id) => {
  await remove(collectionName, id)
}
const alertsGetAll = () => getAll(collectionName)
const alertSubscribeAll = (mutationCallback) =>
  subscribeAll(collectionName, mutationCallback)
const alertSubscribeById = (id, mutationCallback) =>
  subscribeOne(collectionName, id, mutationCallback)

export { alertDelete, alertsGetAll, alertSubscribeAll, alertSubscribeById }
