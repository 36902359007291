/* eslint-disable */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as PencilIcon } from '../../images/pencil-fill.svg';
import { ReactComponent as TrashIcon } from '../../images/trash.circle.fill.svg';
import { getItemKey } from '../../api/db';
import { deleteFile } from '../../api/storage'
import Soundscape from './soundscape';
import SoundscapeDeleteModal from './modals/delete-confirmation'

const sortCards = (items) => {
  return _.sortBy(_.values(items), (item) => parseInt(item.rank, 10))
}

const SoundscapeGroup = ({
  title,
  items = [],
  forwardRef,
  draggableProps,
  dragHandleProps,
  addSoundscapeHandle,
  editSoundscapeHandle,
  editGroupHandle,
  deleteGroupHandle,
  groupId,
  groupPropsEditDisabled,
  soundscapesUpdated,
}) => {
  const [cards, setCards] = useState([]);
  const [deleteSoundscapeId, setDeleteSoundscapeId] = useState(false)
 
  useEffect(() => {
    if(items.length > 0) {
      setCards(sortCards(items))
    }
  }, [items]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) { return; }
    const newCardsArray = Array.from(cards);
    const [reorderedItem] = newCardsArray.splice(result.source.index, 1);
    newCardsArray.splice(result.destination.index, 0, reorderedItem);
    const newCards = [];
    newCardsArray.forEach((card, index) => {
      const id = card.id ? card.id : uuid();
      const rank = index + 1;
      newCards.push({
        ...card,
        id,
        rank,
      });
    });
 
    setCards(newCards);
    soundscapesUpdated(groupId, newCards);
  };

  const editSoundscape = (soundscapeId) => {
    editSoundscapeHandle(
      groupId,
      soundscapeId,
    );
  };

  const deleteSoundscapeFiles = (soundscape) => {
    const files = []

    if(soundscape.file) {
      files.push(soundscape.file)
    }

    files.forEach(file => {
      deleteFile(file)
    })
  }

  const deleteSoundscape = (soundscapeId) => {
    console.log({delete: soundscapeId})
    const soundscape = _.find(cards, { id: soundscapeId });
    deleteSoundscapeFiles(soundscape);
    const newCards = _.reject(cards, {id: soundscapeId});
    setCards(newCards);
    setDeleteSoundscapeId(null)
    soundscapesUpdated(groupId, newCards);
  };

  const deleteSoundscapeStart = (soundscapeId) => {
    setDeleteSoundscapeId(soundscapeId)
  }

  return (
    <div className="group" ref={forwardRef} {...draggableProps} data-id={groupId}>
      <div className="group__header">
        <button type="button" onClick={() => addSoundscapeHandle(groupId)} className="group__btn">
          <div className="group__icon group__icon_type_plus">
            <PlusIcon />
          </div>
        </button>
        <h3 className="group__title handle" {...dragHandleProps} style={{ cursor: 'grabbing' }}>{title}</h3>
        {!groupPropsEditDisabled &&         
          <button type="button" onClick={() => editGroupHandle(groupId)} className="group__btn" title="Edit group">
            <div className="group__icon group__icon_type_pencil">
              <PencilIcon />
            </div>
          </button>
        }
        {!groupPropsEditDisabled && items.length === 0 &&
          <button type="button" onClick={() => deleteGroupHandle(groupId)} className="group__btn" title="Delete group">
            <div className="group__icon group__icon_type_pencil">
              <TrashIcon />
            </div>
          </button>
        }
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="group__list" direction="horizontal">
          {(provided) => (
            <ul className="group__list" {...provided.droppableProps} ref={provided.innerRef}>
              {cards.map((soundscape, index) => (
                <Draggable key={getItemKey(soundscape)} draggableId={`soundscape-${soundscape.id}`} index={index}>
                  {(prov) => (
                    <Soundscape
                      soundscape={soundscape}
                      forwardRef={prov.innerRef}
                      draggableProps={prov.draggableProps}
                      dragHandleProps={prov.dragHandleProps}
                      editSoundscape={editSoundscape}
                      deleteSoundscape={deleteSoundscapeStart}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {deleteSoundscapeId &&
        <SoundscapeDeleteModal
          soundscape={_.find(items, {id: deleteSoundscapeId})}
          isOpen={deleteSoundscapeId !== false}
          toggle={() => setDeleteSoundscapeId(false)}
          onSubmit={deleteSoundscape}
        />
      }
    </div>
  );
};

export default SoundscapeGroup;
