import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getMixpanel } from '../../../api/mixpanel'

const mixpanel = getMixpanel()

const AccountOnboarding = ({ isOpen, toggle }) => {
  const video = 'https://www.youtube.com/embed/m3KCFZmVaOY'
  const args = {
    size: 'lg'
  }

  useEffect(() => {
    const eventName = 'View Onboarding Video K00'
    mixpanel.track(eventName)
  }, [])

  return (
      <Modal isOpen={isOpen} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Onboarding video</ModalHeader>
        <ModalBody>
          <center>

            <iframe
            width="766"
            height="434"
            src={video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </center>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

  )
}

export default AccountOnboarding
