import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import { ModalBody, ModalHeader, Progress } from 'reactstrap'
import Modal from '../../../../../components/Modal/index'
import ModalClose from '../../../../../components/Modal/close'
import ActionTemplateGroup from '../components/action-group'
import { actionGroupsSubscribeAll } from '../../../../../api/accounts/actions'
import { getItemKey } from '../../../../../api/db'
import predefinedActions from '../../../predefined-actions'

const Title = memo(({ openCreateNewActionModal }) => (
    <>
      Pick an action or <a href="#" style={{ color: '#C07FC5' }} onClick={() => openCreateNewActionModal()}>create new</a>
    </>
))

Title.displayName = 'ActionTemplateTiele'

const UserEdit = memo(({
  accountId,
  userId,
  isOpen,
  toggle,
  onSubmit,
  createAction,
  openCreateNewActionModal
}) => {
  const [groups, setGroups] = useState()
  const [loading, setLoading] = useState(true)
  const masterAccount = useSelector(state => state.accountReducers.account)

  useEffect(() => {
    const unsubscribe = actionGroupsSubscribeAll(masterAccount.id, (actions) => {
      const actionsOrdered = _.sortBy(actions, Action => Action.metadata.rank)

      _.each(predefinedActions, (actionGroup, key) => {
        const newActions = []
        _.each(actionGroup.actions, (action, index) => {
          newActions.push({
            ...action,
            id: uuid(),
            rank: index
          })
        })

        actionGroup.actions = newActions
        actionsOrdered.push({
          id: key,
          metadata: actionGroup
        })
      })
      setGroups(actionsOrdered)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalHeader close={<ModalClose toggle={toggle} />}>
        <Title openCreateNewActionModal={openCreateNewActionModal} />
      </ModalHeader>
      <ModalBody>
        {!loading &&
          <>
            <div>
              {groups?.map((group, index) => (
                <div key={getItemKey(group)} style={{ marginBottom: '30px' }}>
                  <ActionTemplateGroup
                    group={group}
                    items={group.metadata.actions}
                    actionSelected={createAction}
                  />
                </div>
              ))}
            </div>
          </>
        }
        {loading &&
          <Progress
            animated
            bar
            value="100"
          >
            Loading
          </Progress>
        }
      </ModalBody>
    </Modal>
  )
})

UserEdit.displayName = 'UserEditModal'

export default UserEdit
