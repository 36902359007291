import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Col, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap'
import DropZone from '../../../drop-zone'
import Modal from '../../../Modal/index'
import Input from '../../../Input/index2'
import Select from '../../../Select'
import '../../../Input/index.scss'
import './index.scss'
import Button from '../../../button'
import { getFileUrl } from '../../../../api/storage'

const booleanOptions = [{
  name: 'Yes',
  value: true
},
{
  name: 'No',
  value: false
}
]

const getTitle = (soundscape) => {
  if (!soundscape) {
    return 'Add soundscape'
  }

  return soundscape.name
}

const toBoolean = (value) => {
  if (value === true) {
    return true
  }

  if (value === 'true') {
    return true
  }

  return false
}

const SoundscapeModal = ({
  groups,
  groupId,
  isOpen,
  onSubmit,
  soundscapeId,
  toggle,
  uploadPath
}) => {
  const soundscapeUploadPath = `${uploadPath}/${groupId.substring(0, 8)}/${soundscapeId.substring(0, 8)}`
  const group = _.find(groups, { id: groupId })
  const soundscape = _.find(group.metadata.soundscapes, { id: soundscapeId }) || {}
  const [lockSave, setLockSave] = useState(false)
  const [soundscapeUrl, setSoundscapeUrl] = useState(null)
  const [isMounted, setIsMounted] = useState(true)

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      color: soundscape?.color || '#000000',
      name: soundscape?.name || '',
      file: soundscape?.file || null,
      emoi: soundscape?.emoi || null,
      xp: soundscape?.xp || 5,
      premium: soundscape?.premium || false,
      published: soundscape?.published || false,
      rank: soundscape?.rank || 1000
    }
  })

  useEffect(() => {
    if (getValues().file) {
      getFileUrl(getValues().file).then(file => {
        if (isMounted) {
          setSoundscapeUrl(file)
        }
      })

      return () => setIsMounted(false)
    }
  }, [getValues().file])

  const onFormSubmit = async () => {
    setLockSave(true)
    const values = getValues()

    values.xp = parseInt(values.xp) || 0
    values.published = toBoolean(values.published) || false
    values.premium = toBoolean(values.premium) || false
    values.file = values?.file || null
    values.emoi = values?.emoi || null
    values.rank = soundscape?.rank

    onSubmit(group.id, {
      ...values,
      id: soundscapeId,
      updatedAt: new Date()
    })
  }

  return (
    <Modal
      title={false}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form className="soundscape-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <ModalHeader>{getTitle(soundscape)}</ModalHeader>
            <p className="id">{soundscape.id}</p>
          </Col>
        </Row>

        {soundscape?.link &&
          <Row><Col>Deep link: {soundscape.link}</Col></Row>
        }

        <ModalBody>
          <Row>
            <Col>
              <Input
                name='name'
                label='Name'
                required={() => true}
                placeholder='Enter name'
                register={register}
                errors={errors}
              />
            </Col>
          </Row>

          {false &&
            <Row>
              <Col>
                <Input
                  name='xp'
                  label='XP'
                  required={() => true}
                  placeholder='5'
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col>

              </Col>
            </Row>
          }

          <Row className='d-flex gap-4 justify-content-between'>
            <Col>
              <input type='hidden' {...register('file')} />
              <DropZone
                name='file'
                label='File'
                required={() => true}
                style={{ width: 'auto' }}
                errors={errors}
                path={soundscapeUploadPath}
                uploadSuccess={(file) => { setValue('file', file) }}
                uploadStart={() => setLockSave(true)}
                uploadEnd={() => setLockSave(false)}
              />
            </Col>
            <Col>
              {soundscapeUrl &&
                <audio controls>
                  <source src={soundscapeUrl} type="audio/mpeg" />
                </audio>
              }
            </Col>

          </Row>

          <Row>
            <Col>
              <Input
                name='emoi'
                label='Emoi'
                required={() => true}
                placeholder=''
                register={register}
                errors={errors}
              />
            </Col>
            <Col>
              <Input
                type='text'
                name='color'
                label='Selection Color'
                required={false}
                placeholder='Enter color'
                register={register}
                errors={errors}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                name='premium'
                label='Premium'
                required={false}
                options={booleanOptions}
                register={register}
                errors={errors}
              />
            </Col>
            <Col>
              <Select
                name='published'
                label='Published'
                required={false}
                options={booleanOptions}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button type='submit' disabled={lockSave} text="Save" />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SoundscapeModal
