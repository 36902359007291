import React from 'react'
import './index.scss'

const InputContainer = ({
  name, error, label, children, required = false, style = {}, type
}) => {
  if (type !== 'hidden') {
    return (
    <div className='input-container' style={style}>
      {
        label && (
          <label
            htmlFor={name}
            className={`input-container__label ${required ? 'input-container__label_required' : ''}`}
          >
            {label}
          </label>
        )
      }
      {children}
      {
        error && (
          <p className='input-container__error'>
            {error}
          </p>
        )
      }
    </div>)
  } else return children
}

export default InputContainer
