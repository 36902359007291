import _ from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../Modal/index'
import Button from '../../../button'
import { btnStyles } from '../../../../utils/constants'
import Step1 from './step1'
import Audio from './audio'
import Video from './video'
import Breathing from './breathing'
import '../../../Input/index.scss'

const generateTitle = (title, subtitle) => (
  <>
    {title}
    {' '}
    -
    {' '}
    <span className='customModalTitle1'>{subtitle}</span>
  </>
)

const getTitle = (step, session) => {
  const titlePrefix = session ? `Edit ${session.title}` : 'Add a session'

  const steps = {
    first: {
      step: 'first',
      title: `${titlePrefix} 1/2`
    },
    breathing: {
      step: 'breathing',
      title: generateTitle(`${titlePrefix} 2/2`, ' Breathing')
    },
    audio: {
      step: 'audio',
      title: generateTitle(`${titlePrefix} 2/2`, ' Audio')
    },
    video: {
      step: 'video',
      title: generateTitle(`${titlePrefix} 2/2`, ' Video')
    }
  }

  return steps[step].title
}

const SessionModal = ({
  groups,
  groupId,
  isOpen,
  onSubmit,
  sessionId,
  toggle,
  uploadPath
}) => {
  const [currentStep, updateStep] = useState('first')
  const [lockSave, setLockSave] = useState(false)
  const group = _.find(groups, { id: groupId })
  const session = _.find(group.metadata.sessions, { id: sessionId })

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      type: 'breathing',
      eCadence: 60,
      eIn: 6,
      eInHold: 2,
      eOut: 6,
      eOutHold: 2,
      eRepetitions: 12,
      ...session
    }
  })

  const onFormSubmit = () => {
    if (currentStep === 'first') {
      updateStep(getValues().type)
    } else {
      const values = getValues()

      _.each(values, (value, key) => {
        if (_.includes(['eIn', 'eInHold', 'eOut', 'eOutHold', 'eRepetitions', 'eCadence'], key)) {
          values[key] = parseFloat(value)
        }
      })

      onSubmit(group.id, {
        ...values,
        updatedAt: new Date()
      })
    }
  }

  return (
    <Modal
      title={getTitle(currentStep, session)}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className='session-form'>
            {currentStep === 'first' &&
              <Step1
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                uploadPath={`${uploadPath}/${sessionId}`}
                setLockSave={setLockSave}
              />
            }
            {currentStep === 'breathing' &&
              <Breathing
                register={register}
                setValue={setValue}
                errors={errors}
              />
            }
            {currentStep === 'audio' &&
              <Audio
                errors={errors}
                getValues={getValues}
                register={register}
                setValue={setValue}
                uploadPath={`${uploadPath}/${sessionId}`}
                setLockSave={setLockSave}
              />
            }
            {currentStep === 'video' &&
              <Video
                errors={errors}
                getValues={getValues}
                register={register}
                setValue={setValue}
                uploadPath={`${uploadPath}/${sessionId}`}
                setLockSave={setLockSave}
              />
            }
          </div>

        </ModalBody>
        <ModalFooter>
          {currentStep === 'first' &&
            <Button text='Cancel' btnStyle={btnStyles.simple} onClick={toggle} />
          }
          {currentStep !== 'first' &&
            <Button text='Back' btnStyle={btnStyles.simple} onClick={() => updateStep('first')} />
          }
          <Button type='submit' disabled={lockSave} text={currentStep === 'first' ? 'Next' : 'Save'} />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SessionModal
