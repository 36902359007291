import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Alert, Container, Row } from 'reactstrap'
import { alertsGetAll } from '../../../api/alerts'
import Loader from '../../../components/Loader'
import { healthchecksGetAll } from '../../../api/healthchecks'
import './index.scss'

const getColor = (status) => {
  if (status === 'done') return 'success'
  if (status === 'running') return 'warning'
  return 'danger'
}

const toDate = (fDate) => {
  const date = new Date(fDate.seconds * 1000)
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
}

const duration = (sDate, eDate) => {
  const start = new Date(sDate.seconds * 1000)
  const end = new Date(eDate.seconds * 1000)
  const diff = Math.abs(end - start)
  return Math.ceil(diff / (1000 * 60 * 24))
}

const Alerts = () => {
  const [loading, setLoading] = useState(false)
  const [alerts, setAlerts] = useState([])
  const [healthchecks, setHealthchecks] = useState([])

  useEffect(() => {
    const run = async () => {
      setLoading(true)
      const data = _.orderBy(await alertsGetAll(), ['metadata.createdAt.seconds'], ['desc'])
      const healthchecksData = await healthchecksGetAll()
      setLoading(false)
      setAlerts(data)
      setHealthchecks(healthchecksData)
    }

    run()
  }, [])

  return (
    <Container>
      <Row>
        <h1>API Status</h1>
      </Row>

      {healthchecks.map((healthcheck) => (
        <Row key={healthcheck.id}>
          <Alert color={getColor(healthcheck.metadata.status)}>
            <h4>{healthcheck.id}</h4> {healthcheck.metadata.status === 'done' ? 'operational' : healthcheck.metadata.status}
          </Alert>
        </Row>
      ))}

      {!loading &&
        <Row className="mt-5">
          <h1>Downtimes</h1>
        </Row>
      }
      {loading &&
        <Loader></Loader>
      }
      {!loading &&
        <Row>
          {alerts.map((alert) => (
            <Alert color="danger" key={alert.id} data-id={alert.id}>
              <h4>{alert.metadata.serviceName}</h4>
              <p>Started: {toDate(alert.metadata.createdAt)}</p>
              <p>Duration: {duration(alert.metadata.createdAt, alert.metadata.updatedAt)} minutes</p>
              {alert.metadata.note && <p className="alert-note">{alert.metadata.note}</p>}
            </Alert>
          ))}
        </Row>
      }

    </Container>
  )
}

export default Alerts
