import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'
import QRCode from 'qrcode.react'
import Input from '../../../components/Input/index2'
import InputControlled from '../../../components/Input/controlled'
import Button from '../../../components/button'
import { accountManageJoinLink } from '../../../api/accounts'
import confirm from '../../../components/confirm'
import './index.scss'
import { getMixpanel } from '../../../api/mixpanel'

const mixpanel = getMixpanel()

const TeamInvite = ({ account }) => {
  const [lockSave, setLockSave] = useState(false)
  const [addErrors, setAddErrors] = useState([])

  const {
    clearErrors,
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
  })

  useEffect(() => {
    if (account) {
      reset({
        name: account.metadata.name
      })
    }
  }, [account])

  const generate = async () => {
    setLockSave(true)
    await accountManageJoinLink({
      accountId: account.id,
      action: 'create',
      name: account.metadata.name
    })

    setLockSave(false)
  }

  const deleteLink = async () => {
    const confirmation = await confirm({
      message: 'Are you sure you want to delete this link?',
      confirmText: 'Delete'
    })

    if (!confirmation) return

    setLockSave(true)
    await accountManageJoinLink({
      accountId: account.id,
      action: 'delete'
    })
    mixpanel.track('Invite link deleted E21')

    setLockSave(false)
  }

  const onFormSubmit = async () => {
    setAddErrors([])
    setLockSave(true)
    const values = getValues()

    const { data } = await accountManageJoinLink({
      accountId: account.id,
      action: 'create',
      name: values.name
    })

    const newErrors = _.filter(data.results, x => { return x.error })

    if (newErrors.length > 0) {
      setAddErrors(newErrors)
      setLockSave(false)
    } else {
      mixpanel.track('Invite link generated E20')
      clearErrors('name')
    }
  }

  const downloadQR = () => {
    const canvas = document.getElementById('invite-qr')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${account.id}-${uuid().substring(0, 8)}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <>
      {account.metadata.joinLink &&
        <>
          <Row>
            <Col>
              <div>Invite new Team by sharing following link</div>

              <div>
                <InputControlled type="text" value={account.metadata.joinLink} />
              </div>

              <div>
                <Button type="submit" loading={lockSave} text={'Delete Link'} onClick={deleteLink} />
              </div>
            </Col>
            <Col>
            </Col>
            <Col>
              <div className="qr-box">
                <div className="description">
                  Share this QR code with all members of the team you want to invite
                </div>

                <QRCode
                  value={account.metadata.joinLink}
                  renderAs="canvas"
                  className="invite-qr"
                  id="invite-qr"
                />
                <center className="download-qr">
                  <Button onClick={downloadQR} text="Download QR"></Button>
                </center>
              </div>
            </Col>
          </Row>
        </>
      }
      {!account.metadata.joinLink &&
        <>
          {account.metadata.name &&
            <>
              <p>You do not have public invite link. Click button below to generate it.</p>
              <Button type="submit" loading={lockSave} text={'Generate'} onClick={generate} />
            </>
          }
          {!account.metadata.name &&
            <form onSubmit={handleSubmit(onFormSubmit)}>
            <div>
              <p>Name your team to generate invite link</p>
              <div className="session-form">
                {addErrors.map((error, index) => {
                  return <div key={index} className="error">{error.error}</div>
                })}
                {register && errors &&
                  <Input
                    name="Name"
                    label="Team name"
                    register={register}
                    errors={errors}
                    required={true}
                  />
                }
              </div>
            </div>
            <br />
            <Button type="submit" loading={lockSave} text={'Generate'} />
          </form>
          }
        </>
      }
    </>
  )
}

export default TeamInvite
