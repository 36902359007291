import React, { memo } from 'react'
import Button from '../button'
import { btnStyles } from '../../utils/constants'

const Close = memo(({ toggle }) => (
  <Button text="Close" btnStyle={btnStyles.simple} onClick={() => toggle()} />
))

Close.displayName = 'ModalCloseButton'

export default Close
