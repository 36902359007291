import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { contentSubscribeById, contentUpdate, contentGenerateDeepLink, contentDelete, contentGenerateVector } from '../../../api/content'
import Loader from '../../../components/Loader'
import './index.scss'
import { EditText, EditTextarea } from 'react-edit-text'
import { toast } from 'react-toastify'

const ContentItemEdit = () => {
  const navigate = useNavigate()
  const vectorRequiresRefresh = useRef()
  const [content, setContent] = useState(false)
  const [loading, setLoading] = useState(true)
  const { contentId } = useParams()
  const [categoryDropdownOpen, categoryDropdownSetOpen] = useState(false)
  const [publishDropdownOpen, publishDropdownSetOpen] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [isNew, setIsNew] = useState(null)

  const handleCategoryChange = (id, category) => {
    contentUpdate(id, { ...content.metadata, category })
    categoryDropdownSetOpen(false)
  }

  useEffect(() => {
    if (loading) {
      return contentSubscribeById(contentId, data => {
        const contentData = { ...data }
        if (!contentData.metadata) {
          contentData.metadata = {
            category: 'Blog Post',
            createdAt: new Date()
          }
        }

        if (isNew === null) {
          setIsNew(!contentData.exists)
        }

        setContent(contentData)
        setLoading(false)
        console.log(contentData)
        vectorRequiresRefresh.current = !contentData.metadata || contentData.metadata.vectorRequiresRefresh
      })
    }
  }, [contentId, isNew])

  useEffect(() => {
    return async () => {
      if (vectorRequiresRefresh.current) {
        await contentGenerateVector({ id: contentId })
      }
    }
  }, [])

  const categories = [
    'Blog Post',
    'Session'
  ]

  const generateDeepLink = async (id) => {
    const { data } = await contentGenerateDeepLink(id)
    if (data.success) {
      toast.info('Deep link generated')
    } else {
      toast.error('Error generating deep link: ' + data.errorMessage)
    }
  }

  const deleteContent = (id) => {
    if (confirm('Are you sure you want to delete this content?')) {
      contentDelete(id)
      navigate('/admin/content')
    }
  }

  const publish = async () => {
    setPublishing(true)
    await contentUpdate(content.id, { ...content.metadata, published: true })
    contentGenerateVector({ id: contentId })
    setPublishing(false)
    navigate('/admin/content')
  }

  return (
    <Container className="content-view">
      {!loading &&
        <>
          {!isNew &&
            <Row>
              <Col>
                <h4 className="inline">Status</h4>
                <Dropdown isOpen={publishDropdownOpen} toggle={() => publishDropdownSetOpen(!publishDropdownOpen)} className="publish-dropdown">
                  <DropdownToggle caret>{content?.metadata?.published ? 'Published' : 'Unpublished'}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem key={'published-yes'} onClick={() => {
                      contentUpdate(content.id, { ...content.metadata, published: true })
                      publishDropdownSetOpen(!publishDropdownOpen)
                    }}>Published</DropdownItem>
                    <DropdownItem key={'published-no'} onClick={() => {
                      contentUpdate(content.id, { ...content.metadata, published: false })
                      publishDropdownSetOpen(!publishDropdownOpen)
                    }}>Unpublished</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          }

          <Row className="breather">
            <Col>
              <h4 className="inline">Category</h4>
              <Dropdown isOpen={categoryDropdownOpen} toggle={() => categoryDropdownSetOpen(!categoryDropdownOpen)} className="category-dropdown">
                <DropdownToggle caret>{content?.metadata?.category || 'Select category'}</DropdownToggle>
                <DropdownMenu>
                  {categories.map(category => (
                    <DropdownItem key={category} onClick={() => handleCategoryChange(content.id, category)}>{category}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

          <Row className="breather">
            <Col>
              <h4 className="inline">Title</h4>
              <h1>
                  <EditText
                  name="title"
                  className="editable"
                  inputClassName="text-input"
                  placeholder="Title"
                  defaultValue={content.metadata.name || ''}
                  onSave={({ value }) => {
                    contentUpdate(content.id, { ...content.metadata, name: value || '', vectorRequiresRefresh: true, category: content.metadata.category })
                  }}
                  ></EditText>

              </h1>
            </Col>
          </Row>

          <Row className="breather">
            <Col>
              <h4>Description</h4>
              <EditTextarea
                name="description"
                className="editable"
                inputClassName="textarea-input"
                placeholder="Description"
                defaultValue={content.metadata.description}
                onSave={({ value }) => {
                  contentUpdate(content.id, { ...content.metadata, description: value, vectorRequiresRefresh: true })
                }}
              ></EditTextarea>
            </Col>
          </Row>

          {content.metadata.category === 'Session' &&
            <>
              <Row>
                <Col>
                  <h4>Duration</h4>
                  <EditText
                    name="duration"
                    className="editable"
                    inputClassName="text-input"
                    placeholder="Duration"
                    defaultValue={content.metadata.duration}
                    onSave={({ value }) => {
                      contentUpdate(content.id, { ...content.metadata, duration: value })
                    }}
                  ></EditText>
                </Col>
              </Row>
              <Row className="breather">
                <Col>
                  <h4>Session Id</h4>
                  <EditText
                    name="sessionId"
                    className="editable"
                    inputClassName="text-input"
                    placeholder="Session Id"
                    defaultValue={content.metadata.sessionId || ''}
                    onSave={ async ({ value }) => {
                      await contentUpdate(content.id, { ...content.metadata, sessionId: value, url: false })

                      await generateDeepLink(content.id)
                    }}
                  ></EditText>
                </Col>
              </Row>

            </>
          }

          {(content.metadata.category === 'Blog Post' || content.metadata.url) &&
              <Row className="breather">
                <Col>
                  <h4>URL</h4>
                  <EditText
                    inputClassName="text-input"
                    className="editable"
                    name="url"
                    placeholder="URL"
                    defaultValue={content.metadata.url}
                    onSave={({ value }) => {
                      contentUpdate(content.id, { ...content.metadata, url: value.trim() })
                    }}
                  ></EditText>
                </Col>
              </Row>
          }

          <Row className="breather">
            <Col>
              <h4>Recommended For</h4>
              <EditTextarea
                  name="recommendedFor"
                  className="editable"
                  inputClassName="textarea-input"
                  placeholder="Recommended For"
                  defaultValue={content.metadata.recommendedFor}
                  onSave={({ value }) => {
                    contentUpdate(content.id, { ...content.metadata, recommendedFor: value, vectorRequiresRefresh: true })
                  }}
              ></EditTextarea>
            </Col>
          </Row>
          { /* content.metadata.vector &&
            <Row>
              <Col>
                <h4>Vector</h4>
                <pre className="vector">{JSON.stringify(content.metadata.vector).replaceAll(',', ', ')}</pre>
              </Col>
            </Row>
            */
          }

          {!isNew &&
            <Row className="breather">
              <Col>
                <Button onClick={() => deleteContent(content.id)}>Delete content</Button>
              </Col>
            </Row>
          }
          {isNew &&
            <Row className="breather">
              <Col>
                <Button onClick={() => publish()}>{publishing && <Loader />}{!publishing && <>Publish</>}</Button>
              </Col>
            </Row>
          }
        </>
      }
      {loading &&
        <Loader></Loader>
      }
    </Container>
  )
}

export default ContentItemEdit
