import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { generateVector, postsImport, systemBlogPostUpdate, systemBlogPostsGet } from '../../../api/posts'

const generateVectors = async (posts, existingIds) => {
  posts.forEach(async (post) => {
    if (_.indexOf(existingIds, parseInt(post.ID)) !== -1) {
      return
    }
    const stringWithVector = post.post_title + ' ' + post.post_content.replace(/<[^>]*>?/gm, '')
    const makeVector = await generateVector(stringWithVector)
    await systemBlogPostUpdate(`${post.ID}`, {
      title: post.post_title,
      stringWithVector,
      url: `https://blog.earkick.com/${post.post_name}/`,
      excerpt: post.post_excerpt,
      content: post.post_content.replace(/<[^>]*>?/gm, ''),
      vector: makeVector.data.vector,
      updatedAt: new Date(),
      deleted: false
    })
  })
}

const InternalPostsIndex = () => {
  const [, setWordpressPosts] = useState([])

  const fetchPostsFromWordpress = async (existingIds) => {
    try {
      const response = await postsImport(existingIds)
      setWordpressPosts(JSON.parse(response.data.posts))
      generateVectors(JSON.parse(response.data.posts), existingIds)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchPosts = async () => {
    const systemPosts = await systemBlogPostsGet()
    const existingIds = systemPosts.map((post) => parseInt(post.id))
    fetchPostsFromWordpress(existingIds)
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  return (<>
  </>)
}

export default InternalPostsIndex
