import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart'
    }
  },
  /*
   does not work

  legend: {
    display: true,
    labels: {
      filter: (legendItem, data) => {
        return !legendItem.hiddenInLegend
      }
    }
  },
  */
  scales: {
    y: {
      beginAtZero: true,
      min: 0,
      max: 100,
      ticks: {
        callback: (value) => `${value}%`
      }
    },
    x: {
      ticks: {
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return (new Date(data)).toISOString().substring(0, 10)
        }
      }
    }
  }
}

const Chart = ({ data }) => {
  return (<>
    <Line options={options} data={data} />
  </>
  )
}

export default Chart
