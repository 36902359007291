import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import InputContainer from '../InputContainer'
import './index.scss'

const Input = ({
  register,
  type = 'text',
  step = '0',
  name,
  errors,
  placeholder = 'Enter text',
  label,
  disabled = false,
  required = false,
  style = {},
  onChange = () => {}
}) => {
  return (<InputContainer type={type} label={label} name={name} required={required} style={style}>
    <input
      id={name}
      step={step}
      type={type}
      {...register(name, {
        required: required ? `${label} is required.` : false
      })}
      className='input-container__input'
      placeholder={placeholder}
      disabled={disabled}
      onKeyUp={onChange}
    />
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className='input-container__error'>{message}</p>}
    />
  </InputContainer>)
}

export default Input
