import React, { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../../components/Modal/index'
import Button from '../../../../components/button'
import Input from '../../../../components/Input/index2'
import { btnStyles } from '../../../../utils/constants'
import '../../../../components/Input/index.scss'

const UserEdit = memo(({
  isOpen,
  toggle,
  onSubmit,
  account
}) => {
  const [loading, setLoading] = useState(false)
  const {
    clearErrors,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors }
  } = useForm({
  })

  useEffect(() => {
    if (account) {
      reset({
        name: ''
      })
    }
  }, [account, reset])

  const onFormSubmit = async () => {
    setLoading(true)
    const values = getValues()

    if (values.name.length < 1) {
      setError('name', {
        type: 'focus',
        message: 'Name is required'
      })
      setLoading(false)
    } else {
      clearErrors('name')
      const response = await onSubmit(values)

      if (response.error) {
        setError('name', {
          type: 'focus',
          message: response.errorMessage
        })
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      title={account ? 'Edit team' : 'New team'}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className="session-form">
            <Input
              name="name"
              label="Team Name"
              register={register}
              errors={errors}
              required={true}
            />
          </div>

        </ModalBody>
        <ModalFooter>
          <Button text="Cancel" btnStyle={btnStyles.simple} onClick={toggle} />
          <Button type="submit" loading={loading} text={account ? 'Save' : 'Create'} />
        </ModalFooter>
      </form>
    </Modal>
  )
})

UserEdit.displayName = 'UserEditModal'

export default UserEdit
