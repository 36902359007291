const cssColor = (color) => {
  if (color.indexOf('#') === 0) {
    return color
  }

  if (color.indexOf('rgb') === 0) {
    return color
  }

  return `#${color}`
}

export {
  cssColor
}
