/* eslint-disable */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as PencilIcon } from '../../images/pencil-fill.svg';
import { ReactComponent as TrashIcon } from '../../images/trash.circle.fill.svg';
import { getItemKey } from '../../api/db';
import { deleteFile } from '../../api/storage'
import Action from './action';
import ActionDeleteModal from './modals/delete-confirmation'

const sortCards = (items) => {
  return _.sortBy(_.values(items), (item) => parseInt(item.rank, 10))
}

const Group = ({
  title,
  items = [],
  forwardRef,
  draggableProps,
  dragHandleProps,
  addActionHandle,
  editActionHandle,
  editGroupHandle,
  deleteGroupHandle,
  groupId,
  groupPropsEditDisabled,
  actionsUpdated,
}) => {
  const [cards, setCards] = useState([]);
  const [deleteActionId, setDeleteActionId] = useState(false)
 
  useEffect(() => {
    if(items.length > 0) {
      setCards(sortCards(items))
    }
  }, [items]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) { return; }
    const newCardsArray = Array.from(cards);
    const [reorderedItem] = newCardsArray.splice(result.source.index, 1);
    newCardsArray.splice(result.destination.index, 0, reorderedItem);
    const newCards = [];
    newCardsArray.forEach((card, index) => {
      const id = card.id ? card.id : uuid();
      const rank = index + 1;
      newCards.push({
        ...card,
        id,
        rank,
      });
    });
 
    setCards(newCards);
    actionsUpdated(groupId, newCards);
  };

  const editAction = (actionId) => {
    editActionHandle(
      groupId,
      actionId,
    );
  };

  const deleteActionFiles = (action) => {
    const files = []

    if(action.image) {
      files.push(action.image)
    }

    if(action.file) {
      files.push(action.file)
    }

    files.forEach(file => {
      deleteFile(file)
    })
  }

  const deleteAction = (actionId) => {
    const action = _.find(cards, { id: actionId });
    deleteActionFiles(action);
    const newCards = _.reject(cards, {id: actionId});
    setCards(newCards);
    setDeleteActionId(null)
    actionsUpdated(groupId, newCards);
  };

  const deleteActionStart = (actionId) => {
    setDeleteActionId(actionId)
  }

  return (
    <div className="group" ref={forwardRef} {...draggableProps} data-id={groupId}>
      <div className="group__header">
        <button type="button" onClick={() => addActionHandle(groupId)} className="group__btn">
          <div className="group__icon group__icon_type_plus">
            <PlusIcon />
          </div>
        </button>
        <h3 className="group__title handle" {...dragHandleProps} style={{ cursor: 'grabbing' }}>{title}</h3>
        {!groupPropsEditDisabled &&         
          <button type="button" onClick={() => editGroupHandle(groupId)} className="group__btn" title="Edit group">
            <div className="group__icon group__icon_type_pencil">
              <PencilIcon />
            </div>
          </button>
        }
        {!groupPropsEditDisabled && items.length === 0 &&
          <button type="button" onClick={() => deleteGroupHandle(groupId)} className="group__btn" title="Delete group">
            <div className="group__icon group__icon_type_pencil">
              <TrashIcon />
            </div>
          </button>
        }
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="group__list" direction="horizontal">
          {(provided) => (
            <ul className="group__list" {...provided.droppableProps} ref={provided.innerRef}>
              {cards.map((action, index) => (
                <Draggable key={getItemKey(action)} draggableId={`action-${action.id}`} index={index}>
                  {(prov) => (
                    <Action
                      action={action}
                      forwardRef={prov.innerRef}
                      draggableProps={prov.draggableProps}
                      dragHandleProps={prov.dragHandleProps}
                      editAction={editAction}
                      deleteAction={deleteActionStart}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {deleteActionId &&
        <ActionDeleteModal
          action={_.find(items, {id: deleteActionId})}
          isOpen={deleteActionId !== false}
          toggle={() => setDeleteActionId(false)}
          onSubmit={deleteAction}
        />
      }
    </div>
  );
};

export default Group;
