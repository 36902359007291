import React, { useEffect, memo, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { accountSwitch } from '../../api/accounts'
import { cacheClear } from '../../api/cache'

const AccountSwitch = memo(() => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { accountId } = useParams()
  useEffect(() => {
    if (!loading) {
      setLoading(true)

      accountSwitch(accountId).then(() => {
        if (!loading) {
          cacheClear()
          navigate('/')
        }
      })
    }
  }, [])

  return (<>Switching...</>)
})

AccountSwitch.displayName = 'AccountSwitch'

export default AccountSwitch
