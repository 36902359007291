import React from 'react'
import radialProgress from '../../../images/chart-background-2.png'
import radialProgressStatus from '../../../images/radial-progress-status.svg'
import './radial-progress-bar.scss'

const RadialProgressBar = ({
  dataSymbol,
  progress,
  styles,
  title
}) => {
  return (
    <>
      <div className="radial-progress">
        <div className="radial-progress-wrapper" style={styles}>
          <div className="background-gray-style" />
          <img
            src={radialProgress}
            alt="progress background"
            className="image-1"
          />

          <h4>{progress || 0}{ dataSymbol }</h4>

          <img
            style={{ transform: `rotate(${(progress || 0) * 1.8 + 180}deg)` }}
            src={radialProgressStatus}
            alt="progress track"
            className="image-2"
            />
        </div>
      </div>
      <p>{ title }</p>
    </>
  )
}

export default RadialProgressBar
