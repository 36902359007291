import React, { memo } from 'react'
import { useForm } from 'react-hook-form'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../components/Modal/index'
import Button from '../../button'
import { btnStyles } from '../../../utils/constants'
import Input from '../../../components/Input/index2'

export default memo(function ModalGroupNew ({
  title,
  isOpen,
  toggle,
  onSubmit
}) {
  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
    }
  })

  const onFormSubmit = () => {
    const values = getValues()
    onSubmit(values)
  }

  return (
    <Modal
      title={title}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <div className='session-form'>
            <Input
              name='title'
              label='Title'
              register={register}
              errors={errors}
              required={() => true}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button text='Cancel' btnStyle={btnStyles.simple} onClick={toggle} />
          <Button type='submit' text='Save' />
        </ModalFooter>
      </form>
    </Modal>
  )
})
