import React, { memo, useState } from 'react'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap'
import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router'
import { ReactComponent as NoAvatarIcon } from '../../../images/user-no-avatar.svg'
import { ReactComponent as ArrowIcon } from '../../../images/arrow-dropdown.svg'
import Accounts from './accounts'
import { useNavigate } from 'react-router'
import { userUpdateField } from '../../../api/users'

const Header = memo(({ signOutHandle }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const currentUser = useSelector((state) => state.userReducers.authUser)
  const name = currentUser?.displayName || currentUser?.email
  const account = useSelector((state) => state.accountReducers.account)
  const admin = account?.metadata?.admin || false

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='header'>
      <div className='header__user-container'>
        <Dropdown isOpen={isOpen} toggle={toggle} className='header__dropdown-container'>
          <DropdownToggle tag='span' className='header__dropdown'>
            <NoAvatarIcon />
            {name} {admin &&
              <>(admin)</>
            }
            <ArrowIcon />
          </DropdownToggle>
          <DropdownMenu
            end
            flip={false}
          >
            <Accounts />
            {/*
            <DropdownItem onClick={() => navigate('/admin/profile')}>
              Profile
            </DropdownItem>
            */}
            <DropdownItem onClick={() => {
              userUpdateField({ field: 'showOnboarding', value: true })
              navigate('/')
            }}>
              show onboarding
            </DropdownItem>
            <DropdownItem onClick={signOutHandle}>
              Sign-out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
})

Header.displayName = 'Header'

export default Header
