import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import AdminLayout from '../layouts/AdminLayout'
import AccountHome from '../modules/account/home'
// import AccountUsers from '../modules/account/users'
import AccountUser from '../modules/account/teams/users/user'
import AccountMentalReadiness from '../modules/account/mental-readiness'
import AccountSessions from '../modules/account/sessions'
import AccountAudios from '../modules/account/audios'
import AccountBadges from '../modules/account/badges'
import AccountTeams from '../modules/account/teams'
import AccountTeam from '../modules/account/teams/team'
import AccountActions from '../modules/account/actions'

import { UnregisteredUsersGuard } from './guards'

const AccountRoutes = memo(() => (
  <Routes>
    <Route element={<AdminLayout />}>
    <Route
        path=''
        element={(
          <UnregisteredUsersGuard>
            <AccountHome />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='mental-readiness'
        element={(
          <UnregisteredUsersGuard>
            <AccountMentalReadiness />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='teams'
        element={(
          <UnregisteredUsersGuard>
            <AccountTeams />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='teams/:accountId'
        element={(
          <UnregisteredUsersGuard>
            <AccountTeam />
          </UnregisteredUsersGuard>
        )}
      />
      { /*
      <Route
        path='users'
        element={(
          <UnregisteredUsersGuard>
            <AccountUsers />
          </UnregisteredUsersGuard>
        )}
      />
    */ }
      <Route
        path='teams/:accountId/user/:userId'
        element={(
          <UnregisteredUsersGuard>
            <AccountUser />
          </UnregisteredUsersGuard>
        )}
      />

      <Route
        path='sessions'
        element={(
          <UnregisteredUsersGuard>
            <AccountSessions />
          </UnregisteredUsersGuard>
        )}
      />

      <Route
        path='audios'
        element={(
          <UnregisteredUsersGuard>
            <AccountAudios />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='badges'
        element={(
          <UnregisteredUsersGuard>
            <AccountBadges />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='routines'
        element={(
          <UnregisteredUsersGuard>
            <AccountActions />
          </UnregisteredUsersGuard>
        )}
      />
    </Route>
  </Routes>

))

AccountRoutes.displayName = 'AccountRoutes'

export default AccountRoutes
