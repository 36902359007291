import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router'
import { Alert, Button, Col, Container, Row, Spinner } from 'reactstrap'
import { ReactComponent as PlusIcon } from '../../../images/plus.svg'
import { accountsGetByParentAccount, accountSubscribe, accountUpdate } from '../../../api/accounts'
import UserEditModal from './modals/user-edit-modal'
import { userGet, userTeammemberAdd, userTeammemberRemove } from '../../../api/users'
import AccountTable from './components/account-table'
import TeamTable from './components/team-table'
import UsersTable from './components/users-table'
import { toast } from 'react-toastify'
import '../admin.scss'

const MasterAccount = () => {
  const { accountId } = useParams()
  const [account, setAccount] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [loading, selLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [isUserModalOpen, setIsUserModalOpen] = useState(false)

  useEffect(() => {
    return accountSubscribe(accountId, data => {
      setAccount(data)
      selLoading(false)
    })
  }, [accountId])

  useEffect(() => {
    if (!account || !account.exists) {
      return
    }
    accountsGetByParentAccount(account.id).then(data => {
      setAccounts(_.filter(data, account => account.id !== accountId))
      selLoading(false)

      if (!account.metadata.subaccounts) {
        accountUpdate(accountId, {
          subaccounts: _.map(data, d => {
            return d.id
          })
        })
      }
    })
  }, [account])

  useEffect(() => {
    const run = async () => {
      const usersData = []
      for (let i = 0; i < account?.metadata?.users.length; i++) {
        usersData.push(await userGet(account?.metadata?.users[i]))
      }
      setUsers(usersData)
    }
    if (account?.metadata?.users.length > 0) {
      run()
    }
  }, [account?.metadata?.users])

  const accountValueChange = (accountId, name, value) => {
    const update = {}
    update[name] = value
    accountUpdate(accountId, update)
    toast.info(`Account ${name} updated`)
  }

  const saveUser = async (userData) => {
    const { data } = await userTeammemberAdd({ accountId, ...userData })

    if (data.error) {
      toast.error(data.errorMessage)
    } else {
      toast.info('User added')
      setIsUserModalOpen(false)
    }
  }

  const userRemove = async (email) => {
    const { data } = await userTeammemberRemove({ accountId, email })

    if (data.error) {
      toast.error(data.errorMessage)
    } else {
      toast.info('User removed from account')
      setIsUserModalOpen(false)
    }
  }

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Container>
          <Row>
            <h1>Master Account of {account.metadata.customerName}</h1>

            {account.metadata.deleted && (
              <Row>
                <Alert color="danger">
                  This account was &apos;soft deleted&apos; on {(new Date(account.metadata.deleted.toDate())).toISOString()} by {account.metadata.deletedBy}
                </Alert>
              </Row>
            )}

            {account.id !== 'admin' &&
              <AccountTable
              accountValueChange={accountValueChange}
              account={account}
              />
            }
            <hr />
          </Row>

          {accounts.length > 0 &&
            <Row>
              <br />
              <h2>Team (Subaccounts)</h2>
              <TeamTable
                accountValueChange={accountValueChange}
                accounts={accounts}
              />
              <hr />
            </Row>
          }

          <Row>
            <Col xs="4"><h2>Users</h2></Col>
            <Col xs="2"></Col>

            <Col xs="4">
              <Button onClick={() => {
                setIsUserModalOpen(true)
              }}><PlusIcon/> Add Team member</Button>
            </Col>
          </Row>
          <Row>
            <UsersTable
            users={users}
            userRemove={userRemove}
          />
          </Row>

          {isUserModalOpen &&
            <UserEditModal
              accountId={account.id}
              isOpen={isUserModalOpen}
              toggle={() => setIsUserModalOpen(false)}
              onSubmit={saveUser}
            />
          }
        </Container>
      )}
    </>
  )
}

export default MasterAccount
