import React, { memo } from 'react'
import {
  ModalBody, ModalFooter
} from 'reactstrap'
import Modal from '../../../components/Modal/index'
import Button from '../../button'
import { btnStyles } from '../../../utils/constants'
import Loader from '../../Loader'

export default memo(function ModalSoundscapeDelete ({
  soundscape,
  isOpen,
  toggle,
  onSubmit
}) {
  return (
    !soundscape
      ? <Loader></Loader>
      : <Modal
      title={`Are you sure you want to delete ${soundscape.title}?`}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalBody>
        <p>This action will also delete all files uploaded with this soundscape.</p>
      </ModalBody>
      <ModalFooter>
        <Button text='Cancel' btnStyle={btnStyles.simple} onClick={toggle} />
        <Button type='submit' text='Delete' onClick={() => onSubmit(soundscape.id)} />
      </ModalFooter>
    </Modal>
  )
})
