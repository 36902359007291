import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import DropZone from '../../../drop-zone'
import Input from '../../../Input/index2'
import Select from '../../../Select'
import { getImageUrl } from '../../../../utils/get-image-url'
import './step1.scss'
import Checkbox from '../../../Input/checkbox'

const Step1 = ({ errors, register, getValues, setValue, uploadPath, setLockSave }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [isMounted, setIsMounted] = useState(true)
  const setDominantColor = color => {
    setValue('color', color)
  }

  useEffect(() => {
    if (getValues().image) {
      getImageUrl(getValues().image).then(image => {
        if (isMounted) {
          setImageUrl(image)
        }
      })

      return () => setIsMounted(false)
    }
  }, [getValues().image])

  return (
  <>
    <Input
      name='title'
      label='Title'
      required={() => true}
      placeholder='Enter title'
      register={register}
      errors={errors}
    />
    <Input
      name='description'
      label='Description'
      required={() => true}
      placeholder='Enter description'
      register={register}
      errors={errors}
    />
    <Row>
      <label className="label-checkbox">
        <Checkbox
          name="premium"
          type="checkbox"
          register={register}
          errors={errors}
        />
        Premium
      </label>
    </Row>
    <Select
      name='type'
      label='Type'
      required={() => true}
      options={[{
        value: 'breathing',
        name: 'Breathing'
      }, {
        value: 'audio',
        name: 'Audio'
      }, {
        value: 'video',
        name: 'Video'
      }]}
      register={register}
      errors={errors}
    />

    <Row className='d-flex gap-4 justify-content-between step1'>
      <Col>
        <input type='hidden' {...register('image')} />
        <DropZone
          name='image'
          label='Image'
          required={() => true}
          style={{ width: 'auto' }}
          errors={errors}
          path={uploadPath}
          uploadSuccess={(image) => setValue('image', image)}
          uploadStart={() => setLockSave(true)}
          uploadEnd={() => setLockSave(false)}
          setDominantColor={color => setDominantColor(color)}
        />
        <Input
          type='hidden'
          name='color'
          label='Color'
          required={() => true}
          style={{ width: '340px' }}
          placeholder='Enter color'
          register={register}
          errors={errors}
        />

      </Col>
      <Col>
        {imageUrl &&
          <img src={imageUrl} alt='overlay' className='group-item__img background-image' />
        }
        {!imageUrl &&
          <p className="hint">Images must be dimension 450x450</p>
        }
      </Col>
    </Row>
  </>
  )
}

export default Step1
