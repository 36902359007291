import { httpsCallable } from 'firebase/functions'
import { functions } from './firebase'

const fileCopy = httpsCallable(functions, 'fileCopy')
const userAddToAccount = httpsCallable(functions, 'memberAddToAccount')
const userDoesExist = httpsCallable(functions, 'memberDoesExist')
const userCreate = httpsCallable(functions, 'userCreate')

const userCountEvent = httpsCallable(functions, 'userCountEvent')

export {
  fileCopy,
  userAddToAccount,
  userDoesExist,
  userCreate,
  userCountEvent
}
