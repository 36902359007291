import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { getAccountActivity } from '../../../api/accounts/team-activity'
import Chart from './chart'
import './index.scss'
import { cacheGetItemData, cacheSetItem } from '../../../api/cache'
import { useSelector } from 'react-redux'
import AccountsDropdown from '../components/accounts-dropdown'
import { accountsGetByMasterAccount } from '../../../api/accounts'
import { membersGetCombined } from '../../../api/accounts/members'
import { getMixpanel } from '../../../api/mixpanel'
import Loader from '../../../components/Loader'

const mixpanel = getMixpanel()

const AccountMentalReadiness = () => {
  const userAccountsCacheKey = 'user-accounts'
  const [labels, setLabels] = useState([])
  const [values, setValues] = useState([])
  const [topMarginValues, setTopMarginValues] = useState([])
  const [bottomMarginValues, setBottomMarginValues] = useState([])
  const [accounts, setAccounts] = useState(cacheGetItemData(userAccountsCacheKey) || [])
  const [accountId, setAccount] = useState('all' || false)
  const user = useSelector((state) => state.userReducers.user)
  const masterAccount = useSelector(state => state.accountReducers.account)

  const [mentalReadiness, setMentalReadiness] = useState(cacheGetItemData(`mentalReadiness-${accountId}`) || false)
  const [latestAdoptionRate, setLatestAdoptionRate] = useState(cacheGetItemData(`latestAdoptionRate-${accountId}`) || false)
  const [loading, setLoading] = useState(!mentalReadiness)

  const readiness = mentalReadiness || []

  useEffect(() => {
    const eventName = 'View Mental Readiness D00'
    mixpanel.track(eventName)
  }, [])

  useEffect(() => {
    const run = async () => {
      try {
        const inputAccounts = accountId !== 'all' ? [accountId] : [masterAccount.id, ...masterAccount.metadata.subaccounts]
        const accountUsers = await membersGetCombined(inputAccounts)

        if (accountUsers.length < 5) {
          throw new Error('Not enough data')
        }

        const { mentalReadiness: mr, latestAdoptionRate: lar } = await getAccountActivity(inputAccounts, masterAccount)
        cacheSetItem(`mentalReadiness-${accountId}`, { data: mr })
        cacheSetItem(`latestAdoptionRate-${accountId}`, { data: lar })
        setMentalReadiness(mr)
        setLatestAdoptionRate(lar)
        setLoading(false)
      } catch (e) {
        console.log(e)
        cacheSetItem(`mentalReadiness-${accountId}`, { data: [] })
        cacheSetItem(`latestAdoptionRate-${accountId}`, { data: {} })
        setMentalReadiness([])
        setLatestAdoptionRate({})
        setLoading(false)
      }
    }

    if (accountId && loading) {
      run()
    }
  }, [accountId, loading])

  useEffect(() => {
    const newLabels = []
    const newValues = []
    const newTopMarginValues = []
    const newBottompMarginValues = []

    for (let i = 0; i < readiness.length; i++) {
      newLabels.push(readiness[i].time)
      newValues.push(readiness[i].value)
      newTopMarginValues.push(readiness[i].value + readiness[i].sd)
      newBottompMarginValues.push(readiness[i].value - readiness[i].sd)
    }
    setLabels(newLabels)
    setValues(newValues)
    setTopMarginValues(newTopMarginValues)
    setBottomMarginValues(newBottompMarginValues)
  }, [readiness])

  useEffect(() => {
    if (user.metadata.account) {
      accountsGetByMasterAccount(masterAccount).then(async (data) => {
        const sortedData = _.orderBy(data, a => a.metadata.createdAt.toDate(), 'desc')
        cacheSetItem(userAccountsCacheKey, { data: sortedData })
        await setAccounts(sortedData)
      })
    }

    return () => {}
  }, [user.metadata.account])

  const accountSelected = (account) => {
    setAccount(account)
    setLoading(true)
  }
  return (
    <div className="mental-readiness">
      <h1 style={{ display: 'inline-block' }}>Mental Readiness
        {accounts.length > 0 && (
          <AccountsDropdown
            accounts={accounts}
            accountSelected={_.find(accounts, { id: accountId })}
            onSelectAccount={(account) => accountSelected(account)}
          />
        )}
      </h1>
      {loading && <Loader></Loader>}
      {!loading && (
        <>
          {(mentalReadiness.length === 0 || latestAdoptionRate.activeMembers < 5) && <div className='not-enough-data'>Not enough data, please invite more active members.</div>}
          {(mentalReadiness.length > 0 && latestAdoptionRate.activeMembers >= 5) &&
            <Chart
            data={{
              labels,
              datasets: [{
                label: 'Mental Readiness',
                data: values,
                borderColor: '#4C72B0',
                backgroundColor: '#4C72B0'
              },
              {
                label: 'Standard Deviation',
                data: topMarginValues,
                borderColor: '#CEDCFFB2',
                backgroundColor: '#CEDCFFB2'
              },
              {
                label: '',
                data: bottomMarginValues,
                borderColor: '#CEDCFFB2',
                backgroundColor: '#CEDCFFB2',
                hiddenInLegend: true
              }]
            }}
            ></Chart>
          }
        </>
      )}

    </div>
  )
}

export default AccountMentalReadiness
