import React from 'react'
import calendarDaily from '../../images/calendar-daily.svg'
import calendarWeekly from '../../images/calendar-weekly.svg'
import calendarMonthly from '../../images/calendar-monthly.svg'
import calendarDot from '../../images/calendar-dot.svg'
import calendarCircle from '../../images/calendar-circle.svg'

const DayDot = ({ isFilled }) => (<img src={isFilled ? calendarDot : calendarCircle} alt="" />)

const ActionTile = ({ action }) => {
  const getCalendarImage = () => {
    if (action.eType === 'monthly') {
      return calendarMonthly
    } else if (action.eType === 'weekly') {
      return calendarWeekly
    }

    return calendarDaily
  }

  return (
    <>
      <img src={getCalendarImage()} className="calendar-image" alt="" />
      {action.eType === 'daily' &&
        <div className="days">
          <DayDot isFilled={action.days.indexOf(7) !== -1} />
          <DayDot isFilled={action.days.indexOf(1) !== -1} />
          <DayDot isFilled={action.days.indexOf(2) !== -1} />
          <DayDot isFilled={action.days.indexOf(3) !== -1} />
          <DayDot isFilled={action.days.indexOf(4) !== -1} />
          <DayDot isFilled={action.days.indexOf(5) !== -1} />
          <DayDot isFilled={action.days.indexOf(6) !== -1} />
        </div>
      }
      {action.eRepetitions > 0 &&
        <h3 className='group-item__title action-repetitions'>
          {action.eRepetitions}x
        </h3>
      }
      <h3 className='group-item__title action-title'>
        {action.title}
      </h3>
    </>
  )
}

export default ActionTile
