import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { backgroundColorPlugin } from './chart-utils'

ChartJS.register(
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    backgroundColorPlugin: {
      color: '#212324'
    }
  },
  scales: {
    x: {
      beginAtZero: true
    },
    y: {
      grid: {
        color: '#313537'
      },
      ticks: {
        beginAtZero: true,
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return data
        },
        font: {
          size: 13,
          weight: 'bold'
        },
        color: '#ffffff'
      }

    }
  }
}

const C = ({ data, title }) => {
  const { labels } = data
  const datasets = [{
    data: data.values,
    backgroundColor: '#FD6860',
    borderColor: '#FD6860',
    barThickness: 10,
    borderWidth: 2,
    borderRadius: Number.MAX_VALUE,
    borderSkipped: false
  }]

  return (
    <div className="showcase-chart">
      <h3>{ title }</h3>
      <div className="chart-area">
        <Bar plugins={[backgroundColorPlugin]} data={{ labels, datasets }} options={options} />
      </div>
    </div>
  )
}

export default C
