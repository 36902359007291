import React, { memo, useEffect, useState } from 'react'
import {
  Navigate
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { checkUser } from '../core/reducers/user'
import FullPageLoader from '../components/FullPageLoader'

const UserGuard = memo(({ children }) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkUser())
    setLoading(false)
  }, [])

  return loading ? (<FullPageLoader></FullPageLoader>) : children
})

UserGuard.displayName = 'UserGuard'

const UnregisteredUsersGuard = memo(({
  children,
  redirectTo = '/login'
}) => {
  const user = useSelector((state) => state.userReducers.user)
  return user ? children : (<Navigate to={redirectTo} />)
})

UnregisteredUsersGuard.displayName = 'UnregisteredUsersGuard'

const RegisteredUsersGuard = memo(({
  children,
  redirectTo = false
}) => {
  const user = useSelector((state) => state.userReducers.user)
  const masterAccount = useSelector((state) => state.accountReducers.account)
  const admin = user?.metadata?.account === 'admin' || false

  if (!user && !masterAccount) {
    return children
  }

  if (!redirectTo && (admin || masterAccount)) {
    if (admin) {
      if (window.location.pathname.indexOf('/admin') === 0) {
        redirectTo = window.location.pathname
      } else {
        redirectTo = '/admin/sessions'
      }
    } else {
      if (window.location.pathname.indexOf('/account') === 0) {
        redirectTo = window.location.pathname
      } else {
        if (masterAccount.metadata.therapistShowcase) {
          redirectTo = '/account/teams'
        } else {
          redirectTo = '/account'
        }
      }
    }
  }

  return !redirectTo ? children : (<Navigate to={redirectTo} />)
})

RegisteredUsersGuard.displayName = 'RegisteredUsersGuard'

export {
  RegisteredUsersGuard,
  UnregisteredUsersGuard,
  UserGuard
}
