import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { accountGetTeamStats } from '../../../api/accounts'
import { cacheGetItemData, cacheSetItem } from '../../../api/cache'
import ReadinessDistributionChart from './charts/readiness-distribution'
import './readiness-distribution.scss'
import Loader from '../../../components/Loader'

const sorter = {
  sunday: 0, // << if sunday is first day of week
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
  // sunday: 7
}

const ReadinessDistribution = ({ accounts }) => {
  const [loading, setLoading] = useState(true)
  const [readiness, setReadiness] = useState(cacheGetItemData('accountGetTeamStats') || [])

  useEffect(() => {
    if (loading && readiness.length === 0) {
      accountGetTeamStats(_.map(accounts, a => a.id)).then((readiness) => {
        console.log(readiness)
        const data = { labels: [], values: [] }
        _.forEach(readiness.data.readinessDistribution.sort((a, b) => {
          const day1 = a.weekday.toLowerCase()
          const day2 = b.weekday.toLowerCase()
          return sorter[day1] - sorter[day2]
        }), row => {
          data.labels.push(row.weekday.substring(0, 1).toUpperCase())
          data.values.push(Math.round(row.average_mental_readiness))
        })
        console.log(data)
        cacheSetItem('accountGetTeamStats', { data })
        setReadiness(data)
        setLoading(false)
      })
    }
  }, [accounts, readiness])

  return (
    <div className="readiness-distribution">
      {loading && <Loader />}
      {!loading &&
        <>
          <ReadinessDistributionChart data={readiness} title=""></ReadinessDistributionChart>
          <p>Mental Readiness Distribution</p>
        </>
      }
    </div>
  )
}

export default ReadinessDistribution
