import React, { memo } from 'react'
import Loader from '../Loader'

const C = memo(() => {
  return (<div className='main-container'>
    <Loader></Loader>
  </div>)
})

C.displayName = 'FullPageLoader'

export default C
