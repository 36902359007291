const action = {
  dailyPreset: 'every-day',
  eRepetitions: 1,
  eType: 'daily',
  days: [1, 2, 3, 4, 5, 6, 7],
  title: "Don't pick nails",
  code: 'picknails'
}

export default action
