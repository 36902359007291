import journal from '../actions/journal'
import spendActiveTimeOutdoors from '../actions/spendActiveTimeOutdoors'
import strenghtenAbs from '../actions/strenghtenAbs'
import coreStabilityExcercises from '../actions/coreStabilityExcercises'
import blackRoll from '../actions/blackRoll'
import goForABikeRide from '../actions/goForABikeRide'
import treadmill from '../actions/treadmill'
import workout from '../actions/workout'
import stretching from '../actions/stretching'
import yoga from '../actions/yoga'
import excercise from '../actions/excercise'
import goForAWalk from '../actions/goForAWalk'
import goForARun from '../actions/goForARun'

const category = {
  rank: 6,
  name: 'Improve Fitness',
  actions: [
    journal,
    spendActiveTimeOutdoors,
    strenghtenAbs,
    coreStabilityExcercises,
    blackRoll,
    goForABikeRide,
    treadmill,
    workout,
    stretching,
    yoga,
    excercise,
    goForAWalk,
    goForARun
  ]
}

export default category
