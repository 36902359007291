
import React from 'react'
import { v4 as uuid } from 'uuid'
import { Container, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { EditText } from 'react-edit-text'
import './account-table.scss'

const billingPlans = [
  { value: false, name: 'Free' },
  { value: 'evaluation', name: 'Evaluation' },
  { value: 'premium', name: 'Premium' }
]

const AccountTable = ({
  account,
  accountValueChange
}) => {
  return (
    <Table bordered hover>
      <tbody>
        <tr>
          <th>Account ID</th>
          <td>{account.id}</td>
        </tr>
        <tr>
          <th>Customer Name</th>
          <td>
            <EditText
              name="customerName"
              placeholder="Customer Name"
              defaultValue={account.metadata.customerName}
              onSave={({ value }) => {
                accountValueChange(account.id, 'customerName', value)
              }}
            ></EditText>
          </td>
        </tr>
        <tr>
          <th>Account name (team name)</th>
          <td>
            <EditText
              name="name"
              placeholder="Account name (team name)"
              defaultValue={account.metadata.name}
              onSave={({ value }) => {
                accountValueChange(account.id, 'name', value)
              }}
            ></EditText>
          </td>
        </tr>
        <tr>
          <th>Extra Features<br /><a href="https://earkick.atlassian.net/wiki/spaces/DAS/pages/236093441/Extra+Features" target="_blank" rel="noreferrer">Documentation</a></th>
          <td>
            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.members}
                    onChange={() => accountValueChange(account.id, 'members', !account.metadata.members)}
                  />
                  App Members (allow customer to add app users by themselves)
                </Label>
              </FormGroup>
            </div>

            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.membersDetails}
                    onChange={() => accountValueChange(account.id, 'membersDetails', !account.metadata.membersDetails)}
                  />
                  App Members details (allow customer to list members and manage their therapies)
                </Label>
              </FormGroup>
            </div>

            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.sessions}
                    onChange={() => accountValueChange(account.id, 'sessions', !account.metadata.sessions)}
                  />
                  Sessions
                </Label>
              </FormGroup>
            </div>
            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.badges}
                    onChange={() => accountValueChange(account.id, 'badges', !account.metadata.badges)}
                  />
                  Badges
                </Label>
              </FormGroup>
            </div>
            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.actions}
                    onChange={() => accountValueChange(account.id, 'actions', !account.metadata.actions)}
                  />
                  Routines (old actions)
                </Label>
              </FormGroup>
            </div>
            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.team}
                    onChange={() => accountValueChange(account.id, 'team', !account.metadata.team)}
                  />
                  Team (invite with link)
                </Label>
              </FormGroup>
            </div>
            <div>
              <FormGroup
                switch
                inline
              >
                <Label check>
                  <Input
                    type="switch"
                    checked={account.metadata.therapistShowcase}
                    onChange={() => {
                      accountValueChange(account.id, 'therapistShowcase', !account.metadata.therapistShowcase)
                      accountValueChange(account.id, 'team', account.metadata.team || !account.metadata.therapistShowcase)
                      accountValueChange(account.id, 'members', account.metadata.members || !account.metadata.therapistShowcase)
                      accountValueChange(account.id, 'membersDetails', account.metadata.membersDetails || !account.metadata.therapistShowcase)
                    }}
                  />
                  Therapist showcase
                </Label>
              </FormGroup>
            </div>
          </td>
        </tr>
        <tr>
          <th>Billing</th>
          <td>
            <Container>
              <Row>
                <Input
                  name='billing'
                  type='select'
                  onChange={(e) => accountValueChange(
                    account.id,
                    'billing', e.target.value !== 'false' ? e.target.value : false
                  )}
                >
                  {billingPlans.map(plan => (

                    <option
                    key={uuid()}
                    selected={account.metadata.billing === plan.value}
                    value={plan.value}
                    >{plan.name}</option>
                  ))}
                </Input>
              </Row>

              <Row>
                <FormGroup
                  switch
                  inline
                >
                  <Label check>
                    <Input
                      type="switch"
                      checked={account.metadata.premiumMembers}
                      onChange={() => accountValueChange(account.id, 'premiumMembers', !account.metadata.premiumMembers)}
                      />
                    Premium members
                  </Label>
                </FormGroup>
              </Row>
            </Container>
          </td>
        </tr>
        <tr>
          <th>Join Link</th>
          <td>{account.metadata.joinLink || 'n/a'}</td>
        </tr>
        <tr>
          <th>Subaccounts status</th>
          <td>
            {!account.metadata.subaccounts &&
              <>Fixing subaccounts now...</>
            }
            {account.metadata.subaccounts &&
              <>Fixed</>
            }
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default AccountTable
