import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import SessionGroup from '../../../../components/sessions/group'
import SessionModal from '../../../../components/sessions/modals/session'
import ActionGroup from '../../../../components/actions/group'
import ActionModal from '../../../../components/actions/modals/action'
import ActionTemplateModal from './modals/actions-template'
import SessionTemplateModal from './modals/sessions-template'
import UserEditModal from './modals/user-edit'
import {
  membersSubscribeOne, memberUpdate
} from '../../../../api/accounts/members'
import deleteReplacedFiles from '../../../../utils/delete-replaced-files'
import { userAddToAccount, userDoesExist } from '../../../../api/functions'
import { useSelector } from 'react-redux'
import UserCharts from './user-charts'

const Users = memo(() => {
  const masterAccount = useSelector(state => state.accountReducers.account)
  const { accountId, userId } = useParams()
  const [isUserModalOpen, setIsUserModalOpen] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false)
  const [isAddSessionModalOpen, setIsAddSessionModalOpen] = useState(false)
  const [isPickActionTemplateModalOpen, setIsPickActionTemplateModalOpen] = useState(false)
  const [isPickSessionTemplateModalOpen, setIsPickSessionTemplateModalOpen] = useState(false)
  const [currentActionId, setCurrentActionId] = useState(null)
  const [currentSessionId, setCurrentSessionId] = useState(null)

  useEffect(() => {
    const unsubscribe = membersSubscribeOne(accountId, userId, (userRaw) => {
      setUser(userRaw)
      setLoading(false)
    })

    return unsubscribe
  }, [accountId, userId])

  const openActionModal = (groupId, actionId = null) => {
    if (actionId) {
      setCurrentActionId(actionId)
      setIsAddActionModalOpen(true)
    } else {
      setIsPickActionTemplateModalOpen(true)
    }
  }

  const openSessionModal = (groupId, sessionId = null) => {
    if (sessionId) {
      setCurrentSessionId(sessionId)
      setIsAddSessionModalOpen(true)
    } else {
      setIsPickSessionTemplateModalOpen(true)
    }
  }

  const createAction = (data) => onActionSubmit('', data)
  const createSession = (data) => onSessionSubmit('', data)

  const editUser = () => {
    setIsUserModalOpen(true)
  }

  const openCreateNewActionModal = () => {
    setIsPickActionTemplateModalOpen(false)
    setIsAddActionModalOpen(true)
  }

  const openCreateNewSessionModal = () => {
    setIsPickSessionTemplateModalOpen(false)
    setIsAddSessionModalOpen(true)
  }

  const onActionSubmit = async (groupId, actionData) => {
    const actions = Object.assign([], user.metadata.actions)
    if (currentActionId) {
      const index = _.findIndex(actions, { id: currentActionId })
      actions[index] = actionData
    } else {
      actionData.id = uuid()
      actionData.rank = _.size(user.metadata.actions) + 1
      actions.push(actionData)
    }

    resetActionModal()
    await memberUpdate(accountId, user.id, { actions })
  }

  const onSessionSubmit = async (groupId, data) => {
    const sessionData = _.omitBy(data, _.isUndefined)

    const sessions = Object.assign([], user.metadata.sessions)
    if (currentSessionId) {
      const index = _.findIndex(sessions, { id: currentSessionId })
      sessions[index] = sessionData
    } else {
      sessionData.id = uuid()
      sessionData.rank = _.size(user.metadata.sessions) + 1
      sessions.push(sessionData)
    }

    resetSessionModal()
    const diff = {
      oldItems: Object.assign([], user.metadata.sessions),
      newItems: Object.assign([], sessions)
    }
    await memberUpdate(accountId, user.id, { sessions })
    await deleteReplacedFiles(diff)
  }

  const resetActionModal = () => {
    setIsAddActionModalOpen(false)
    setIsPickActionTemplateModalOpen(false)
    setCurrentActionId(null)
  }

  const resetSessionModal = () => {
    setIsAddSessionModalOpen(false)
    setCurrentSessionId(null)
  }

  const saveUser = async (newData) => {
    const data = {
      firstname: newData.firstname,
      lastname: newData.lastname,
      appIds: newData.appIds.replace(' ', '').split(',')
    }

    await memberUpdate(accountId, user.id, data)
    _.each(data.appIds, appId => {
      userAddToAccount({
        appId: appId.trim(),
        accountId
      })
    })

    setIsUserModalOpen(false)
  }

  const actionsUpdated = (groupId, actions) => {
    memberUpdate(accountId, user.id, { actions })
    resetActionModal()
  }

  const sessionsUpdated = (groupId, sessions) => {
    memberUpdate(accountId, user.id, { sessions })
    resetSessionModal()
  }

  return (
    <>
        {!loading && user.metadata &&
          <>
            {masterAccount.metadata.therapistShowcase &&
              <UserCharts member={user} accountId={accountId} editUser={() => editUser() } />
            }

            { /*
            <p>App Ids: {user.metadata?.appIds.join(', ') || 'unknown'}</p>
          */ }

            <SessionGroup
              title="Sessions"
              items={user.metadata.sessions}
              groupId="main"
              addSessionHandle={openSessionModal}
              deleteGroupHandle={() => false}
              editSessionHandle={openSessionModal}
              sessionsUpdated={sessionsUpdated}
              groupPropsEditDisabled={true}
            />

            <br />

            <ActionGroup
              title="Actions"
              items={user.metadata.actions}
              groupId="main"
              addActionHandle={openActionModal}
              deleteActionHandle={() => false}
              editActionHandle={openActionModal}
              actionsUpdated={actionsUpdated}
              groupPropsEditDisabled={true}
            />
          </>
        }
        {!loading && !user.metadata &&
          <>User not found</>
        }
        {loading &&
          <Loader></Loader>
        }
      {isAddSessionModalOpen &&
        <SessionModal
          isOpen={isAddSessionModalOpen}
          onSubmit={onSessionSubmit}
          toggle={resetSessionModal}
          sessionId={currentSessionId}
          groupId={'main'}
          groups={[{
            id: 'main',
            metadata: {
              sessions: user.metadata.sessions
            }
          }]}
        />
      }
      {isAddActionModalOpen &&
        <ActionModal
          isOpen={isAddActionModalOpen}
          onSubmit={onActionSubmit}
          toggle={resetActionModal}
          actionId={currentActionId}
          groupId={'main'}
          groups={[{
            id: 'main',
            metadata: {
              actions: user.metadata.actions
            }
          }]}
        />
      }
      {isPickSessionTemplateModalOpen &&
        <SessionTemplateModal
          isOpen={isPickSessionTemplateModalOpen}
          onSubmit={() => setIsPickSessionTemplateModalOpen(false)}
          toggle={() => setIsPickSessionTemplateModalOpen(false)}
          createSession={createSession}
          openCreateNewSessionModal={openCreateNewSessionModal}
          accountId={accountId}
          userId={userId}
        />
      }
      {isPickActionTemplateModalOpen &&
        <ActionTemplateModal
          isOpen={isPickActionTemplateModalOpen}
          onSubmit={() => setIsPickActionTemplateModalOpen(false)}
          toggle={() => setIsPickActionTemplateModalOpen(false)}
          createAction={createAction}
          openCreateNewActionModal={openCreateNewActionModal}
          accountId={accountId}
          userId={userId}
        />
      }
      {isUserModalOpen &&
        <UserEditModal
          title="Add a User"
          user={user}
          isOpen={isUserModalOpen}
          toggle={() => setIsUserModalOpen(false)}
          onSubmit={saveUser}
          userDoesExist={userDoesExist}
        />
      }
    </>
  )
})

Users.displayName = 'Users'

export default Users
