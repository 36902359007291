import React from 'react'
import Input from '../../../Input/index2'

const Video = ({
  errors,
  register
}) =>
  <>
    <Input
      name='videoTitle'
      label='Video Title'
      required={() => true}
      placeholder='Enter video title'
      register={register}
      errors={errors}
    />
    <Input
      name='videoUrl'
      label='Video Url'
      required={() => true}
      placeholder='Enter video url'
      register={register}
      errors={errors}
    />
    <Input
      name='eDuration'
      label='Duration'
      required={() => true}
      placeholder='0'
      register={register}
      errors={errors}
    />
  </>

export default Video
