import React, { memo, Suspense, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useNavigate
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import LoginPage from '../modules/auth/login'
import SignupPage from '../modules/auth/signup'
import ForgotPassword from '../modules/auth/forgot-password'
import GuestLayout from '../layouts/GuestLayout'

import AccountRoutes from './account'
import AdminRoutes from './admin'
import {
  RegisteredUsersGuard,
  UnregisteredUsersGuard
} from './guards'
import { userSubscribe } from '../api/users'
import actions from '../core/actions'
import { accountSubscribe } from '../api/accounts'
import { signOutUser } from '../core/reducers/user'
import AccountSwitch from '../modules/account/switch'

const { setUser, setAccount } = actions

const LogoutComponent = memo(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(signOutUser())
    navigate('/login')
  }, [])

  return (<>Logging out...</>)
})

LogoutComponent.displayName = 'LogoutComponent'

const Routing = memo(() => {
  const dispatch = useDispatch()
  const authUser = useSelector(state => state.userReducers.authUser)
  const user = useSelector((state) => state.userReducers.user)
  const email = authUser?.email || false
  const accountId = user?.metadata?.account || false

  const account = useSelector((state) => state.accountReducers.account)
  const admin = account?.metadata?.admin || false
  const customer = account?.metadata?.customer || false

  useEffect(() => {
    if (email) {
      const unsubscribe = userSubscribe(email, data => {
        const userObject = {
          ...data,
          email
        }
        dispatch(setUser(userObject))
      })

      return unsubscribe
    }
  }, [email])

  useEffect(() => {
    if (accountId) {
      const unsubscribe = () => {}

      const subscribe = async () => {
        return await accountSubscribe(accountId, data => {
          dispatch(setAccount(data))
        })
      }

      subscribe().then(f => unsubscribe)

      return unsubscribe
    }
  }, [accountId])

  return (
    <Suspense className='main-container' fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/admin/*" element={<AdminRoutes />}></Route>
        <Route path="/account/*" element={<AccountRoutes />}></Route>
        <Route element={<GuestLayout />}>
          <Route
            path='/login'
            element={(
              <RegisteredUsersGuard>
                <LoginPage />
              </RegisteredUsersGuard>
            )}
          />
          <Route
            path='/forgot-password'
            element={(
              <RegisteredUsersGuard>
                <ForgotPassword />
              </RegisteredUsersGuard>
            )}
          />
          <Route
            path='/signup'
            element={(
              <RegisteredUsersGuard>
                <SignupPage />
              </RegisteredUsersGuard>
            )}
          />
        </Route>
        <Route element={<GuestLayout />}>
          <Route
            path='/logout'
            element={<LogoutComponent />}
          />
          <Route
            path='/account/switch/:accountId'
            element={
              <UnregisteredUsersGuard>
                <AccountSwitch />
              </UnregisteredUsersGuard>
            }
          >
          </Route>
        {admin &&
          <Route path='/admin' element={<Navigate to='/admin/sessions' />} />
        }
        {customer &&
          <Route path='/account' element={<Navigate to='/account' />} />
        }

        </Route>
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/*' element={<GuestLayout />}>
          <Route path="*" element={<p>404</p>} />
        </Route>

      </Routes>
    </Suspense>
  )
})

Routing.displayName = 'Routing'

export default Routing
