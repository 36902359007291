import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import Joi from 'joi'
import Title from '../../components/Title'
import Button from '../../components/button'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import './index.scss'
import { signInUser } from '../../core/reducers/user'
import { emailSchema, passwordSchema } from '../../utils/schemas'
import ContinueWithGoogle from './google'
import Hr from '../../components/hr'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../api/firebase'
import { Link } from 'react-router-dom'
import { getMixpanel } from '../../api/mixpanel'

const mixpanel = getMixpanel()
const schema = Joi.object({
  email: emailSchema(),
  password: passwordSchema()
})

const LoginPage = memo(() => {
  const {
    handleSubmit, formState: { errors }, setValue
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: joiResolver(schema)
  })
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.rootReducers.isComponentLoading)

  const onSubmit = async (values) => {
    const { email, password } = values
    await createUserWithEmailAndPassword(auth, email.toLowerCase(), password)
    mixpanel.track('User account creation A10', { method: 'email' })

    dispatch(signInUser({
      email: email.toLowerCase(),
      password
    }))
  }

  return (
    <div className='login'>
      <div className='login__container'>
        <Title value='Create an account' />
        <form className='login__form' onSubmit={handleSubmit(onSubmit)}>
          <Input
            type='text'
            name='email'
            label='Email'
            placeholder='Enter your email'
            onChange={(e) => setValue('email', e.target.value)}
            error={errors?.email?.message}
            disabled={isLoading}
          />
          <Input
            type='password'
            name='password'
            label='Password'
            placeholder='Choose a password'
            onChange={(e) => setValue('password', e.target.value)}
            error={errors?.password?.message}
            disabled={isLoading}
          />
          {
            isLoading
              ? <Loader />
              : (
                <Button
                  loading={isLoading}
                  text='Signup'
                  type='submit'
                />
                )
          }
        </form>
        <Hr>Or</Hr>
        <ContinueWithGoogle text="Signup with Google" />
        <div className="login-signup-cross-link">
          <Link to="/login">Login</Link>
        </div>
      </div>
    </div>
  )
})

LoginPage.displayName = LoginPage

export default LoginPage
