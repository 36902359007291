import React, { memo, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import PageHeader from '../../../components/PageHeader'
import Button from '../../../components/button'
import Loader from '../../../components/Loader'
import { ReactComponent as PlusIcon } from '../../../images/plus.svg'
import { Container, Row } from 'reactstrap'
import './index.scss'
import SoundscapeGroup from '../../../components/soundscapes/group'
import SoundscapeModal from '../../../components/soundscapes/modals/soundscape'
import NewGroupModal from '../../../components/soundscapes/modals/group-new'
import EditGroupModal from '../../../components/soundscapes/modals/group-edit'
import { getItemKey } from '../../../api/db'
import { deleteFile } from '../../../api/storage'
import {
  soundscapeUpdate,
  soundscapesGroupCreate,
  soundscapesGroupDelete,
  soundscapesGroupUpdate,
  soundscapesCreate,
  soundscapesGroupsSubscribeAll,
  soundscapesUpdate
} from '../../../api/soundscapes'

const AddGroupButtonText = () => (
  <div className="d-flex align-items-center gap-3">
    <PlusIcon style={{
      width: '11px',
      height: '12px'
    }}
    />
    Add Group
  </div>
)

const Soundscapes = memo(() => {
  const [groups, setGroups] = useState()
  const [loading, setLoading] = useState(true)
  const [isAddSoundscapeModalOpen, setIsAddSoundscapeModalOpen] = useState(false)
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditGroupModalOpen] = useState(false)
  const [currentGroupId, setcurrentGroupId] = useState(null)
  const [currentSoundscapeId, setcurrentSoundscapeId] = useState(null)

  useEffect(() => {
    const unsubscribe = soundscapesGroupsSubscribeAll((soundscapes) => {
      const soundscapesOrdered = _.sortBy(soundscapes, soundscape => soundscape.metadata.rank)

      setGroups(soundscapesOrdered)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const openNewGroupModal = () => {
    setIsNewGroupModalOpen(true)
  }

  const deleteGroup = (groupId) => {
    const files = []
    const group = _.find(groups, { id: groupId })

    _.each(group.metadata.soundscapes, soundscape => {
      if (soundscape.image) {
        files.push(soundscape.image)
      }

      if (soundscape.file) {
        files.push(soundscape.file)
      }
    })

    files.forEach(file => {
      deleteFile(file)
    })

    setcurrentGroupId(null)
    soundscapesGroupDelete(groupId)
  }

  const editGroup = (groupId) => {
    setcurrentGroupId(groupId)
    setIsEditGroupModalOpen(true)
  }

  const openSoundscapeModal = (groupId, soundscapeId = null) => {
    console.log({ groupId, soundscapeId })
    if (!soundscapeId) {
      soundscapeId = uuid()
    }

    setcurrentGroupId(groupId)
    setcurrentSoundscapeId(soundscapeId)
    setIsAddSoundscapeModalOpen(true)
  }

  const resetSoundscapeModal = () => {
    setIsAddSoundscapeModalOpen(false)
    setcurrentSoundscapeId(null)
    setcurrentGroupId(null)
  }

  const resetAddNewGroupModal = () => {
    setIsNewGroupModalOpen(false)
  }

  const resetEditGroupModal = () => {
    setIsEditGroupModalOpen(false)
    setcurrentGroupId(null)
  }

  const soundscapesUpdated = (groupId, soundscapes) => {
    const group = _.find(groups, { id: groupId })
    soundscapesUpdate(group, soundscapes)
  }

  const onSoundscapeSubmit = (groupId, soundscapeData) => {
    console.log({ groupId, soundscapeData })

    const group = _.find(groups, { id: groupId })
    const soundscapes = group.metadata.soundscapes
    if (_.findIndex(soundscapes, { id: currentSoundscapeId }) !== -1) {
      soundscapeUpdate(group, currentSoundscapeId, soundscapeData)
    } else {
      soundscapeData.rank = _.size(soundscapes) + 1
      soundscapesCreate(group, soundscapeData)
    }
    resetSoundscapeModal()
  }

  const onAddNewGroupSubmit = async (values) => {
    const group = {
      name: values.title,
      rank: groups && groups.length > 0 ? _.last(groups).metadata.rank + 1 : 1,
      soundscapes: []
    }
    soundscapesGroupCreate(group)
    setIsNewGroupModalOpen(false)
  }

  const onEditGroupSubmit = async (values) => {
    soundscapesGroupUpdate(currentGroupId, { name: values.title })
    resetEditGroupModal()
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newGroups = Array.from(groups)
    const [reorderedItem] = newGroups.splice(result.source.index, 1)
    newGroups.splice(result.destination.index, 0, reorderedItem)

    const groupsChanged = []
    newGroups.map((group, index) => {
      if (group.metadata.rank !== index + 1) {
        groupsChanged.push({
          soundscapeId: group.id,
          rank: index + 1
        })
      }
      return true
    })

    setGroups(newGroups)

    groupsChanged.forEach(({ soundscapeId, rank }) => {
      soundscapesGroupUpdate(soundscapeId, { rank })
    })
  }

  return (
    <Container fluid className="soundscapes">
      <PageHeader title="Soundscapes">
        <Button onClick={() => openNewGroupModal()} text={<AddGroupButtonText />} />
      </PageHeader>
      <div className="soundscapes__content">
        {!loading &&
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="group__header">
              {(provided) => (
                <ul className="soundscapes__list" {...provided.droppableProps} ref={provided.innerRef}>
                  {groups?.map((group, index) => (
                    <Draggable key={getItemKey(group)} draggableId={`soundscape-group-${group.id}`} index={index}>
                      {(prov) => (
                        <SoundscapeGroup
                          group={group}
                          forwardRef={prov.innerRef}
                          draggableProps={prov.draggableProps}
                          dragHandleProps={prov.dragHandleProps}
                          title={group.metadata.name}
                          items={group.metadata.soundscapes}
                          groupId={group.id}
                          addSoundscapeHandle={openSoundscapeModal}
                          editGroupHandle={editGroup}
                          deleteGroupHandle={deleteGroup}
                          editSoundscapeHandle={openSoundscapeModal}
                          soundscapesUpdated={soundscapesUpdated}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        }
        {loading &&
          <Row className="center">
            <Loader></Loader>
          </Row>
        }
      </div>
      {isAddSoundscapeModalOpen &&
        <SoundscapeModal
          isOpen={isAddSoundscapeModalOpen}
          onSubmit={onSoundscapeSubmit}
          toggle={resetSoundscapeModal}
          soundscapeId={currentSoundscapeId}
          groupId={currentGroupId}
          groups={groups}
          uploadPath={'soundscapes'}
        />
      }
      {isNewGroupModalOpen &&
        <NewGroupModal
          title="Add a group"
          isOpen={isNewGroupModalOpen}
          toggle={resetAddNewGroupModal}
          onSubmit={onAddNewGroupSubmit}
        />
      }
      {isEditModalOpen &&
        <EditGroupModal
          title="Edit group"
          group={_.find(groups, { id: currentGroupId })}
          isOpen={isEditModalOpen}
          toggle={resetEditGroupModal}
          onSubmit={onEditGroupSubmit}
        />
      }
    </Container>
  )
})

Soundscapes.displayName = 'Soundscapes'

export default Soundscapes
